<template>
  <section>
    <dialogModule
      v-if="cipinDialog"
      width="1240px"
      top="15"
      title="次品销售"
      bgColor="#fff"
      :dialogVisible="cipinDialog"
      @close="close">
      <span slot="close" class="el-icon-close close" @click="close" style="cursor: pointer;"></span>
      <div slot="change" style="margin-left: 30px;">
        <div style="color: #F8475F;">温馨提示：次品销售后，不退不换。</div>
      </div>
      <div slot="content" class="content">
        <div class="row align-center space-between query-top">
          <el-form ref="form" size="mini" inline :model="form" @keyup.enter.native="submitForm">
            <el-form-item>
              <div class="row align-center item-state">
                <span :class="[form.state === '1' ? 'select-state' : '']" @click.stop="form.state = '1';submitForm();">审批通过</span>
                <span :class="[form.state === '0' ? 'select-state' : '']" @click.stop="form.state = '0';submitForm();">待审批</span>
                <span :class="[form.state === '-1' ? 'select-state' : '']" @click.stop="form.state = '-1';submitForm();">已作废</span>
              </div>
            </el-form-item>
            <el-form-item label="货品名称">
              <el-input v-model="form.goodsName" placeholder="请输入货品名称" clearable style="width: 150px;"></el-input>
            </el-form-item>
            <el-form-item label="条码/货号">
              <el-input v-model="form.goodsNo" placeholder="请输入条码/货号" clearable style="width: 150px;"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" icon="el-icon-search" :loading="loading" @click="submitForm">查 询</el-button>
            </el-form-item>
          </el-form>
          <div class="approve-btn" @click="scanDialog = true" v-if="listData.length">次品申请二维码</div>
        </div>
        <div v-if="listData.length" class="query-list row flex-wrap space-between">
          <div class="query-card row align-center space-between" v-for="(item, index) in listData" :key="index">
            <div class="row align-center">
              <div class="goods-image">
                <div v-if="!item.imageList || !item.imageList.length">暂无图片</div>
                <el-image v-else :src="item.imageList[0]" :preview-src-list="item.imageList"></el-image>
              </div>
              <div class="goods-info">
                <div>{{item.goodsName}}</div>
                <div>货号：{{item.goodsNo}}</div>
                <div>条码：{{item.barCode}}</div>
              </div>
            </div>
            <div class="goods-price">
              <p>可售数量：<span>{{item.takeEffectNumber}}</span></p>
              <p>申请数量：<span>{{item.number}}</span></p>
            </div>
            <div class="goods-price">
              <p>售价：<span>￥{{item.suggestPrice}}</span></p>
              <p>吊牌价：<span>￥{{item.tagPrice}}</span></p>
            </div>
            <div class="goods-action">
              <template v-if="item.state === '2'">
                <div style="cursor: pointer;background: #067CF2;" @click="addCashDesk(item)">加入收银台</div>
                <div style="border: 1px solid #067CF2;background: none;color: #067CF2;margin-top: 5px;cursor: pointer;" @click="updateState(item)">作废</div>
              </template>
              <template v-else-if="item.state === '-1'">
                <div>已作废</div>
              </template>
              <template v-else>
                <div>正在审批中</div>
                <span>审批人：{{item.reviewUserName}}</span>
              </template>
            </div>
          </div>
        </div>
        <div v-else style="margin-top: 150px;text-align: center;">
          <div style="width: 200px;height: 200px;margin: auto;">
            <img style="width: 200px;" :src="basedImg" alt="code" />
          </div>
          <div style="margin-top: 10px;line-height: 28px;font-size: 16px;">
            <p>APP扫码即可快速跳转到次品申请页面</p>
            <p>快扫码试试吧！</p>
          </div>
        </div>
      </div>
    </dialogModule>
    <dialogModule
      v-if="scanDialog"
      width="500px"
      top="30"
      title="次品申请二维码"
      bgColor="#fff"
      :dialogVisible="scanDialog"
      @close="scanDialog = false">
      <span slot="close" class="el-icon-close close" @click="scanDialog = false" style="cursor: pointer;"></span>
      <div class="content" slot="content" style="text-align: center;height: 300px;">
        <div style="width: 200px;height: 200px;margin: auto;">
          <img style="width: 200px;" :src="basedImg" alt="code">
        </div>
        <div style="margin-top: 10px;line-height: 28px;font-size: 16px;">
          <p>APP扫码即可快速跳转到次品申请页面</p>
          <p>快扫码试试吧！</p>
        </div>
      </div>
    </dialogModule>
  </section>
</template>

<script>
  import dialogModule from '@/components/dialogModule.vue'
  import {queryBadGoodsCode, queryBadGoodsList, updateBadGoodsState} from "@/libs/http/modules/posOrder.js";
  export default {
    name: 'saleBadGoods',
    props: ['tabList'],
    components: {
      dialogModule
    },
    data(){
      return{
        cipinDialog: false,
        scanDialog: false,
        form: {
          state: '1',
          goodsName: '',
          goodsNo: ''
        },
        loading: false,
        listData: [],
        basedImg: ''
      }
    },
    created() {
      this.cipinDialog = true;
      this.submitForm();
      this.getCodeImg();
    },
    methods: {
      close(){
        this.cipinDialog = false;
        this.$emit('close')
      },
      submitForm(){
        this.loading = true;
        queryBadGoodsList({
          type: this.form.state,
          goodsName: this.form.goodsName,
          keyWord: this.form.goodsNo
        }).then(data=>{
          this.loading = false;
          this.listData = data || [];
        }).catch(error=>{
          this.loading = false;
          this.$message.error({ message: error, duration: 2000, showClose: true })
        })
      },
      addCashDesk(item){
        let res = this.tabList.some(list=>{
          return list.barCode === item.barCode && list.realPrice === item.suggestPrice && Number(list.number) >= Number(item.takeEffectNumber)
        })
        if(res){
          return this.$message.error({ message: `当前次品最大可售数量为：${item.takeEffectNumber}件`, duration: 2000, showClose: true })
        }
        item.badGoods = true;
        item.realPrice = item.suggestPrice;
        item.retailPrice = item.suggestPrice;
        item.skuId = item.skuInfoId;
        this.$emit('skuData', item);
        this.close();
      },
      async getCodeImg(){
        let res = await queryBadGoodsCode();
        this.basedImg = res.identifyBase64;
      },
      updateState(item){
        this.$confirm('确定将该次品标记为作废，作废后将会删除该次品', '提示').then(res=>{
          updateBadGoodsState({
            id: item.id
          }).then(data=>{
            this.submitForm();
            this.$emit('void', item)
          }).catch(error=>{
            this.$message.error({ message: error, duration: 2000, showClose: true })
          })
        }).catch(err=>{})
      }
    }
  }
</script>

<style lang="scss" scoped>
.content{
  background:#f3f3f3;
  padding:15px;
  height: 64vh;
  overflow: auto;
  .query-top{
    width: 100%;
    height: 40px;
    .item-state{
      border: 1px solid #067CF2;
      border-radius: 4px;
      color: #067CF2;
      text-align: center;
      cursor:pointer;
      span{
        width: 70px;
        border-right: 1px solid #067CF2;
        &:last-child{
          border-right: none;
        }
      }
      .select-state{
        background: #067CF2;
        color: #fff;
      }
    }
  }
  .approve-btn{
    border: 1px solid #067CF2;
    border-radius: 4px;
    color: #067CF2;
    text-align: center;
    cursor:pointer;
    height: 28px;
    line-height: 28px;
    padding: 0 10px;
    margin-top: -14px;
  }
}
.query-list{
  .query-card{
    background: #fff;
    width: 49.5%;
    padding: 10px;
    margin-bottom: 10px;
    box-shadow: 0px 1px 10px 0px rgba(0,0,0,0.08);
    border-radius: 8px;
    position: relative;
    .goods-image{
      div{
        width:66px;
        height:60px;
        line-height:60px;
        margin-right:10px;
        border-radius:4px;
        float:left;
        text-align: center;
        background: #f5f5f5;
        color:#999;
      }
      el-image{
        width:66px;
        height:60px;
        margin-right:10px;
        border-radius:4px;
        float:left;
      }
    }
    .goods-info{
      div:first-child{
        font-size:15px;
        color:#333;
        margin-top:-4px;
      }
      div:nth-child(2){
        font-size:14px;
        color:#999;
        margin:4px 0;
      }
      div:last-child{
        font-size:14px;
        color:#999;
      }
    }
    .goods-price{
      color: #999;
      p:first-child{
        margin-bottom: 5px;
        span{
          color: #F8475F;
          font-size: 18px;
          font-family: 'font-Medium';
        }
      }
    }
    .goods-action{
      max-width: 100px;
      div{
        color: #fff;
        border-radius: 4px;
        padding: 4px 8px;
        background: #999;
        text-align: center;
      }
      span{
        font-size: 12px;
        color: #F8475F;
        margin-top: 4px;
        text-align: left;
      }
    }
  }
}
</style>