<template>
	<dialogModule top="15" width="1000px" title="选择营业员" v-if="guideModule" :dialogVisible="guideModule" @close="close">
	  <span slot="close" class="el-icon-close close-icon" @click="close"></span>
	  <div slot="content" class="user-content" style="padding:15px;">
		<section class="row align-center">
			<div class="row align-center space-between search-item">
				<span class="iconfont icon-saoma"></span>
				<span class="item-line">|</span>
				<input type="text" v-model="searchKey" placeholder="搜索营业员" @keyup.enter="()=>{page = 1;listSearch();}" />
				<div class="item-sure" @click="()=>{page = 1;listSearch();}">
					<span class="iconfont icon-sousuo"></span>
				</div>
			</div>
			<div style="width:700px;">
				<!-- <p v-if="!selectedList.length" style="color:#F8475F;margin-top:-18px;margin-left:20px;">不选择默认为本店所有营业员</p> -->
				<p v-if="selectedList.length" style="margin-top:-15px;margin-left:20px;">
					<span style="color:#333;font-family: 'font-Regular';">当前营业员：</span>
					<span v-for="(user, index) in selectedList" :key="user.id">{{user.realName}}
						<span v-if="index < selectedList.length - 1">、</span>
					</span>
				</p>
			</div>
		</section>
		<div style="height:440px;overflow:hidden;">
			<drag :list="userList" :config="config1" v-if="userList.length">
				<template v-slot="{ item }">
					<div class="item" style="position:relative;" @click="clickHandel(item)" :style="{border: item.checked ? '1px solid #067CF2' : '1px solid #fff'}">
						<div v-if="item.avatar" class="avatar-img">
							<el-image class="avat" :src="item.avatar"></el-image>
						</div>
						<div v-else class="avatar-text">
							<span>{{item.realName.substring(0, 1)}}</span>
						</div>
						<div class="vip-data">
							<div class="vip-name">{{item.realName}}</div>
							<div class="vip-phone">{{item.phone}}</div>
							<div class="vip-numb">编号：{{item.erpUid}}</div>
						</div>
						<div class="row align-center space-center" v-if="item.checked" style="position:absolute;width:70px;height:70px;background:#067CF2;top:-45px;right:-35px;transform: rotate(45deg);">
							<span class="iconfont icon-gou" style="color:#fff;font-size:16px;transform: rotate(-40deg);margin-top:45px;margin-left:10px;"></span>
						</div>
					</div>
				</template>
			</drag>
			<noDataState v-else marginTop="100" textDesc="暂无营业员"></noDataState>
		</div>
		<section class="row align-center space-center">
            <el-pagination
				layout="total, prev, pager, next"
				:pager-count="5"
				:page-size="10"
				:total="totalList"
				:current-page="page - 0"
				@current-change="getCurPage">
			</el-pagination>
		</section>
		<div class="row align-center space-center bot-handel">
			<div @click="close">取 消</div>
			<div @click="sureHandel">确 定</div>
		</div>
	  </div>
	</dialogModule>
</template>

<script>
import dialogModule from '@/components/dialogModule.vue'
import drag from '@/components/drag.vue'
import noDataState from "@/components/noDataState.vue"
import { queryShoppGuide } from '@/libs/http/modules/posOrder'
export default {
  props: ['curGuider', 'type'],
  components: {
	  dialogModule,
	  drag,
	  noDataState
  },
  data(){
	  return{
		  guideModule: false,
		  userList: [],
		  config1: {
		    name: 'test',
		    push: true,
		    pull: true,
		    exchange: true,
		  },
		  searchKey: '',
		  totalList: 0,
		  page: 1,
		  selectedList: []
	  }
  },
  created(){
	  this.selectedList = [];
	  this.guideModule = true;
    if(this.curGuider && this.curGuider.length){
      this.selectedList = JSON.parse(this.curGuider);
    }else{
      this.selectedList = [];
    }
	  this.listSearch();
  },
  methods: {
	close(){
		this.guideModule = false;
		this.$emit('close');
	},
	sureHandel(){
    if(this.type === 'vip' && this.selectedList.length > 1){
      return this.$message({ type: 'error', message: "请选择一个营业员", duration: 2000,showClose:true});
    }
    if(this.selectedList.length < 2 && this.type !== 'vip'){
      return this.$message({ type: 'error', message: "请选择两个或两个以上营业员，只有一个营业员请选择个人业绩", duration: 2000,showClose:true});
    }
    this.guideModule = false;
		this.$emit('sureBtn', this.selectedList);
	},
	async listSearch(){
		let data = await queryShoppGuide({
			keyword: this.searchKey,
			page: this.page,
			pageSize: '10'
		});
		this.userList = data.items || [];
		this.totalList = Number(data.totalItem) || 0;
		this.userList.map(item=>{
			item.draggable = true;
			this.$set(item, 'checked', false);
			this.selectedList.forEach(user=>{
				if(item.id == user.id){
					this.$set(item, 'checked', true);
				}
			})
		})
	},
	getCurPage(val){
		this.page = val
		this.listSearch()
	},
	clickHandel(item) {
		item.checked = !item.checked
		let ind = this.selectedList.findIndex(user=>{
			return user.id == item.id
		})
		if(item.checked && ind == -1){
			this.selectedList.push(item)
		}else{
			this.selectedList.splice(ind, 1)
		}
    },
  }
}
</script>

<style lang="scss" scoped>
.close-icon{
	color: #999; 
	padding:10px; 
	margin-right: -10px; 
	cursor: pointer;
}
.search-item{
	box-shadow: 0px 0px 21px 0px rgba(95, 199, 255, 0.48);
	width: 200px;
	height: 30px;
	line-height: 30px;
	margin-left: 10px;
	margin-bottom: 20px;
	background:#fff;
	border-radius:4px;
	padding-left: 10px;
    color:#067CF2;
	.item-line{
		margin: 0 8px;
	}
	input{
		outline: none;
		width: 100%;
		border: none;
	}
	.item-sure{
		width:50px;
		background:#067CF2;
		color:#fff;
		text-align:center;
		border-radius: 4px;
		cursor: pointer;
	}
}

.avatar-img{
	width:60px;
	height:60px;
	border-radius:50%;
	margin: 10px auto 0;
	.avat{
		width:60px;
		height:60px;
		float:left;
		border-radius:50%;
	}
}
.avatar-text{
	width:60px;
	height:60px;
	line-height:60px;
	border-radius:50%;
	background:#067CF2;
	color:#fff;
	font-size:24px;
	text-align:center;
	font-family: 'font-Regular';
	margin: 10px auto 0;
}
.vip-data{
	font-size: 14px;
	font-family: 'font-Regular';
	color: #000033;
	.vip-name{
		font-size: 18px;
		margin-top: 15px;
		margin-bottom: 4px;
	}
	.vip-phone{
		color: #49688B;
	}
	.vip-numb{
		margin: 20px 0 10px;
		color: #666;
	}
}
.bot-handel{
	text-align: center;
	margin: 40px auto 10px;
	font-family: 'font-Regular';
	font-size: 16px;
	& :first-child{
		width: 100px;
		line-height: 40px;
		border: 1px solid #DCDFE6;
		border-radius: 4px;
		margin-right:10px;
		cursor: pointer;
	}
	& :last-child{
		width: 100px;
		line-height: 40px;
		border-radius: 4px;
		background: #067CF2;
		color: #fff;
		cursor: pointer;
	}
}
</style>