<template>
<el-drawer
    title="挂单记录"
    v-if="hangVisiable"
    size="95%"
    :visible.sync="hangVisiable"
    @close="close">
    <section class="row">
      <asideContent ref="ording" invoicesType="1" @sendObj="getCurOrdingObj" style="margin-top:-10px;"></asideContent>
      <section class="section-main" v-if="ordingId"
        v-loading="infoDetailLoading"
        element-loading-text="请稍后，全力加载中..."
        element-loading-spinner="el-icon-loading">
        <section class="goods">
          <section class="hang-table">
            <div class="goods-top row align-center" style="border-bottom:1px solid #e1e1e1;width:100%;margin-bottom:15px;">
              <span style="margin-right: 10px;height: 20px;width: 4px;background: #067cf2;"></span>
              <p>货品信息</p>
            </div>
            <grid-manager v-if="goodsTable.length" :option="gridOption"></grid-manager>
            <div class="table-total row space-end align-center" v-if="ordingData">
              <div>总件数：<span>{{ ordingData.totalCount || "0" }}</span>件</div>
              <div>总金额：<span>￥{{ ordingData.totalRealPrice || "0.00" }}</span></div>
            </div>
          </section>

          <section class="goods-card">
            <template v-if="ordingData">
              <div class="goods-title row align-center" style="border-bottom:1px solid #e1e1e1;width:98%;">
                <span style="margin-right: 10px;height: 20px;width: 4px;background: #067cf2;"></span>
                <p>单据信息</p>
              </div>
              <div class="row space-around goods-obj">
                <div class="goods-item">
                  <div>
                    销售状态：<span>已挂起</span>
                  </div>
                  <div>
                    店铺名称：<span>{{ ordingData.deptName }}</span>
                  </div>
                  <div>
                    业绩方式：<span>{{
                      ordingData.brokerage === '1'
                        ? "个人业绩"
                        : ordingData.brokerage === '2'
                        ? "班组业绩"
                        : ordingData.brokerage === '3'
                        ? "分销"
                        : ""
                    }}</span>
                  </div>
                </div>
                <div class="goods-item">
                  <div>
                    销售单号：<span>{{ ordingData.orderNo }}</span>
                  </div>
                  <div>
                    订单性质：<span>{{ ordingData.orderTypeTitle }}</span>
                  </div>
                  <div v-if="ordingData.brokerage === '1' && ordingData.shoppingGuideName">
                    营业员：<span>{{ ordingData.shoppingGuideName }}</span>
                  </div>
                </div>
                <div class="goods-item">
                  <div>
                    单据来源：<span>{{
                      ordingData.source === '1' ? "手机端" : ordingData.source === '2' ? "客户端" : ''
                    }}</span>
                  </div>
                  <div>
                    创建时间：<span>{{ ordingData.createTime }}</span>
                  </div>
                  <div v-if="ordingData.createTerminalTitle">创建订单收银台：<span>{{ordingData.createTerminalTitle}}</span></div>
                </div>
              </div>
              <div class="row" style="margin: -8px 10px 0;" v-if="ordingData.brokerage === '2' && ordingData.shoppingGuideName">
                <span style="color:#999;">班组人员：</span><span class="description">{{ ordingData.shoppingGuideName }}</span>
              </div>
            </template>
          </section>
          <section class="goods-card">
            <template v-if="vipData && vipData.phone">
              <div class="goods-title row align-center" style="border-bottom:1px solid #e1e1e1;width:98%;margin-bottom:15px;">
                <span style="margin-right: 10px;height: 20px;width: 4px;background: #067cf2;"></span>
                <p>会员信息</p>
              </div>
              <div class="row space-around goods-obj">
                <div class="goods-item">
                  <div>
                    会员昵称：<span>{{ vipData.nickname }}</span>
                  </div>
                  <div>
                    会员卡号：<span>{{ vipData.cardNumber }}</span>
                  </div>
                  <div>
                    累计消费金额：<span>{{ vipData.totalSellAmount }}</span>
                  </div>
                  <div>
                    件单价：<span>{{ vipData.unitPrice }}</span>
                  </div>
                  <div>
                    会员优惠券：<span>{{ vipData.realName }}</span>
                  </div>
                </div>
                <div class="goods-item">
                  <div>
                    开卡时间：<span>{{ vipData.createTime }}</span>
                  </div>
                  <div>
                    累计消费次数：<span>{{ vipData.totalSellCount }}</span>
                  </div>
                  <div>
                    会员积分：<span>{{ vipData.integralBalance }}</span>
                  </div>
                </div>
                <div class="goods-item">
                  <div>
                    手机号：<span>{{ vipData.phone }}</span>
                  </div>
                  <div>
                    开卡店铺：<span>{{ vipData.deptName }}</span>
                  </div>
                  <div>
                    平均消费金额：<span>{{ vipData.avgSellAmount }}</span>
                  </div>
                  <div>
                    会员余额：<span>{{ vipData.accountBalance }}</span>
                  </div>
                </div>
              </div>
            </template>
          </section>
        </section>
        <section class="bot-fixed row align-center">
          <div class="tools-btn" @click="deleteCurHang" style="background: #43435C;">删除</div>
          <div class="tools-btn" @click="sureFetch" style="background: #DD8595;">确认取单</div>
        </section>
      </section>
      <section class="section-main" style="background: #fff" v-else>
        <noDataState textDesc="暂无订单详情数据" marginTop="280"></noDataState>
      </section>
    </section>
</el-drawer>
</template>

<script>
import asideContent from "@/views/salesRecord/components/asideContent";
import noDataState from "@/components/noDataState.vue";
import { takeOutPendingList, queryInvoicesInfo } from "@/libs/http/modules/posOrder";
export default {
  components: {
    asideContent,
    noDataState,
  },
  data() {
    return {
      gridOption: {
        gridManagerName: "ordinghange",
        firstLoading: true,
        height: "400px",
        isCombSorting: true,
        supportAutoOrder: false,
        supportCheckbox: false,
        supportConfig: true,
        disableAutoLoading: true,
        supportMenu: false,
        disableBorder: true,
        disableLine: true,
        columnData: [
          {
            key: "goodsName",
            text: "货品信息",
            align: "center",
            width: "300px",
            template: () => {
              return `
                        <div class="row align-cneter" style="text-align:left;">
                        <div style="position:relative;overflow:hidden;width:102px;height:92px;border-radius:4px;">
                          <el-image v-if="row.goodsImg && row.goodsImg[0]" style="width:102px;height:92px;border-radius:4px;float:left;" :preview-src-list="row.goodsImg" :src="row.goodsImg[0]"></el-image>
                          <div v-else style="width:102px;height:92px;border-radius:4px;text-align:center;line-height:92px;color:#999;background:#f5f5f5;">暂无图片</div>
                          <div v-if="row.activeId"
                            style="
                            width:70px;height:22px;line-height:22px;position:absolute;top:4px;right:-19px;transform: rotate(45deg);text-align:center;
                            background: linear-gradient(-90deg, #EC4620, #F08702, #F7D400);color:#fff;font-size:12px;">
                            {{'促销'}}
                          </div>
                        </div>
                        <div class="column space-center" style="margin-left:10px;flex:1;width:170px;">
                          <div style="font-size:18px;color:#333;margin-top:-4px;">{{row.goodsName}}</div>
                          <div style="font-size:14px;color:#999;margin:4px 0;">货号：{{row.goodsNo}}</div>
                          <div style="font-size:14px;color:#999;">条码：{{row.barCode}}</div>
                        </div>
                      </div>
                    `;
            },
          },
          {
            key: "colorDesc",
            text: "颜色 / 尺码",
            align: "center",
            width: "150px",
            template: () => {
              return `
                        <div v-if="row.attrList" class="row space-center align-center text-center" style="border:1px solid #DEEEF9;background:#fff;color:#333;line-height:26px;border-radius:2px;cursor:pointer;">
                            <p v-for="(item, index) in row.attrList.slice(0,2)" :key="item.code" style="">{{item.value}}
                                <span v-if="index == 0" style="margin:0 3px;">,</span>
                            </p>
                        </div>
                    `;
            },
          },
          {
            key: "number",
            text: "数量",
            align: "center",
            width: "120px",
            template: () => {
              return `
                        <div style="font-size:20px;">
                            {{row.number}}
                        </div>
                        `;
            },
          },
          {
            key: "unitRealPrice",
            text: "实卖价(单价)",
            align: "center",
            width: "120px",
            template: () => {
              return `
                        <div class="row align-center space-center">
                            <span style="color:#666;font-size:20px;">￥{{row.unitRealPrice}}</span>
                        </div>
                    `;
            },
          },
          {
            key: "tagPrice",
            text: "吊牌价",
            align: "center",
            template: () => {
              return `
                        <div class="row align-center space-center">
                            <span style="font-size:20px;">￥{{row.tagPrice}}</span>
                        </div>
                    `;
            },
          },
          {
            key: "discount",
            text: "折扣",
            align: "center",
            template: () => {
              return `
                        <div class="row align-center space-center">
                            <span style="font-size:20px;">{{(row.unitRealPrice / row.tagPrice * 10).toFixed(1)}}折</span>
                        </div>
                    `;
            },
          },
          {
            key: "money",
            text: "金额(元)",
            align: "center",
            template: () => {
              return `
                        <div class="row align-cneter space-center" style="color:#F8475F;font-size:20px;">
                            ￥{{row.totalRealPrice}}
                        </div>
                    `;
            },
          },
        ],
        ajaxData: this.showGoodsTable,
      },
      goodsTable: [],
      ordingData: null,
      vipData: null,
      ordingId: "",
      invoicesNo: "",
      infoDetailLoading: false,
      hangVisiable: false,
    };
  },
  created(){
    this.hangVisiable = true;
    this.$nextTick(()=>{
      this.$refs.ording.queryHangList();
    })
  },
  methods: {
    close(){
      this.hangVisiable = false;
      this.$emit('close');
    },
    getCurOrdingObj(e) {
      if (e && e.id && e.invoicesNo) {
        this.ordingId = e.id;
        this.invoicesNo = e.invoicesNo;
        this.queryInvoices(e.first);
      } else {
        this.ordingId = "";
        this.invoicesNo = "";
      }
    },
    queryInvoices() {
      this.infoDetailLoading = true;
      queryInvoicesInfo({
        orderId: this.ordingId
      })
      .then(data => {
        if (data.storeInfo) {
          this.goodsTable = data.storeInfo;
          GridManager.refreshGrid(this.gridOption.gridManagerName);
        }
        this.ordingData = data.orderInfo || null;
        this.vipData = data.memberInfo || null;
        setTimeout(()=>{
          this.infoDetailLoading = false;
        },500);
      })
      .catch(err => {
        this.$message({
          type: "error",
          message: err,
          duration: 2000,
          showClose: true,
        });
        this.infoDetailLoading = false;
      });
    },
    showGoodsTable() {
      if (this.goodsTable.length) {
        return new Promise((resolve, reject) => {
          const tableData = {
            data: this.goodsTable,
          };
          resolve(tableData);
        });
      }
    },
    deleteCurHang() {
      this.$confirm("是否删除该条挂单记录", "提示")
      .then(() => {
        this.$refs.ording.deleteCurOrding();
      })
      .catch((err) => {});
    },
    sureFetch() {
      this.$confirm("是否提取该订单数据", "提示")
      .then(() => {
		  const cashierItem = JSON.parse(this.$cache.local.get('cashierDeskItem'))
        this.infoDetailLoading = true;
        takeOutPendingList({
          terminalId: cashierItem ? cashierItem.id : '',
          id: this.ordingId,
          isUpdate: 'N'
        })
        .then(data => {
          this.$emit('getOrding', data);
          this.$refs.ording.delecardList();
          this.infoDetailLoading = false;
          this.close();
        })
        .catch(err => {
          this.infoDetailLoading = false;
          this.$message({ type: "error", message: err, duration: 2000, showClose: true, });
        });
      })
      .catch(err => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.description {
  display: inline-block;
  line-height: 20px;
}
.section-main {
  width: calc(100% - 520px);
  height: calc(100vh - 90px);
  margin-top: 10px;
  position: relative;
  .bot-fixed {
    position: absolute;
    bottom: 0;
    width: 100%;
    background: #fff;
    height: 60px;
    box-shadow: 0px -2px 16px 0px rgba(4, 51, 97, 0.1);
    border-radius: 2px;
    font-family: "font-Regular";
    z-index: 9;
    .tools-btn {
      border-radius: 4px;
      color: #fff;
      width: 100px;
      text-align: center;
      line-height: 40px;
      margin-left: 10px;
      cursor: pointer;
    }
  }
}
.goods {
  height: calc(100vh - 140px);
  overflow: auto;
  .goods-top {
    font-family: "font-Medium";
    color: #656d78;
    padding-bottom: 12px;
  }
  .hang-table {
    background: #fff;
    padding: 0 10px;
    .table-total {
      height: 50px;
      border-top: 1px solid #f5f5f5;
      font-family: "font-Light";
      > div {
        margin-right: 20px;
      }
      span {
        color: #f8475f;
        font-size: 20px;
        font-family: "font-Medium";
      }
    }
  }
  .goods-card {
    background: #fff;
    margin-top: 10px;
    font-family: "font-Light";
    font-size: 14px;
    .goods-title {
      font-size: 18px;
      font-family: "font-Medium";
      color: #656d78;
      width: 180px;
      padding: 10px 0;
      margin: 0 10px;
    }
    .goods-obj {
      flex: 1;
      color: #999;
      padding: 10px;
      .goods-item {
        width: calc(100% / 3);
        div {
          line-height: 30px;
          span {
            color: #000;
          }
        }
      }
    }
  }
}
</style>

<style>
.hang-table .table-wrap {
  height: auto !important;
}
</style>