<template>
	<section>
		<dialogModule width="1700px" top="5" title="货品查询" v-if="changeVisiable" :dialogVisible="changeVisiable" @close="close">
			<span slot="close" class="el-icon-close close" @click="close"></span>
			<div slot="content" style="padding:15px;" class="row">
				<section class="search-from">
					<el-form label-position="top" size="small" class="query-from" :model="queryForm" @submit.native.prevent>
						<el-form-item label="扫吊牌条码查询">
							<input class="input-scan" type="text" v-focusInput placeholder="请扫描" v-model="queryForm.skuCode"/>
						</el-form-item>
						<el-form-item label="货号/简码">
							<el-input clearable placeholder="请输入" v-model="queryForm.goodsNo"></el-input>
						</el-form-item>
						<el-form-item label="款式分类">
							<el-cascader
								style="width: 100%;"
								v-model="queryForm.categoryId"
								filterable
								collapse-tags
								clearable
								:show-all-levels="true"
								:options="categoryListOne"
								:props="{
									label: 'categoryName',
									value: 'id',
									children: 'child',
									multiple: false,
									checkStrictly: true
								}"
							></el-cascader>
						</el-form-item>
						<el-form-item label="年份">
							<el-select clearable v-model="queryForm.yearIdList" multiple placeholder="请选择" style="width: 100%;">
								<el-option
									v-for="item in categoryListThree"
									:key="item.id"
									:label="item.categoryName"
									:value="item.id">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="季节">
							<el-select clearable v-model="queryForm.quarterIdList" multiple placeholder="请选择" style="width: 100%;">
								<el-option label="春季" value="春季"></el-option>
								<el-option label="夏季" value="夏季"></el-option>
								<el-option label="秋季" value="秋季"></el-option>
								<el-option label="冬季" value="冬季"></el-option>
								<el-option label="通季" value="通季"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="风格分类">
							<el-select clearable v-model="queryForm.styleCategoryId" multiple placeholder="请选择" style="width: 100%;">
								<el-option
									v-for="item in categoryListTwo"
									:key="item.id"
									:label="item.categoryName"
									:value="item.id">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="尺码">
							<el-input clearable placeholder="请输入" v-model="queryForm.size"></el-input>
						</el-form-item>
						<el-form-item label="颜色码">
							<el-input clearable placeholder="请输入" v-model="queryForm.color"></el-input>
						</el-form-item>
						<div class="tip-text">
							可按吊牌颜色精准查询，也可按颜色关键字模糊查询，如输入黑，可查询带“黑”的颜色
						</div>
						<el-form-item>
							<el-button class="btn-width" type="primary" native-type="submit" :loading="loading" @click="currentPage = 1;submitForm();">查询(回车键)</el-button>
							<el-button class="btn-width" @click="resetQuery">重置</el-button>
						</el-form-item>
					</el-form>
				</section>
				<section class="goods-box">
					<div class="action-top row align-center space-between">
						<span>货品列表</span>
						<div>
							<span>显示库存：</span>
							<el-checkbox label="库存" :checked="true" disabled></el-checkbox>
							<el-checkbox label="占用库存" v-model="occupyStock"></el-checkbox>
							<el-checkbox label="可用库存" v-model="usableStock"></el-checkbox>
						</div>
						<span>
              <el-radio v-model="queryForm.stock" label="1" @input="changeStock">仅看有[库存]</el-radio>
              <el-radio v-model="queryForm.stock" label="2" @input="changeStock">仅看负[库存]</el-radio>
							<el-checkbox label="仅看有调价" v-model="queryForm.isAdjustPrice" @change="changeAdjust"></el-checkbox>
					   </span>
					</div>
					<div class="list-box"
						 v-loading="loading"
						 element-loading-text="请稍后，全力加载中..."
						 element-loading-spinner="el-icon-loading"
						 element-loading-background="rgba(0, 0, 0, 0)">
						<noDataState v-if="!listData.length" textDesc="暂无库存数据" margin-top="220"></noDataState>
						<template v-else>
							<div class="row align-center space-between list-item" v-for="(item, index) in listData" :key="index">
								<div class="row align-center">
									<div class="row img-attr">
										<div class="goods-img">
											<el-image lazy v-if="item.images && item.images[0]" class="has-image" :src="item.images[0]" :preview-src-list="item.images"></el-image>
											<div class="none-image" v-else>暂无图片</div>
											<span class="price-label" v-if="item.enableTime">调价</span>
										</div>
										<div class="column space-between goods-attr">
											<span>{{ item.title }}</span>
											<span>货号：{{ item.goodsNo }}</span>
											<span>颜色：{{ item.color }}</span>
											<span class="color-gray">{{ item.classifyOne }}&nbsp;{{ item.classifyTwo }}&nbsp;{{ item.classifyThree }}</span>
										</div>
									</div>
									<div class="column space-between color-gray goods-price">
										<span>吊牌价：￥{{ item.tagPrice || '0.00' }}</span>
										<span>零售价：￥{{ item.retailPrice || '0.00' }}</span>
										<span>当前销售价：<span class="color-red">￥{{ item.currentSellingPrice || '0.00' }}</span></span>
										<span v-if="item.enableTime">调价生效日期：{{ item.enableTime }}</span>
									</div>
									<div class="size-stock">
										<p>
											<span v-if="item.currentBar">对应的条码：{{item.currentBar}}</span>
										</p>
										<div class="text-center row" v-if="item.skuList">
											<div class="column label-title">
												<span class="cell-span">尺码</span>
												<span class="cell-span">库存</span>
												<span class="cell-span" v-if="occupyStock">占用库存</span>
												<span class="cell-span" v-if="usableStock">可用库存</span>
											</div>
											<div v-for="(size, s) in item.skuList" :key="s" :class="['column', 'stock-row', item.checkBarCode === size.sku ? 'check-row' : '']" @click="selectSize(item, size, s)">
												<span class="cell-span">{{size.size}}</span>
												<span class="cell-span">
													<i v-if="Number(size.stock) !== 0">{{size.stock}}</i>
												</span>
												<span class="cell-span" v-if="occupyStock">
													<i v-if="Number(size.occupiedQuantity) !== 0">{{size.occupiedQuantity}}</i>
												</span>
												<span class="cell-span" v-if="usableStock">
													<i v-if="Number(size.usableQuantity) !== 0" :style="{color: Number(size.usableQuantity) < 0 ? 'red' : '#0b83f3'}">{{size.usableQuantity}}</i>
												</span>
											</div>
										</div>
									</div>
								</div>
								<div class="text-center goods-action">
									<div class="row space-between">
										<span class="btn one" @click="showOtherDept(item)">其他店铺</span>
										<span class="btn two" @click="stockAccountCardModule = true; currentGoodsNo = item.goodsNo">库存账卡</span>
									</div>
									<div class="btn three" @click="addCollent(item)">加入收银台</div>
								</div>
							</div>
						</template>
					</div>
					<div class="page-line row">
						<el-pagination
							layout="total, sizes, prev, pager, next, jumper"
							:current-page="currentPage"
							:page-sizes="[100, 200, 300, 400]"
							:page-size="pageSizeOne"
							:total="totalItem"
							@size-change="handleSizeChange"
							@current-change="handleCurrentChange">
						</el-pagination>
					</div>
				</section>
			</div>
		</dialogModule>
		<dialogModule width="1000px" top="16" title="其他店铺【可用库存】" v-if="stockModule" :dialogVisible="stockModule" @close="stockModule = false;nowName = '';">
			<span slot="close" class="el-icon-close close" @click="stockModule = false;nowName = '';"></span>
			<div slot="content" style="padding:15px;">
				<div class="row align-center single-row">
					<div class="row align-center row-shadow">
						<p class="row align-center">
							<span class="el-icon-search"></span>
							<input type="text" v-model="nowName" placeholder="请输入店铺名称" @keyup.enter="page = 1;queryOther();"/>
						</p>
					</div>
					<el-button :loading="loading" class="row-search" @click="page = 1; queryOther();">搜 索</el-button>
					<el-checkbox v-model="isYesStock" @change="()=>{page = 1;queryOther()}">只显示有库存</el-checkbox>
				</div>
				<section class="row align-center space-between cureent-card">
					<div class="row align-center card-left">
						<div class="card-left-img">
							<el-image v-if="currentItem && currentItem.images[0]" class="goods-img" :src="currentItem.images[0]" :preview-src-list="currentItem.images"></el-image>
							<div v-else class="null-img">暂无图片</div>
						</div>
						<div class="card-number">
							<div>{{ currentItem.title }}</div>
							<p>货号：{{ currentItem.goodsNo }}</p>
							<p>条码：{{ currentItem.currentBar ? currentItem.currentBar : currentItem.skuList[0].barCode }}</p>
						</div>
					</div>
					<div>
						<p>吊牌价：￥{{ currentItem.tagPrice || '0.00' }}</p>
						<p>零售价：￥{{ currentItem.retailPrice || '0.00' }}</p>
					</div>
					<div class="card-center" style="border:1px solid #DEEEF9;width:130px;line-height:26px;">
						<div class="row space-center align-center">
							<p>{{currentItem.color}}</p>
							<span style="margin:0 5px;">,</span>
							<p>{{currentItem.checkSize ? currentItem.checkSize : currentItem.skuList[0].size}}</p>
						</div>
					</div>
				</section>
				<section style="margin:10px;"
						 v-loading="loading"
						 element-loading-text="请稍后，全力加载中..."
						 element-loading-spinner="el-icon-loading"
						 element-loading-background="rgba(0, 0, 0, 0)">
					<div style="max-height: 400px;overflow: auto;">
						<div v-if="!tableList.length" style="margin: 100px auto;font-size: 16px;text-align: center;">
							暂无数据
						</div>
						<section v-else class="row align-center other-dept"
								 style="border-bottom: 1px solid #dcdcdc;padding: 5px 0;"
								 v-for="(item, idx) in tableList" :key="idx">
							<div class="row align-center" style="width: 200px;">
								<div class="card-number">
									<p>店铺名称：<span style="font-weight: bold;color: #000;">{{ item.name }}</span></p>
									<p>距离本店：{{ item.km }}km</p>
									<p>联系电话：{{ item.phone }}</p>
								</div>
							</div>
							<div class="row space-center card-center">
								<div class="title">
									<p style="width: 65px;">尺码</p>
									<p style="width: 65px;">可用库存</p>
								</div>
								<div class="row body">
									<div
										v-for="(attr, index) in item.attrList"
										:key="index"
										:class="attr.value === attrValue && idx === currentIdx ? 'select-val' : ''">
										<p>{{ attr.value }}</p>
										<p style="color:#067CF2;" :style=" Number(attr.stock) > 0 ? 'background:#067CF2;color:#fff;' : ''">
											<span v-if="Number(attr.stock) !== 0">{{ attr.stock }}</span>
										</p>
									</div>
								</div>
							</div>
						</section>
					</div>
					<el-pagination
						layout="total, prev, pager, next"
						:total="total"
						:page-size="pageSize"
						:pager-count="5"
						:current-page="page - 0"
						@current-change="cahngeCurPage">
					</el-pagination>
				</section>
			</div>
		</dialogModule>
		<stockAccountCardVue v-if="stockAccountCardModule" :goodsNo="currentGoodsNo" @close="stockAccountCardModule = false"></stockAccountCardVue>
	</section>
</template>

<script>
import dialogModule from "@/components/dialogModule.vue";
import noDataState from "@/components/noDataState.vue";
import {querySingleStock, queryOtherDeptStock} from '@/libs/http/modules/posOrder'
import stockAccountCardVue from './stockAccountCard.vue';
import {queryAllByType} from "@/libs/http/api";
export default {
	props: ['initValue'],
	components: {
		dialogModule,
		noDataState,
		stockAccountCardVue
	},
	data() {
		return {
			stockAccountCardModule: false,
			changeVisiable: false,
			loading: false,
			isYesStock: false,
			listData: [],
			tableList: [],
			stockModule: false,
			nowName: '',
			currentGoodsNo: '',
			currentItem: null,
			total: 0,
			page: '1',
			pageSize: 10,
			attrValue: '',
			currentIdx: '',
			queryForm: {
				skuCode: '',
				goodsNo: '',
				categoryId: '',
				styleCategoryId: '',
				yearIdList: '',
				quarterIdList: '',
				size: '',
				color: '',
        stock: '',
        isAdjustPrice: ''
			},
      occupyStock: false,
      usableStock: false,

			categoryListOne: [],
			categoryListTwo: [],
			categoryListThree: [],
			totalItem: 0,
			currentPage: 1,
			pageSizeOne: 100
		}
	},
	created() {
		this.changeVisiable = true
		if (this.initValue) {
			this.queryForm.skuCode = this.initValue
			this.submitForm()
		}
		this.getCategoryOrStyle(0)
		this.getCategoryOrStyle(1)
		this.getCategoryOrStyle(2)
	},
	methods: {
		close() {
			this.changeVisiable = false;
			this.$emit('close');
		},
		getCategoryOrStyle(type) {
			queryAllByType({ type }).then((data) => {
				if(type === 0){
					this.categoryListOne = data.map((item) => ({
						categoryName: item.categoryName,
						id: item.id,
						child: item.child ? item.child.map((i) => ({
							categoryName: i.categoryName,
							id: i.id
						})) : []
					})) || []
				}
				if(type === 1){
					this.categoryListTwo = data.map((item) => ({
						categoryName: item.categoryName,
						id: item.id
					})) || []
				}
				if(type === 2){
					this.categoryListThree = data.map((item) => ({
						categoryName: item.categoryName,
						id: item.id
					})) || []
				}
			}).catch((error) => {
				this.$message({type: 'error', message: error, duration: 2000, showClose: true});
			})
		},
		submitForm() {
			this.loading = true
			let reqData = {
				page: this.currentPage,
				pageSize: this.pageSizeOne
			}
			for (let key in this.queryForm) {
				if (this.queryForm[key] && this.queryForm[key].length && key !== 'isAdjustPrice' && key !== 'stock') {
					reqData[key] = this.queryForm[key]
				}
			}
			if (this.queryForm.isAdjustPrice) {
				reqData['isAdjustPrice'] = 'Y'
			} else {
				reqData['isAdjustPrice'] = 'N'
			}
      if(this.queryForm.stock){
        if(this.queryForm.stock === '1'){
          reqData['isShowZeroStock'] = 'Y'
          reqData['isNegativeStock'] = 'N'
        }else{
          reqData['isShowZeroStock'] = 'N'
          reqData['isNegativeStock'] = 'Y'
        }
      }else{
        reqData['isShowZeroStock'] = 'N'
        reqData['isNegativeStock'] = 'N'
      }
			querySingleStock(reqData).then(res => {
				if(res && res.items){
					res.items.forEach(item=>{
						this.$set(item, 'checkSize', '')
						this.$set(item, 'checkBarCode', '')
						if(item.enableTime){
							item.enableTime = item.enableTime.split(' ')[0]
						}
						if(item.categoryName){
							let classifyOne = item.categoryName.split('/')
							let value = ''
							if(classifyOne[0]) {
								value = classifyOne[0]
							}
							if(classifyOne[1]){
								value = classifyOne[0] + '/' + classifyOne[1]
							}
							this.$set(item, 'classifyOne', value)
						}
						if(item.timeCategoryName){
							let classifyTwo = item.timeCategoryName.split('/')
							let value = ''
							if(classifyTwo[0]) {
								value = classifyTwo[0]
							}
							if(classifyTwo[1]){
								value = classifyTwo[0] + '/' + classifyTwo[1]
							}
							this.$set(item, 'classifyTwo', value)
						}
						if(item.styleCategoryName){
							let classifyThree = item.styleCategoryName.split('/')
							let value = ''
							if(classifyThree[0]) {
								value = classifyThree[0]
							}
							this.$set(item, 'classifyThree', value)
						}
						if(item.skuList){
							if(this.queryForm.skuCode){
								item.skuList.forEach(s=>{
									if(this.queryForm.skuCode === s.sku){
										this.$set(item, 'checkSize', s.size)
										this.$set(item, 'checkBarCode', s.sku)
									}
								})
							}
							let total = {
								size: '合计',
								stock: '',
								occupiedQuantity: '',
								usableQuantity: ''
							}
							total.stock = item.skuList.reduce((c, R) => c + Number(R.stock || 0), 0);
							total.occupiedQuantity = item.skuList.reduce((c, R) => c + Number(R.occupiedQuantity || 0), 0);
							total.usableQuantity = item.skuList.reduce((c, R) => c + Number(R.usableQuantity || 0), 0);
							item.skuList.push(total)
						}
					})
				}
				this.listData = res.items || [];
				this.totalItem = Number(res.totalItem) || 0;
				this.loading = false;
			}).catch(error => {
				this.listData = [];
				this.totalItem = 0;
				this.loading = false;
				this.$message({type: 'error', message: error, duration: 2000, showClose: true});
			})
		},
		resetQuery() {
			for (let key in this.queryForm) {
				if (this.queryForm[key]) {
					this.queryForm[key] = ''
				}
			}
			this.currentPage = 1;
      this.submitForm();
		},
		changeStock() {
			this.currentPage = 1;
			this.submitForm();
		},
		changeAdjust() {
			this.currentPage = 1;
			this.submitForm();
		},
		handleSizeChange(e) {
			this.pageSizeOne = e;
			this.currentPage = 1;
			this.submitForm();
		},
		handleCurrentChange(e) {
			this.currentPage = e;
			this.submitForm();
		},
		selectSize(item, size){
			if(!size.sku) return
			item.checkSize = size.size
			item.checkBarCode = size.sku
      item.currentBar = size.barCode
		},
		addCollent(item) {
			if (!item.checkBarCode) {
				return this.$message({type: 'error', message: "请选择尺码", duration: 2000, showClose: true});
			}
			this.close();
			this.$emit("skuData", item.checkBarCode);
		},
		showOtherDept(item){
			this.stockModule = true;
			this.page = '1'
			this.currentItem = item;
			this.currentGoodsNo = item.goodsNo;
			this.queryOther()
		},
		queryOther() {
			this.loading = true;
			queryOtherDeptStock({
				queryType: '1',
				page: this.page + '',
				deptName: this.nowName,
				pageSize: this.pageSize + '',
				goodsNo: this.currentGoodsNo,
				colorId: this.currentItem.colorId,
				isShowHasStock: this.isYesStock ? 'Y' : 'N'
			}).then(data => {
				this.loading = false;
				this.total = Number(data.totalItem);
				this.tableList = data.items || [];
				this.tableList.forEach(item => {
					item.attrList = []
					item.items.forEach(bar => {
						bar.jsonResp.attr.forEach(attr => {
							if (attr.isHorizontal === 'Y') {
								item.attrList.push({
									value: attr.value,
									stock: bar.stock,
									barCode: bar.barcode
								})
							}
						})
					})
				})
			}).catch(err => {
				this.loading = false;
				this.$message({type: 'error', message: err, duration: 2000, showClose: true});
			})
		},
		cahngeCurPage(val) {
			this.page = val;
			this.queryOther();
		},
	}
}
</script>

<style lang="scss" scoped>
.close {
	color: #999;
	padding: 10px;
	margin-right: -10px;
	cursor: pointer
}
.color-gray {
	color: #999;
}
.color-red {
	color: red;
}
.search-from {
	width: 270px;
	border: 1px solid #ddd;
	padding: 10px;
	.input-scan {
		border: 1px solid red;
		outline: none;
		line-height: 28px;
		padding: 0 10px;
		border-radius: 4px;
		width: 100%;
		background: #FFE190;
	}
	.tip-text {
		color: red;
		margin-top: -10px;
		font-size: 12px;
		margin-bottom: 50px;
	}
	.btn-width {
		width: 47%;
	}
}
.goods-box {
	margin-left: 10px;
	width: calc(100% - 280px);
	.action-top {
		border: 1px solid #ddd;
		background: #eee;
		padding: 5px 10px;
	}
	.list-box {
		border: 1px solid #ddd;
		border-top: none;
		height: 75vh;
		overflow: auto;
		.list-item {
			border-bottom: 1px solid #ddd;
			padding: 10px;
			color: #000;
			&:last-child {
				border-bottom: none;
			}
			.img-attr{
				width: 360px;
				.goods-img {
					width: 100px;
					height: 100px;
					background: #eee;
					margin-right: 10px;
					position: relative;
					overflow: hidden;
					.has-image {
						width: 100px;
						height: 100px;
						float: left;
					}
					.none-image {
						line-height: 100px;
						color: #999;
						text-align: center;
					}
					.price-label {
						position: absolute;
						right: -18px;
						top: 6px;
						width: 60px;
						height: 18px;
						line-height: 18px;
						font-size: 14px;
						color: #fff;
						transform: rotate(50deg);
						background: #FF9000;
						text-align: center;
					}
				}
				.goods-attr {
					margin-right: 10px;
					height: 100px;
				}
			}
			.goods-price{
				width: 200px;
			}
			.size-stock {
				p {
					height: 25px;
				}
				.cell-span {
					height: 26px;
					line-height: 26px;
					border-top: 1px solid #ddd;
					border-left: 1px solid #ddd;
				}
				.label-title {
					span {
						width: 70px;
						
						&:last-child {
							border-bottom: 1px solid #ddd;
						}
					}
				}
				.stock-row {
					cursor: pointer;
					span {
						width: 40px;
						i {
							font-style: normal;
						}
						&:last-child {
							border-bottom: 1px solid #ddd;
						}
					}
					&:last-child {
						border-right: 1px solid #ddd;
					}
				}
				.check-row{
					span{
						background: #0b83f3;
						color: #fff;
						i{
							color: #fff !important;
						}
					}
				}
			}
			.goods-action {
				.btn {
					border-radius: 4px;
					line-height: 30px;
					cursor: pointer;
				}
				.one {
					width: 80px;
					background: #0b83f3;
					color: #fff;
				}
				.two {
					width: 80px;
					border: 1px solid #ddd;
					color: #0b83f3;
				}
				.three {
					width: 170px;
					background: #FF9000;
					color: #fff;
					margin-top: 10px;
				}
			}
		}
	}
	.page-line {
		border: 1px solid #ddd;
		background: #eee;
		border-top: none;
	}
}


.single-row {
	text-align: center;
	margin: 0 10px;
	
	.row-shadow {
		box-shadow: 0px 2px 10px 0px rgba(6, 124, 242, 0.21);
		line-height: 30px;
		border-radius: 4px 0 0 4px;
		
		.row-type {
			position: relative;
			
			p {
				border-right: 1px solid #eee;
				width: 70px;
				color: #067CF2;
			}
		}
		
		> p {
			margin: 0 10px;
			
			input {
				border: none;
				background: none;
				outline: none;
				margin-left: 10px;
				height: 28px;
				line-height: 28px;
			}
		}
	}
	
	.row-search {
		padding: 0;
		width: 80px;
		line-height: 30px;
		background: #067CF2;
		color: #fff;
		margin-right: 30px;
	}
	
	.choose-value {
		position: absolute;
		top: 32px;
		left: 0;
		background: #fff;
		box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.08);
		width: 100px;
		z-index: 9;
		
		div {
			line-height: 30px;
			cursor: pointer;
			
			&:hover {
				background: #eff7ff;
			}
		}
	}
	
	.show-type {
		border: 1px solid #067CF2;
		text-align: center;
		line-height: 30px;
		border-radius: 4px;
		font-family: 'font-Regular';
		color: #000;
		
		p {
			width: 90px;
			line-height: 30px;
			cursor: pointer;
		}
		
		& :first-child {
			border-radius: 4px 0 0 4px;
		}
		
		& :last-child {
			border-radius: 0 4px 4px 0;
		}
		
		.row-active {
			background: #067CF2;
			color: #fff;
		}
	}
}

.card-left {
	width: 280px;
	
	.card-left-img {
		margin-right: 10px;
		
		.goods-img {
			width: 102px;
			height: 92px;
			border-radius: 4px;
			text-align: center;
			line-height: 92px;
			float: left;
		}
		
		.null-img {
			width: 102px;
			height: 92px;
			border-radius: 4px;
			text-align: center;
			line-height: 92px;
			color: #999;
			background: #f5f5f5;
		}
	}
	
	.card-number {
		div {
			font-family: 'font-Regular';
			color: #333;
			font-size: 18px;
		}
		
		p {
			color: #999;
			font-size: 14px;
		}
	}
}

.card-right {
	color: #fff;
	text-align: center;
	width: 170px;
	
	.add-btn {
		background: #FF9000;
		width: 170px;
		line-height: 30px;
		border-radius: 4px;
		margin-top: 10px;
		cursor: pointer;
	}
	
	p {
		width: 80px;
		line-height: 30px;
		border-radius: 4px;
		cursor: pointer;
	}
	
	.other-btn {
		background: #067CF2;
		// flex: 1;
	}
	
	.stock-accountCard-btn {
		background: white;
		color: #067CF2;
	}
	
	.stock-btn {
		background: #43D268;
	}
}

.list-attr {
	overflow: auto;
	margin-top: 20px;
	height: 54vh;
	
	.attr-card {
		box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.08);
		border-radius: 8px;
		padding: 10px;
		margin: 10px;
		
		&:last-child {
			margin-bottom: 20px;
		}
	}
	
	.card-center {
		text-align: center;
		
		> div {
			width: 130px;
			border: 1px solid #DEEEF9;
			background: #fff;
			color: #333;
			line-height: 26px;
			border-radius: 2px;
			cursor: pointer;
			margin: 0 auto
		}
		
		> p {
			color: #999;
			margin-top: 10px;
		}
	}
}

.other-dept {
	.card-center {
		text-align: center;
		
		.title {
			border: 1px solid #eee;
			
			&:first-child {
				border-bottom: 1px solid #eee;
			}
		}
		
		.body {
			flex-wrap: wrap;
			border-top: 1px solid #eee;
			
			div {
				width: 45px;
				border-right: 1px solid #eee;
				cursor: pointer;
				border-bottom: 1px solid #eee;
			}
		}
		
		p {
			width: 45px;
			height: 30px;
			line-height: 30px;
		}
		
		p:nth-child(odd) {
			border-bottom: 1px solid #eee;
		}
		
		.select-val {
			background: #067CF2;
			
			p {
				border: none;
				color: #fff !important;
			}
		}
	}
}

.list-obj {
	height: 55vh;
	overflow: hidden;
	
	.attr-card {
		box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.08);
		border-radius: 8px;
		padding: 10px;
		margin: 50px 10px 0;
	}
	
	.card-center {
		text-align: center;
		flex: 1;
		margin: 0 10px;
		
		.title {
			border: 1px solid #eee;
			
			&:first-child {
				border-bottom: 1px solid #eee;
			}
		}
		
		.body {
			//max-width: 360px;
			flex-wrap: wrap;
			border-top: 1px solid #eee;
			
			div {
				width: 45px;
				border-right: 1px solid #eee;
				cursor: pointer;
				border-bottom: 1px solid #eee;
			}
			
		}
		
		p {
			width: 45px;
			height: 30px;
			line-height: 30px;
		}
		
		p:nth-child(odd) {
			border-bottom: 1px solid #eee;
		}
		
		.select-val {
			background: #067CF2;
			
			p {
				border: none;
				color: #fff !important;
			}
		}
	}
}

.cureent-card {
	padding: 10px;
	margin: 30px 10px 20px;
	border-radius: 8px;
	box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.08);
}
</style>

<style>
.search-from .el-form--label-top .el-form-item__label {
	padding: 0;
}
</style>