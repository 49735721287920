<template>
    <dialogModule top="28" title="整单备注" v-if="descriptModule" :dialogVisible="descriptModule" @close="closeDialog">
        <span slot="close" class="el-icon-close close-icon" @click="closeDialog"></span>
        <section slot="content">
           <div style="margin:20px 20px 0">
             <el-input type="textarea" v-model="description"  rows="5" placeholder="请输入备注"></el-input>
            <div class="row align-center space-center bot-handel">
                <div @click="closeDialog">取 消</div>
                <div @click="sureText">确 定</div>
            </div>
           </div>
        </section>
  </dialogModule>
</template>

<script>
import dialogModule from "@/components/dialogModule.vue"
export default {
    props: ['descript'],
    components: {
        dialogModule
    },
    data(){
        return{
            descriptModule: false,
            description: ''
        }
    },
    created(){
        this.descriptModule = true;
        if(this.descript){
            this.description = this.descript;
        }
    },
    methods:{
        closeDialog() {
            this.descriptModule = false;
            this.$emit("close", this.description);
        },
        sureText(){
            this.descriptModule = false;
            this.$emit("close", this.description);
        }
    }
}
</script>

<style lang="scss" scoped>
.bot-handel{
	text-align: center;
	margin: 30px auto 20px;
	font-family: 'font-Regular';
	font-size: 16px;
	& :first-child{
		width: 100px;
		line-height: 40px;
		border: 1px solid #DCDFE6;
		border-radius: 4px;
		margin-right:10px;
		cursor: pointer;
	}
	& :last-child{
		width: 100px;
		line-height: 40px;
		border-radius: 4px;
		background: #067CF2;
		color: #fff;
		cursor: pointer;
	}
}
</style>
<style>
.el-textarea__inner{
    font-family: 'font-Regular';
}
</style>