<template>
  <dialogModule
    width="1400px"
    top="10"
    title="销售查询"
    v-if="changeVisible"
    :dialogVisible="changeVisible"
    @close="close">
    <span slot="close" class="el-icon-close close cursor" @click="close"></span>
    <section class="query-box" slot="content">
      <div class="row align-center top">
        <div class="top-left">
          <div class="row align-center left-tab">
            <span class="tab-item cursor" :class="currentItem === '1' ? 'active-item' : ''" @click="showItem('1')">当天流水</span>
            <span class="tab-item cursor" :class="currentItem === '2' ? 'active-item' : ''" @click="showItem('2')">昨天流水</span>
            <span class="tab-item cursor" :class="currentItem === '3' ? 'active-item' : ''" @click="showItem('3')">当天业绩</span>
            <span class="tab-item cursor" :class="currentItem === '4' ? 'active-item' : ''" @click="showItem('4')">昨天业绩</span>
            <span class="tab-item cursor" :class="currentItem === '5' ? 'active-item' : ''" @click="showItem('5')">历史销售</span>
          </div>
        </div>
        <div class="top-right row align-center">
          <div v-if="currentItem !== '5'" class="right-icon" @click="totalShow = !totalShow">
            <i v-show="!totalShow" class="el-icon-arrow-right"></i>
            <i v-show="totalShow" class="el-icon-arrow-down"></i>
          </div>
          <template v-if="totalShow">
            <div class="row align-center space-between right-total" v-if="currentItem === '1' || currentItem === '3'">
              <template v-if="totalInfo.totalOne">
                <div v-for="(item, i) in totalInfo.totalOne" :key="i">{{item.title}}：{{item.money}}</div>
              </template>
            </div>
            <div class="row align-center space-between right-total" v-if="currentItem === '2' || currentItem === '4'">
              <template v-if="totalInfo.totalTwo">
                <div v-for="(item, i) in totalInfo.totalTwo" :key="i">{{item.title}}：{{item.money}}</div>
              </template>
            </div>
          </template>
        </div>
      </div>
      <div class="main">
        <div v-show="currentItem === '1'">
          <grid-manager :option="gridTableOne"></grid-manager>
        </div>
        <div v-show="currentItem === '2'">
          <grid-manager :option="gridTableTwo"></grid-manager>
        </div>
        <div v-show="currentItem === '3'">
          <grid-manager :option="gridTableThree"></grid-manager>
        </div>
        <div v-show="currentItem === '4'">
          <grid-manager :option="gridTableFour"></grid-manager>
        </div>
        <div v-show="currentItem === '5'">
          <grid-manager :option="gridTableFive"></grid-manager>
        </div>
      </div>
    </section>
  </dialogModule>
</template>

<script>
import moment from "moment"
import dialogModule from "@/components/dialogModule.vue";
import {queryNearOrderWide, queryOrderWide, querySummaryUserWide} from "@/libs/http/modules/posOrder";
export default {
  components: {
    dialogModule
  },
  data() {
    return {
      changeVisible: true,
      totalShow: true,
      currentItem: '1',

      letList: [
        {key: 'innerSn', text: '销售单号', width: '220px'},
        {key: 'userName', text: '姓名'},
        {key: 'totalNumber', text: '件数'},
        {key: 'realPrice', text: '金额'}
      ],
      rightList: [
        {key: 'createTime', text: '销售时间', width: '170px'}
      ],
      gridTableOne: {
        height: '65vh',
        gridManagerName: 'gridTableOne',
        firstLoading: true,
        isCombSorting: true,
        supportCheckbox: false,
        supportAutoOrder: false,
        useTrFocus: true,
        lineHeight: '20px',
        columnData: [
          {key: 'innerSn', text: '销售单号', width: '220px'},
          {key: 'userName', text: '姓名'},
          {key: 'totalNumber', text: '件数'},
          {key: 'realPrice', text: '金额'},
          {
            key: 'cashPayment',
            text: '现金支付',
            template: () => {
              return `
                <div v-if="row.cashPayment">{{row.cashPayment}}</div>
              `
            }
          },
          {
            key: 'lklPayment',
            text: '拉卡拉支付',
            template: () => {
              return `
                <div v-if="row.lklPayment">{{row.lklPayment}}</div>
              `
            }
          },
          {
            key: 'scanPayment',
            text: '扫码支付',
            template: () => {
              return `
                <div v-if="row.scanPayment">{{row.scanPayment}}</div>
              `
            }
          },
          {
            key: 'memberBalancePayment',
            text: '会员余额支付',
            template: () => {
              return `
                <div v-if="row.memberBalancePayment">{{row.memberBalancePayment}}</div>
              `
            }
          },
          {key: 'createTime', text: '销售时间', width: '170px'}
        ],
        ajaxData: this.fetchOneList,
        summaryHandler: (data) => {
          let list = ['totalNumber', 'realPrice', 'cashPayment', 'ccbPayment', 'scanPayment', 'lklPayment', 'memberBalancePayment', 'pocketAppPayment', 'pocketPayment', 'tiktokPayment', 'unionPayment']
          let obj = {
            innerSn: '合计'
          }
          list.forEach((key) => {
            let total = data.reduce((c, R) => c + Number(R[key] || 0), 0)
            if(total === 0){
              obj[key] = ''
            }else{
              if(key === 'totalNumber'){
                obj[key] = total
              }else {
                obj[key] = total.toFixed(2)
              }
            }
          })
          return obj
        },
        supportAjaxPage: false
      },
      gridTableTwo: {
        height: '65vh',
        gridManagerName: 'gridTableTwo',
        firstLoading: true,
        isCombSorting: true,
        supportCheckbox: false,
        supportAutoOrder: false,
        useTrFocus: true,
        lineHeight: '20px',
        columnData: [
          {key: 'innerSn', text: '销售单号', width: '220px'},
          {key: 'userName', text: '姓名'},
          {key: 'totalNumber', text: '件数'},
          {key: 'realPrice', text: '金额'},
          {
            key: 'cashPayment',
            text: '现金支付',
            template: () => {
              return `
                <div v-if="row.cashPayment">{{row.cashPayment}}</div>
              `
            }
          },
          {
            key: 'lklPayment',
            text: '拉卡拉支付',
            template: () => {
              return `
                <div v-if="row.lklPayment">{{row.lklPayment}}</div>
              `
            }
          },
          {
            key: 'scanPayment',
            text: '扫码支付',
            template: () => {
              return `
                <div v-if="row.scanPayment">{{row.scanPayment}}</div>
              `
            }
          },
          {
            key: 'memberBalancePayment',
            text: '会员余额支付',
            template: () => {
              return `
                <div v-if="row.memberBalancePayment">{{row.memberBalancePayment}}</div>
              `
            }
          },
          {key: 'createTime', text: '销售时间', width: '170px'}
        ],
        ajaxData: this.fetchTwoList,
        summaryHandler: (data) => {
          let list = ['totalNumber', 'realPrice', 'cashPayment', 'ccbPayment', 'scanPayment', 'lklPayment', 'memberBalancePayment', 'pocketAppPayment', 'pocketPayment', 'tiktokPayment', 'unionPayment']
          let obj = {
            innerSn: '合计'
          }
          list.forEach((key) => {
            let total = data.reduce((c, R) => c + Number(R[key] || 0), 0)
            if(total === 0){
              obj[key] = ''
            }else{
              if(key === 'totalNumber'){
                obj[key] = total
              }else {
                obj[key] = total.toFixed(2)
              }
            }
          })
          return obj
        },
        supportAjaxPage: false
      },
      gridTableThree: {
        height: '65vh',
        gridManagerName: 'gridTableThree',
        firstLoading: true,
        isCombSorting: true,
        supportCheckbox: false,
        supportAutoOrder: false,
        useTrFocus: true,
        lineHeight: '20px',
        columnData: [
          {key: 'userName', text: '姓名'},
          {
            key: 'totalPaperCount',
            text: '单数',
            template: () => {
              return `
                <div v-if="row.totalPaperCount">{{row.totalPaperCount}}</div>
              `
            }
          },
          {
            key: 'totalAmount',
            text: '金额',
            template: () => {
              return `
                <div v-if="row.totalAmount">{{row.totalAmount}}</div>
              `
            }
          },
          {
            key: 'userTotalAmount',
            text: '公单',
            template: () => {
              return `
                <div v-if="row.userTotalAmount">{{row.userTotalAmount}}</div>
              `
            }
          },
          {
            key: 'totalAmountSummary',
            text: '业绩合计',
            template: () => {
              return `
                <div v-if="row.totalAmountSummary">{{row.totalAmountSummary}}</div>
              `
            }
          }
        ],
        ajaxData: this.fetchThreeList,
        summaryHandler: (data) => {
          let list = ['totalPaperCount', 'totalAmount', 'userTotalAmount', 'totalAmountSummary']
          let obj = {
            userName: '合计'
          }
          list.forEach((key) => {
            let total = data.reduce((c, R) => c + Number(R[key] || 0), 0)
            if(total === 0){
              obj[key] = ''
            }else{
              if(key === 'totalPaperCount'){
                obj[key] = total
              }else {
                obj[key] = total.toFixed(2)
              }
            }
          })
          return obj
        },
        supportAjaxPage: false
      },
      gridTableFour: {
        height: '65vh',
        gridManagerName: 'gridTableFour',
        firstLoading: true,
        isCombSorting: true,
        supportCheckbox: false,
        supportAutoOrder: false,
        useTrFocus: true,
        lineHeight: '20px',
        columnData: [
          {key: 'userName', text: '姓名'},
          {
            key: 'totalPaperCount',
            text: '单数',
            template: () => {
              return `
                <div v-if="row.totalPaperCount">{{row.totalPaperCount}}</div>
              `
            }
          },
          {
            key: 'totalAmount',
            text: '金额',
            template: () => {
              return `
                <div v-if="row.totalAmount">{{row.totalAmount}}</div>
              `
            }
          },
          {
            key: 'userTotalAmount',
            text: '公单',
            template: () => {
              return `
                <div v-if="row.userTotalAmount">{{row.userTotalAmount}}</div>
              `
            }
          },
          {
            key: 'totalAmountSummary',
            text: '业绩合计',
            template: () => {
              return `
                <div v-if="row.totalAmountSummary">{{row.totalAmountSummary}}</div>
              `
            }
          }
        ],
        ajaxData: this.fetchFourList,
        summaryHandler: (data) => {
          let list = ['totalPaperCount', 'totalAmount', 'userTotalAmount', 'totalAmountSummary']
          let obj = {
            userName: '合计'
          }
          list.forEach((key) => {
            let total = data.reduce((c, R) => c + Number(R[key] || 0), 0)
            if(total === 0){
              obj[key] = ''
            }else{
              if(key === 'totalPaperCount'){
                obj[key] = total
              }else {
                obj[key] = total.toFixed(2)
              }
            }
          })
          return obj
        },
        supportAjaxPage: false
      },
      gridTableFive: {
        height: '65vh',
        gridManagerName: 'gridTableFive',
        firstLoading: true,
        isCombSorting: true,
        supportCheckbox: false,
        supportAutoOrder: false,
        useTrFocus: true,
        lineHeight: '20px',
        columnData: [
          {key: 'forDate', text: '日期'},
          {key: 'totalPaperCount', text: '单数'},
          {key: 'totalAmount', text: '金额'}
        ],
        ajaxData: this.fetchNearly,
        summaryHandler: (data) => {
          let list = ['totalPaperCount', 'totalAmount']
          let obj = {
            forDate: '合计'
          }
          list.forEach((key) => {
            let total = data.reduce((c, R) => c + Number(R[key] || 0), 0)
            if(key === 'totalAmount'){
              obj[key] = total.toFixed(2)
            }else {
              obj[key] = total
            }
          })
          return obj
        },
        supportAjaxPage: false
      },
      totalInfo: {}
    }
  },
  methods: {
    close() {
      this.changeVisible = false
      this.$emit('close')
    },
    showItem(item){
      if(this.currentItem === item) return
      this.currentItem = item
    },
    dealData(data, type){
      // let columnList = []
      let totalList = []
      if(data && data.queryOrderPayVO){
        let total = data.queryOrderPayVO
        if(total.cashPayment){
          totalList.push({
            title: '现金',
            money: total.cashPayment
          })
        }
        if(total.lklPayment){
          totalList.push({
            title: '拉卡拉',
            money: total.lklPayment
          })
        }
        if(total.scanPayment){
          totalList.push({
            title: '扫码',
            money: total.scanPayment
          })
        }
        if(total.memberBalancePayment){
          totalList.push({
            title: '会员',
            money: total.memberBalancePayment
          })
        }
        if(total.totalPayment){
          totalList.push({
            title: '总金额',
            money: total.totalPayment
          })
        }
        if(type === 'today'){
          this.$set(this.totalInfo, 'totalOne', totalList)
        }else{
          this.$set(this.totalInfo, 'totalTwo', totalList)
        }

        // if(total.ccbPayment){
        //   totalList.push({
        //     title: '建行',
        //     money: total.ccbPayment
        //   })
        //   columnList.push({
        //     key: 'ccbPayment',
        //     text: '建行',
        //     template: () => {
        //       return `
        //         <div v-if="row.ccbPayment">{{row.ccbPayment}}</div>
        //       `
        //     }
        //   })
        // }
        // if(total.pocketAppPayment){
        //   totalList.push({
        //     title: '口袋零钱APP',
        //     money: total.pocketAppPayment
        //   })
        //   columnList.push({
        //     key: 'pocketAppPayment',
        //     text: '口袋零钱APP',
        //     template: () => {
        //       return `
        //         <div v-if="row.pocketAppPayment">{{row.pocketAppPayment}}</div>
        //       `
        //     }
        //   })
        // }
        // if(total.pocketPayment){
        //   totalList.push({
        //     title: '口袋零钱',
        //     money: total.pocketPayment
        //   })
        //   columnList.push({
        //     key: 'pocketPayment',
        //     text: '口袋零钱',
        //     template: () => {
        //       return `
        //         <div v-if="row.pocketPayment">{{row.pocketPayment}}</div>
        //       `
        //     }
        //   })
        // }
        // if(total.unionPayment){
        //   totalList.push({
        //     title: '银联',
        //     money: total.unionPayment
        //   })
        //   columnList.push({
        //     key: 'unionPayment',
        //     text: '银联',
        //     template: () => {
        //       return `
        //         <div v-if="row.unionPayment">{{row.unionPayment}}</div>
        //       `
        //     }
        //   })
        // }
        // if(total.tiktokPayment){
        //   totalList.push({
        //     title: '抖音核销',
        //     money: total.tiktokPayment
        //   })
        //   columnList.push({
        //     key: 'tiktokPayment',
        //     text: '抖音核销',
        //     template: () => {
        //       return `
        //         <div v-if="row.tiktokPayment">{{row.tiktokPayment}}</div>
        //       `
        //     }
        //   })
        // }
      }
    },
    fetchOneList(){
      return new Promise(async (resolve, reject) => {
        try {
          let data = await queryOrderWide({
            startTime: moment().format('YYYY-MM-DD') + ' ' + '00:00',
            endTime: moment().format('YYYY-MM-DD') + ' ' + '23:59'
          })
          if(data){
            this.dealData(data, 'today')
          }
          const tableData = {
            data: data.queryOrderVOList || []
          };
          resolve(tableData);
        }catch(e) {
          this.$message({type: 'error', message: e, duration: 2000, showClose: true});
          reject(e);
        }
      });
    },
    fetchTwoList(){
      return new Promise(async (resolve, reject) => {
        try {
          let data = await queryOrderWide({
            startTime: moment().subtract(1, 'days').format('YYYY-MM-DD') + ' ' + '00:00',
            endTime: moment().subtract(1, 'days').format('YYYY-MM-DD') + ' ' + '23:59'
          })
          if(data){
            this.dealData(data, 'yesterday')
          }
          const tableData = {
            data: data.queryOrderVOList || []
          };
          resolve(tableData);
        }catch(e) {
          this.$message({type: 'error', message: e, duration: 2000, showClose: true});
          reject(e);
        }
      });
    },
    fetchThreeList(){
      return new Promise(async (resolve, reject) => {
        try {
          let data = await querySummaryUserWide({
            queryDate: moment().format('YYYYMMDD')
          })
          const tableData = {
            data: data || []
          };
          resolve(tableData);
        }catch(e) {
          this.$message({type: 'error', message: e, duration: 2000, showClose: true});
          reject(e);
        }
      });
    },
    fetchFourList(){
      return new Promise(async (resolve, reject) => {
        try {
          let data = await querySummaryUserWide({
            queryDate: moment().subtract(1, 'days').format('YYYYMMDD')
          })
          const tableData = {
            data: data || []
          };
          resolve(tableData);
        }catch(e) {
          this.$message({type: 'error', message: e, duration: 2000, showClose: true});
          reject(e);
        }
      });
    },

    fetchNearly(){
      return new Promise(async (resolve, reject) => {
        try {
          let data = await queryNearOrderWide({
            startTime: moment(new Date()).subtract(7, 'days').format('YYYYMMDD'),
            endTime: moment().format('YYYYMMDD')
          })
          const tableData = {
            data: data || []
          };
          resolve(tableData);
        }catch(e) {
          this.$message({type: 'error', message: e, duration: 2000, showClose: true});
          reject(e);
        }
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.query-box{
  height: 75vh;
}
.top{
  padding: 15px;
  background: #eee;
  .top-left{
    margin-right: 20px;
    .left-tab{
      border-radius: 4px;
      border: 1px solid #0b83f3;
      .tab-item{
        width: 120px;
        line-height: 34px;
        text-align: center;
        color: #0b83f3;
        font-size: 16px;
        background: #fff;
        border-right: 1px solid #0b83f3;
        &:first-child{
          border-radius: 4px 0 0 4px;
        }
        &:last-child{
          border: none;
          border-radius: 0 4px 4px 0;
        }
      }
      .active-item{
        color: #fff;
        background: #0b83f3;
      }
    }
  }
  .top-right{
    flex: 1;
    background: #fff;
    height: 38px;
    border-radius: 4px;
    padding-right: 30px;
    font-size: 16px;
    .right-icon{
      font-size: 20px;
      width: 40px;
      text-align: center;
      cursor: pointer;
    }
    .right-total{
      flex: 1;
      margin-left: 30px;
    }
  }
}
.main{
  padding: 15px;
}
</style>