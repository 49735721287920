<template>
  <TransitionGroup
    name="group-list"
    tag="li"
    class="row flex-wrap"
  >
    <li
      v-for="(item, index) in list"
      :key="item.id"
      :draggable="item.draggable"
      :class="[
        'list-item',
        {
          'is-dragover': index == dropIndex && item.draggable && config.exchange
        },
      ]"
      @dragstart="onDragstart($event, index)"
      @dragenter="onDragenter(index)"
      @dragover.prevent="onDragover(index)"
      @dragleave="onDragleave"
      @dragend="onDragend"
      @drop="onDrop"
    >
      <slot :item="item" />
    </li>
  </TransitionGroup>
</template>

<script>
export default {
  name: "Draggable",
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    config: {
      type: Object,
      default: () => ({
        name: "",
        push: true,
        pull: true,
        exchange: true,
      }),
    },
  },
  data() {
    return {
      dragIndex: null,
      dropIndex: null
    };
  },
  computed: {
    isPush() {
      const { dropIndex, dragIndex } = this;
      return dropIndex !== null && dragIndex === null;
    },
    isExchange() {
      const { dropIndex, dragIndex } = this;
      return dragIndex !== null && dropIndex !== null;
    },
    pushCbName() {
      const {
        config: { name },
      } = this;
      return `${name}-push-callback`;
    },
  },
  methods: {
    onDragstart(e, i) {
      const {
        list,
        config: { name },
        transferData,
      } = this;
      this.dragIndex = i;
      if (name) {
        transferData({ e, key: name, type: "set", data: list[i] });
      } else {
        throw new Error("缺少配置关联名name");
      }
      this.$emit("drag-start", i);
    },
    onDragenter(i) {
      this.dropIndex = i;
      this.$emit("drag-enter", i);
    },
    onDragover(i) {
      const { dragIndex, dropIndex } = this;
      if (i === dragIndex || i === dropIndex) return;
      this.dropIndex = i;
      this.$emit("drag-over", i);
    },
    onDragleave() {
      this.dropIndex = null;
    },
    onDrop(e) {
      const {
        list,
        dropIndex,
        dragIndex,
        config: { name, push: enablePush, exchange },
        isPush,
        isExchange,
        pushCbName,
        storage,
        resetIndex,
        transferData,
      } = this;

      if (dropIndex === dragIndex || !exchange) return;
      if (isPush) {
        if (!enablePush) {
          resetIndex();
          return;
        }
        if (name) {
          list.splice(
            dropIndex,
            0,
            transferData({ e, key: name, type: "get" })
          );
          storage("set", pushCbName, true);
        } else {
          resetIndex();
          throw new Error("缺少配置关联属性name");
        }
        resetIndex();
        return;
      }

      if (isExchange) {
        const drapItem = list[dragIndex];
        const dropItem = list[dropIndex];
        list.splice(dropIndex, 1, drapItem);
        list.splice(dragIndex, 1, dropItem);
      }
      resetIndex();
    },

    onDragend() {
      const {
        list,
        dragIndex,
        config: { pull: enablePull },
        pushCbName,
        storage,
        resetIndex,
      } = this;
      if (enablePull) {
        const isPushSuccess = storage("get", pushCbName);
        if (isPushSuccess) {
          list.splice(dragIndex, 1);
          storage("remove", pushCbName);
        }
      }
      resetIndex();
      this.$emit("drag-end");
    },
    storage(type, key, value) {
      return {
        get() {
          // return JSON.parse(localStorage.getItem(key));
        },
        set() {
          // localStorage.setItem(key, JSON.stringify(value));
        },
        remove() {
          // localStorage.removeItem(key);
        },
      }[type]();
    },

    resetIndex() {
      this.dropIndex = null;
      this.dragIndex = null;
    },

    transferData({ e, key, type, data } = {}) {
      if (type === "get") {
        return JSON.parse(e.dataTransfer.getData(`${key}-drag-key`));
      }
      if (type === "set") {
        e.dataTransfer.setData(`${key}-drag-key`, JSON.stringify(data));
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.list-item {
  list-style: none;
  position: relative;
  margin: 10px 10px 20px;
  border-radius: 4px;
  // padding: 10px;
  background: #fff;
  border: 1px solid #fff;
  width: calc(20% - 20px);
  text-align: center;
  box-shadow: 0px 0px 10px 0px rgba(204, 204, 204, 0.38);
  overflow: hidden;
}
.click-item {
  background: #F6FAFF;
}
// .click-item::before{
//   content: "";
//   position: absolute;
//   top: -35px;
//   right: -35px;
//   width: 70px;
//   height: 70px;
//   background: #0c6bc9;
//   transform: rotate(45deg);
// }
// .click-item::after{
//   content: "";
//   background-color: white;
//   width: 50px;
//   height: 8px;
//   position: absolute;
//   top: 100px;
//   left: 32px;
//   transform: rotate(30deg);
//   border-radius: 8px;
// }
.list-item.is-dragover::before {
  content: "";
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: #0c6bc9;
}
.list-item.is-dragover::after {
  content: "";
  //   position: absolute;
  //   bottom: -8px;
  //   left: -6px;
  //   border: 3px solid #0c6bc9;
  //   border-radius: 50%;
  //   width: 6px;
  //   height: 6px;
  //   background-color: #fff;
}
.group-list-move {
  transition: transform 0.8s;
}
</style>