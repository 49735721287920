<template>
  <section>
    <dialogModule top="10" width="90%" title="会员充值记录" :dialogVisible="vipRechargeType" bgColor="#f5f7fb"
      data-keyboard=“false” @close="closeDialog">
      <span slot="close" class="el-icon-close close-icon cursor" @click="closeDialog"></span>
      <div slot="content" class="row" style="background:#FFF;padding:10px;">
        <div style="height: 800px;width: 100%;padding: 10px;">
          <div style="display: flex;justify-content: space-between;">
            <div class="row-box">
              <img src="../../../assets/images/recharge(5).png" style="width: 100%;height: 128px;" alt="" />
              <div class="row-top">
                <div class="row-img">
                  <img style="width: 70px;height: 70px;" src="@/assets/images/Recharge(2).png" alt="" />
                </div>
                <div>
                  <p> 新增会员(人)</p>
                  <h1>{{ vipStatisticData.vipCount }}</h1>
                </div>
              </div>
            </div>
            <div class="row-box">
              <img src="../../../assets/images/recharge(6).png" style="width: 100%;height: 128px;" alt="" />
              <div class="row-top">
                <div class="row-img">
                  <img style="width: 70px;height: 70px;" src="@/assets/images/Recharge(3).png" alt="" />
                </div>
                <div>
                  <p> 充值次数(笔)</p>
                  <h1>{{ vipStatisticData.rechargeTimes }}</h1>
                </div>
              </div>
            </div>
            <div class="row-box">
              <img src="../../../assets/images/recharge(7).png" style="width: 100%;height: 128px;" alt="" />
              <div class="row-top">
                <div class="row-img">
                  <img style="width: 70px;height: 70px;" src="@/assets/images/Recharge(4).png" alt="" />
                </div>
                <div>
                  <p> 充值金额(元)</p>
                  <h1>￥{{ vipStatisticData.rechargeAmount }}</h1>
                </div>
              </div>
            </div>
          </div>
          <div style="display: flex;width: 100%;justify-content: space-between;margin-top: 30px;">
            <div class="search-div">
              时间范围
              <el-date-picker style="margin-left: 10px;" size="small" v-model="timer" type="datetimerange"
                format="yyyy-MM-dd" value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期"
                end-placeholder="结束日期">
              </el-date-picker>
            </div>
            <div style="width: 270px;">
              会员名称<el-input v-model="name" size="small" style="width: 200px;margin-left: 10px;"
                placeholder="请输入"></el-input>
            </div>
            <div style="width: 260px;">
              手机号<el-input v-model="phone" size="small" style="width: 200px;margin-left: 10px;"
                placeholder="请输入"></el-input>
            </div>
            <div style="width: 260px;">
              状态
              <el-select v-model="state" style="width:180px;margin-left: 10px;" size="small">
                <el-option v-for="item in payType" :key="item.value" :value="item.value" :label="item.label"></el-option>
              </el-select>
            </div>
            <div>
              <el-button style="width: 100px;border-radius: 4px; " size="small" @click="resetSearch">重置</el-button>
              <el-button style="width: 100px;border-radius: 4px; background-color: #077cf2;color: #FFF;" size="small"
                @click="searchRecharge">确定</el-button>
            </div>
          </div>
          <el-table :data="tableData" :header-cell-style="{ textAlign: 'center', background: '#fafafa' }"
            :cell-style="{ textAlign: 'center' }"
            style="width: 100%;margin-top: 30px;border: 1px solid #dedede;border-bottom: 0;margin-bottom: 0;"
            height="500px">
            <el-table-column type="expand" width="40px">
              <template slot-scope="props">
                <el-form label-position="left" inline class="demo-table-expand">
                  <el-form-item label="会员名称">
                    <span>{{ props.row.nickName }}</span>
                  </el-form-item>
                  <el-form-item label="手机号">
                    <span>{{ props.row.phone }}</span>
                  </el-form-item>
                  <el-form-item label="单号">
                    <span>{{ props.row.sn }}</span>
                  </el-form-item>
                  <el-form-item label="金额">
                    <span>{{ props.row.amount }}</span>
                  </el-form-item>
                  <el-form-item label="充值前">
                    <span>{{ props.row.lastBalance }}</span>
                  </el-form-item>
                  <el-form-item label="充值后">
                    <span>{{ props.row.afterBalance }}</span>
                  </el-form-item>
                  <el-form-item label="待支付金额">
                    <span>{{ props.row.unpaidAmount }}</span>
                  </el-form-item>
                  <el-form-item label="已支付金额">
                    <span>{{ props.row.paidAmount }}</span>
                  </el-form-item>
                  <el-form-item label="支付方式">
                    <span>{{ payState(props.row) }}</span>
                  </el-form-item>
                  <el-form-item label="时间">
                    <span>{{ props.row.createTime }}</span>
                  </el-form-item>
                  <el-form-item label="状态">
                    <span>{{ itemState(props.row) }}</span>
                  </el-form-item>
                </el-form>
              </template>
            </el-table-column>
            <el-table-column label="会员名称" prop="nickName" width="120px">
            </el-table-column>
            <el-table-column label="手机号" prop="phone" width="140px">
            </el-table-column>
            <el-table-column label="单号" prop="sn" width="180px">
            </el-table-column>
            <el-table-column label="金额" prop="amount" width="110px">
            </el-table-column>
            <el-table-column label="充值前" prop="lastBalance" width="110px">
            </el-table-column>
            <el-table-column label="充值后" prop="afterBalance" width="110px">
            </el-table-column>
            <el-table-column label="待支付金额" prop="unpaidAmount" width="110px">
            </el-table-column>
            <el-table-column label="已支付金额" prop="paidAmount" width="110px">
            </el-table-column>
            <el-table-column label="支付方式" prop="stateList" style="display: flex;justify-content: center;">
              <template slot-scope="scope" style="display: flex;justify-content: center;">
                <p :title="payState(scope.row)"
                  style="width: 100%;text-align: center;text-overflow: ellipsis;overflow: hidden;word-break: break-all;white-space: nowrap;">
                  {{ payState(scope.row) }}</p>
              </template>
            </el-table-column>
            <el-table-column label="时间" prop="createTime" width="200px">
            </el-table-column>
            <el-table-column label="状态" prop="state" width="100px">
              <template slot-scope="scope">
                <div class="box-yuan" v-if="scope.row.state == '0'">
                  <div class="yuan" style="background-color:#f78989;"></div>
                  <span style="color: #f78989;">
                    未支付
                  </span>
                </div>
                <div class="box-yuan" v-if="scope.row.state == '1'">
                  <div class="yuan" style="background-color:#e6a23c;"></div>
                  <span style="color: #e6a23c;">
                    部分支付
                  </span>
                </div>
                <div class="box-yuan" v-if="scope.row.state == '2'">
                  <div class="yuan" style="background-color:#67c23a;"></div>
                  <span style="color: #67c23a;">
                    已支付
                  </span>
                </div>
                <div class="box-yuan" v-if="scope.row.state == '3'">
                  <div class="yuan" style="background-color:#909399;"></div>
                  <span style="color: #909399;">
                    已取消
                  </span>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="操作" prop="desc" width="280px">
              <template slot-scope="scope">
                <el-button v-if="scope.row.state == '0' || scope.row.state == '1'" size="mini"
                  style="background-color: #67c23a;color: white;"
                  @click="handleEdit(scope.$index, scope.row)">继续支付</el-button>
                <el-button v-if="scope.row.state == '0' || scope.row.state == '1'" size="mini" type="danger"
                  @click="handleDelete(scope.$index, scope.row)">取消支付</el-button>
              </template>
            </el-table-column>
          </el-table>
          <section class="section-page row align-center space-end" v-show="totalVip > '0'">
            <el-pagination layout="total, prev, pager, next" :total="totalVip" :page-size="10" :pager-count="5"
              @current-change="cahngeCurPage" :current-page="page - 0">
            </el-pagination>
          </section>
        </div>
      </div>
    </dialogModule>
    <MemberRecharge v-if="rechargeDialog" @close="closeRecharge" payAgain="Y" :againData="againData"
      @lookRecharge="lookRecharge"></MemberRecharge>
  </section>
</template>
  
<script>
import moment from "moment";
import dialogModule from "@/components/dialogModule.vue";
import MemberRecharge from "./MemberRecharge";
import { vipRechargeStatistic, vipAccountRechargeRecode, secondValidState, accountRechargeBill } from '@/libs/http/modules/vip';
export default {
  components: {
    dialogModule,
    MemberRecharge
  },
  data() {
    return {
      tableData: [],
      vipStatisticData: {},
      deptId: '',//店铺id
      timer: [
        moment().startOf('month').format('YYYY-MM-DD'),
        moment().endOf('month').format('YYYY-MM-DD'),
      ],
      searchLoading: false,
      vipList: [],
      totalVip: 0,
      name: '',
      phone: '',
      state: '2',
      page: 1,
      vipRechargeType: false,
      payType: [
        {
          label: '未支付',
          value: '0'
        },
        {
          label: '部分支付',
          value: '1'
        },
        {
          label: '已支付',
          value: '2'
        },
        {
          label: '已取消',
          value: '3'
        },
      ],
      rechargeDialog: false,
      againData: null,
    };
  },
  created() {
    this.vipRechargeType = true;
    this.deptId = this.$cache.local.get('deptId')
    this.vipStatisticList();
    this.AccountRechargeList();
  },
  methods: {
    handleDelete(e, row) {
      let reqData = {
        id: row.id,
      }
      this.$confirm('确认取消这笔充值记录？', '提示').then(() => {
        accountRechargeBill(reqData).then(data => {
          this.searchRecharge();
        }).catch(err => {
          this.searchLoading = false;
          this.$message({ type: 'error', message: err, duration: 2000, showClose: true })
        })
      }).catch(err => { })
    },
    handleEdit(e, row) {
      secondValidState({
        id: row.id
      }).then(data => {
        if (data === 'true') {
          this.$message({ type: 'success', message: `当前单据金额发生变化`, duration: 2000, showClose: true });
          this.searchRecharge();
        } else {
          this.rechargeDialog = true;
          this.againData = row;
        }
      }).catch(error => {
        this.$message({ type: 'error', message: error, duration: 2000, showClose: true })
      })
    },
    lookRecharge() {
      this.searchRecharge();
    },
    payAfter(item) {
      if (item.state == '0') {
        return 'stateN'
      }
      if (item.state == '1') {
        return 'stateY'
      }
      if (item.state == '2') {
        return 'stateY'
      }
      if (item.state == '3') {
        return 'stateG'
      }
    },
    resetSearch() {//重置按钮
      this.timer = '';
      this.name = '';
      this.phone = '';
      this.state = '';
    },
    searchRecharge() {//确定按钮
      const searchData = {};
      if (this.timer) {
        searchData['timer'] = this.timer;
      }
      if (this.name) {
        searchData['name'] = this.name;
      }
      if (this.phone) {
        searchData['phone'] = this.phone;
      }
      if (this.state) {
        searchData['state'] = this.state;
      }
      this.AccountRechargeList(searchData)
      this.vipStatisticList()
    },
    payState(item) {
      const stateList = [];
      item.rechargeDetailList.forEach(i => {
        stateList.push(i.payType)
      });
      return stateList.join(',')
    },
    itemState(item) {//判定支付状态渲染页面
      if (item.state == '0') {
        return '未支付'
      } else if (item.state == '1') {
        return '部分支付'
      } else if (item.state == '2') {
        return '已支付'
      }
      else if (item.state == '3') {
        return '已取消'
      }
    },
    payAgin(item) {//继续支付
      secondValidState({
        id: item.id
      }).then(data => {
        if (data === 'true') {
          this.$message({ type: 'success', message: `当前单据金额发生变化`, duration: 2000, showClose: true });
          this.searchRecharge();
        } else {
          this.rechargeDialog = true;
          this.againData = item;
        }
      }).catch(error => {
        this.$message({ type: 'error', message: error, duration: 2000, showClose: true })
      })
    },
    closeRecharge(e) {//关闭支付弹窗
      this.rechargeDialog = false;
      if (e === 'refresh') {
        this.searchRecharge();
      }
    },
    cahngeCurPage(val) {
      this.page = val;
      this.searchRecharge();
    },
    vipStatisticList() {
      this.searchLoading = true;
      let reqData = {
        
      }
      if (this.timer) {
        reqData['startTime'] = this.timer[0];
        reqData['endTime'] = this.timer[1];
      }
      vipRechargeStatistic(reqData).then(data => {
        this.vipStatisticData = data;
      }).catch(err => {
        this.searchLoading = false;
        this.$message({ type: 'error', message: err, duration: 2000, showClose: true })
      })
    },
    AccountRechargeList(searchData) {
      this.searchLoading = true;
      let reqData = {
        deptId: this.deptId,
        page: this.page + '',
        pageSize: '10'
      }
      if (this.state) {
        reqData['state'] = this.state
      }
      if (searchData) {
        if (searchData.timer) {
          reqData['startTime'] = searchData.timer[0];
          reqData['endTime'] = searchData.timer[1];
        }
        if (searchData.name) {
          reqData['name'] = searchData.name;
        }
        if (searchData.phone) {
          reqData['phone'] = searchData.phone;
        }
        if (searchData.state) {
          reqData['state'] = searchData.state;
        }
      }
      vipAccountRechargeRecode(reqData).then(data => {
        this.vipList = data.items || [];
        this.totalVip = Number(data.totalItem) || 0;
        this.tableData = data.items;
      }).catch(err => {
        this.searchLoading = false;
        this.$message({ type: 'error', message: err, duration: 2000, showClose: true })
      })
    },
    closeDialog() {
      this.vipRechargeType = false;
      this.$emit("close");
    },
  },
};
</script>
  
<style lang="scss" scoped>
.row {
  .row-box {
    width: 32%;
    position: relative;

    .row-top {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      color: #fff;
      border-radius: 8px;
      display: flex;
      padding: 32px 20px;

      .row-img {
        border-radius: 8px;
        margin-right: 18px;
        background: #fff;
      }

      p {
        font-size: 18px;
        margin-bottom: 12px;
      }
    }
  }

  .vip-title {
    background: #FAFAFA;
    margin-top: 20px;
    height: 44px;
    border: 1px solid #DFDFDF;
    border-bottom: none;
  }

  .vip-title>div,
  .vip-item>div {
    height: 44px;
    line-height: 44px;
    overflow: hidden;

    span {
      margin-left: 10px;
    }
  }

  .vip-list {
    border-left: 1px solid #DFDFDF;
    border-right: 1px solid #DFDFDF;

    .vip-item {
      height: 45px;
      border-bottom: 1px solid #DFDFDF;

      .stateN {
        width: 100px;
        height: 30px;
        border: 1px solid #e7839a;
        color: #e7839a;
        line-height: 30px;
        border-radius: 5px;
      }

      .stateY {
        width: 100px;
        height: 30px;
        color: #66b37b;
        border: 1px solid #66b37b;
        line-height: 30px;
        border-radius: 5px;
      }

      .stateG {
        width: 100px;
        height: 30px;
        color: #808080;
        border: 1px solid #808080;
        line-height: 30px;
        border-radius: 5px;
      }

      .payType {
        width: 160px;
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: break-all;
        white-space: nowrap;
      }
    }

    .choose-row {
      background: #EFF7FF;
    }
  }
}

.section-page {
  margin-top: 15px;
  margin-right: -12px;
  position: absolute;
  bottom: 10px;
}

.box-yuan {
  display: flex;
  align-items: center;
  justify-content: center;

  .yuan {
    width: 10px;
    height: 10px;
    background-color: #808080;
    border-radius: 50%;
    margin-right: 10px;
  }
}
</style>

<style>
.demo-table-expand {
  font-size: 0;
  padding-left: 80px;
}

.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}

.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 30%;
  color: black;
}

.el-table__body-wrapper::-webkit-scrollbar {
  /*width: 0;宽度为0隐藏*/
  width: 2px;
  height: 0px;
}

.el-table__body-wrapper::-webkit-scrollbar-thumb {
  border-radius: 2px;
  height: 50px;
  background: #eee;
}

.el-table__body-wrapper::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  background: rgba(0, 0, 0, 0.4);
}
</style>
  
  