<template>
  <section>
    <dialogModule width="1100px" title="加购货品信息" v-if="changeVisiable" :dialogVisible="changeVisiable" @close="close">
      <span slot="close" class="el-icon-close close" @click="close"></span>
      <div slot="content">
        <div style="margin: 10px 20px;display: flex;">
          <el-input clearable size="small" placeholder="请输入货号、条码或者扫吊牌条码查询" style="width: 500px;margin-right: 20px;" v-model="searchVal" @keyup.enter.native="queryList"></el-input>
          <el-button type="primary" size="small" :loading="loading" @click="queryList">查询</el-button>
        </div>
        <div class="give-list">
          <div class="row align-center give-item" v-for="(item, index) in currentActive.addGoodInfos" :key="index" v-show="item.isShow">
            <div class="row align-cneter" @click="selectGift(item)">
              <div v-if="!item.selected" class="iconfont icon-weixuanzhongyuanquan" style="font-size:24px;color:#ccc;"></div>
              <div v-else class="iconfont icon-chenggong" style="font-size:24px;color:#067CF2;"></div>
            </div>
            <div class="row align-center" style="width: 280px;">
              <div>
                <div v-if="!item.imageList" style="width:102px;height:92px;line-height:92px;margin-right:10px;border-radius:4px;float:left;text-align: center;background: #f5f5f5;color:#999;">暂无图片</div>
                <el-image v-else style="width:102px;height:92px;margin-right:10px;border-radius:4px;float:left;" :src="item.imageList[0]" :preview-src-list="item.imageList"></el-image>
              </div>
              <div class="column space-center">
                <div style="font-size:18px;color:#333;margin-top:-4px;">{{item.goodsName}}</div>
                <div style="font-size:14px;color:#999;margin:4px 0;">货号：{{item.goodsNo}}</div>
                <div style="font-size:14px;color:#999;">条码：{{item.barCode}}</div>
              </div>
            </div>
            <div style="width: 250px;">
              <div class="row space-center align-center" style="border:1px solid #eee;background:#fff;color:#999;line-height:26px;border-radius:2px;cursor:pointer;padding:0 10px;">
                <p style="margin-right:10px;" v-for="(attr, index) in item.attr.slice(0, 2)" :key="index">{{attr.title}}：{{attr.value}}</p>
                <span v-if="0===1" class="el-icon-arrow-down" style="color:#067CF2;font-weight:bold;"></span>
              </div>
            </div>

            <div class="row align-center space-center" style="width: 180px">
              <div @click="minusNum(item)" style="width:40px;height:50px;line-height:50px;text-align:center;font-size:18px;border:1px solid #eee;color:#333;cursor:pointer;">
                <span class="el-icon-minus"></span>
              </div>
              <div style="width:60px;height:50px;line-height:50px;text-align:center;font-size:20px;border:1px solid #eee;border-left:none;color:#333;">{{item.giveCount}}</div>
              <div @click="plusNum(item)" style="background:#067CF2;color:#fff;width:40px;height:50px;line-height:50px;text-align:center;font-size:18px;cursor:pointer;">
                <span class="el-icon-plus"></span>
              </div>
            </div>

            <div style="font-size: 18px;color: #333;">
              <p>加购价：<span style="font-size: 20px;color: red;">￥{{item.realPrice}}</span></p>
              <p style="font-size: 16px;text-decoration: line-through;color: #999;margin-top: 5px;">原价：￥{{item.retailPrice}}</p>
            </div>
          </div>
        </div>

        <div class="row align-center space-center bot-handel">
          <div @click="close">取 消</div>
          <div @click="sureGetGift">确 定</div>
        </div>
      </div>
    </dialogModule>
    <ChooseGuige v-if="guigeModule" @close="closeGuigeModul" :curRow="curRow" type="ZP"></ChooseGuige>
  </section>
</template>

<script>
import dialogModule from "@/components/dialogModule.vue";
import ChooseGuige from './ChooseGuige'
export default {
  name: 'Complimentaries',
  props: ['currentActive'],
  components: {
    dialogModule,
    ChooseGuige
  },
  data(){
    return{
      changeVisiable: true,
      guigeModule: false,
      curRow: null,
      searchVal: '',
      loading: false,
      groupList: []
    }
  },
  created(){
    this.groupList = this.currentActive.exchangeGoodsNumberInfoList || []
    if(!this.currentActive.checkGive || !this.currentActive.checkGive.length){
      this.currentActive.addGoodInfos.forEach(item=>{
        this.$set(item, 'selected', false); //是否选中
        this.$set(item, 'giveCount', 0); //选中数量
        this.$set(item, 'isShow', true);//是否显示
      })
    }else{
      this.currentActive.addGoodInfos.forEach(item=>{
        this.$set(item, 'selected', false); //是否选中
        this.$set(item, 'giveCount', 0); //选中数量
        this.$set(item, 'isShow', true);//是否显示
        this.currentActive.checkGive.forEach(check => {
          if(item.sku === check.sku){
            this.$set(item, 'selected', true); //是否选中
            this.$set(item, 'giveCount', check.number); //选中数量
          }
        })
      })
    }
  },
  methods: {
    close(){
      this.changeVisiable = false;
      this.$emit('close');
    },
    showGuige(item){
      this.curRow = item
      this.guigeModule = true
    },
    closeGuigeModul(e){
      if(e){
        this.curRow.barCode = e.barCode
        this.curRow.attr[0].value = e.colorVal
        this.curRow.attr[1].value = e.sizeVal
        this.curRow.giveCount = e.giveCount
        this.curRow.sku = e.sku
        let index = this.currentActive.addGoodInfos.findIndex(item => {
          return item.sku === this.curRow.sku
        })
        this.currentActive.addGoodInfos[index] = this.curRow
      }
      this.guigeModule = false
    },
    checkNumber(item){
      const index = this.groupList.findIndex(group=>{
        return group.spuList.includes(item.goodsNo)
      })
      const groupSpu = this.groupList[index].spuList
      const groupNum = this.groupList[index].number
      const checkGroup = this.currentActive.addGoodInfos.filter(list=>{
        return groupSpu.includes(list.goodsNo)
      })
      const checkNum = checkGroup.reduce((c, R) => c + Number(R.giveCount), 0)
      return checkNum >= groupNum
    },
    selectGift(item){
      item.selected = !item.selected;
      if(item.selected === false){
        item.giveCount = 0;
      }else{
        let res = this.checkNumber(item)
        const allNum = this.currentActive.addGoodInfos.reduce((c, R) => c + (+R.giveCount), 0)
        if(allNum >= this.currentActive.addNumber || res){
          item.selected = false;
          item.giveCount = 0;
          this.$message.error({ type: "error", message: `当前选中的加购货品已达到活动设置的上限`, duration: 2000, showClose: true })
        }else{
          item.giveCount = 1
        }
      }
    },
    minusNum(item){
      if(+item.giveCount === 0)
      return this.$message.error({ type: "error", message: `当前数量为0，不能再减少了`, duration: 2000, showClose: true })
      item.giveCount--;
      item.selected = true;
      if(+item.giveCount === 0) {
        item.selected = false;
      }
    },
    plusNum(item){
      let res = this.checkNumber(item)
      const allNum = this.currentActive.addGoodInfos.reduce((c, R) => c + (+R.giveCount), 0)
      if(allNum >= this.currentActive.addNumber || res)
      return this.$message.error({ type: "error", message: `当前选中的加购货品已达到活动设置的上限`, duration: 2000, showClose: true })
      ++item.giveCount;
      item.selected = true;
    },
    sureGetGift() {
      const allNum = this.currentActive.addGoodInfos.reduce((c, R) => c + (+R.giveCount), 0)
      if(allNum > this.currentActive.addNumber)
      return this.$message.error({ type: "error", message: `当前活动最多只能选择${this.currentActive.addNumber}件加购货品`, duration: 2000, showClose: true })
      this.currentActive.checkGive = [];
      this.currentActive.addGoodInfos.forEach(item=>{
        if(!item.giveCount){
          item.selected = false
        }
        if(!item.selected){
          item.giveCount = 0
        }
        if(item.selected && item.giveCount > 0){
          this.currentActive.checkGive.push({
            sku: item.sku,
            spu: item.spu,
            number: item.giveCount,
            realPrice: item.realPrice
          })
        }
      })
      this.$emit('close', this.currentActive);
    },
    queryList(){
      this.loading = true;
      if(this.searchVal){
        this.currentActive.addGoodInfos.forEach(item=>{
          if(item.barCode.includes(this.searchVal) || item.goodsNo.includes(this.searchVal)){
            item.isShow = true;
          }else{
            item.isShow = false;
          }
        })
      }else{
        this.currentActive.addGoodInfos.forEach(item=>{
          item.isShow = true;
        })
      }
      this.loading = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.give-list{
  max-height: 500px;
  overflow: auto;
  .give-item{
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;
    margin-top: 10px;
    &:last-child{
      border-bottom: none;
    }
    >div{
      margin: 0 20px;
    }
  }
}

.close{
  color: #999;
  padding: 10px;
  margin-right: -10px;
  cursor: pointer
}
.bot-handel{
  text-align: center;
  margin: 20px auto 0;
  padding-bottom: 20px;
  font-family: 'font-Regular';
  font-size: 16px;
  & :first-child{
    width: 100px;
    line-height: 40px;
    border: 1px solid #DCDFE6;
    border-radius: 4px;
    margin-right:10px;
    cursor: pointer;
  }
  & :last-child{
    width: 100px;
    line-height: 40px;
    border-radius: 4px;
    background: #067CF2;
    color: #fff;
    cursor: pointer;
  }
}
</style>