<template>
    <dialogModule width="600px" top="32" title="整单改价" v-if="changeVisiable" :dialogVisible="changeVisiable" @close="close">
        <span slot="close" class="el-icon-close close" @click="close"></span>
        <div slot="content" style="padding:15px;">
            <section class="row align-center space-between price-row">
                <div>
                    <span>本单总金额</span>
                    <input type="text" v-model="priceAll" disabled />
                </div>
                <div>
                    <span>改价金额</span>
                    <input type="text" style="background: #EEF2FB;border:1px solid #ccc;" v-model="newPrice" @blur="surebtn" @keyup.enter="$event.target.blur()" placeholder="请输入改价金额" />
                </div>
            </section>
            <div class="row align-center space-center bot-handel">
                <div class="cancel-btn" @click="close">取 消</div>
                <el-button class="sure-btn" @click="sureText" :loading="loading">确 定</el-button>
            </div>
        </div>
    </dialogModule>
</template>

<script>
import dialogModule from "@/components/dialogModule.vue";
export default {
    props: ['priceAll'],
    components: {
        dialogModule
    },
    data(){
        return{
            changeVisiable: false,
            loading: false,
            newPrice: ''
        }
    },
    created(){
        this.changeVisiable = true;
    },
    methods: {
        close(){
            this.changeVisiable = false;
            this.$emit('close');
        },
        surebtn(){
            this.newPrice = Number(this.newPrice).toFixed(2);
        },
        sureText(){
            if(!this.newPrice) return this.$message({type: 'error', message: "请填写改价金额", duration: 2000, showClose: true})
            let obj = {
              price: this.newPrice
            }
            this.$emit('close', obj);
        }
    }
}
</script>

<style lang="scss" scoped>
.close{
  color: #999; 
  padding: 10px; 
  margin-right: -10px; 
  cursor: pointer
}
.price-row{
    margin: 30px 0 50px;
    input{
        width:200px;
        border:1px solid #DCDFE6;
        margin-left:10px;
        height:30px;
        line-height:30px;
        border-radius:4px;
        outline:none;
        padding:0 10px;
    }
}
.bot-handel {
  text-align: center;
  margin: 40px auto 10px;
  font-family: "font-Regular";
  font-size: 16px;
  .cancel-btn{
    width: 100px;
    line-height: 40px;
    border: 1px solid #dcdfe6;
    border-radius: 4px;
    margin-right: 10px;
    cursor: pointer;
  }
  .sure-btn{
    width: 100px;
    line-height: 40px;
    border-radius: 4px;
    background: #067cf2;
    color: #fff;
    cursor: pointer;
    padding: 0;
    border: none;
    font-size: 16px;
  }
}
</style>