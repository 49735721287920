<template>
  <section>
    <dialogModule
      top="8"
      width="88%"
      title="积分兑换"
      :dialogVisible="IntegralDialog"
      bgColor="#fff"
      data-keyboard="“false”"
      @close="closeDialog">
      <span slot="close" class="el-icon-close close-icon cursor" @click="closeDialog"></span>
      <div slot="content" class="row" style="background: #fff; padding: 10px; background: #f6f6f6">
        <div class="Integral-box">
          <div class="integral-left" v-loading="leftLoading">
            <div class="left-top">
              <div style="display: flex">
                <div class="items-box" style="display: flex; align-items: center; width: 330px; color: #000">
                  <span style="margin-right: 5px">礼品名称/货号</span>
                  <div
                    style="
                      border: 1px solid #888;
                      border-radius: 3px;
                      height: 30px;
                      width: 200px;
                      display: flex;
                      align-items: center;
                    ">
                    <i class="iconfont icon-saoma"></i>
                    <input
                      v-model="goodsName"
                      style="width: 82%; height: 100%; border: 0px; margin-left: 8px"
                      placeholder="扫描吊牌或输入货号查询"
                      @input="handleInput2" />
                  </div>
                </div>
                <div class="button-box" @click="getDataList('1')">查询</div>
              </div>
              <div style="color: #ff9407; margin-right: 20px">
                <h3>点击选中积分礼品后可点击右侧图片放大查看</h3>
              </div>
            </div>
            <div class="left-middle" v-if="itemsList && itemsList.length">
              <div
                v-for="(item, index) in itemsList"
                :key="index"
                @click="getItems(item, index)"
                :class="['middle-box-items', getTouch == index ? 'middle-items' : '']">
                <div class="items-image" v-if="item && item.imageInfoList">
                  <el-image class="img" :src="item.imageInfoList[0]"></el-image>
                </div>
                <div class="items-image image-not" v-else></div>
                <div class="items-right">
                  <div class="detail">
                    <div
                      style="
                        color: #000;
                        font-size: 18px;
                        width: 200px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                      "
                      :title="item.goodsName">
                      {{ item.goodsName }}
                    </div>
                    <div style="display: flex; width: 300px; height: 60px; align-items: center">
                      <div style="margin-right: 20px">{{ item.goodsNo }}</div>
                      <div
                        v-if="item.singleChangeNum > '0'"
                        style="
                          color: #f84f66;
                          width: 120px;
                          height: 22px;
                          background: #fff1f0;
                          border: 1px solid #f84f66;
                          border-radius: 3px;
                          text-align: center;
                        ">
                        单次限兑换{{ item.singleChangeNum }}个
                      </div>
                    </div>
                    <div style="color: #ff9407; font-size: 18px; font-weight: 600">{{ item.integralPrice }}积分</div>
                  </div>
                  <div style="font-weight: 600" v-if="!item.isChange || item.isChange == '0'" class="typeTime">
                    <!-- 兑换时间：{{ item.startTime + '~' + item.endTime }} -->
                    POS端未在兑换时间内
                  </div>
                  <div v-else :class="['yep', getTouch == index ? 'middle-items-yep' : '']"></div>
                </div>
              </div>
            </div>
            <div v-else style="color: #eae8e8; display: flex; justify-content: center; align-items: center">
              <h1>暂无数据</h1>
            </div>
            <div class="left-bottom">
              <div class="page-box">
                <div class="page-size">
                  <el-select
                    v-model="pageSize"
                    size="mini"
                    style="width: 100px; height: 26px"
                    placeholder="请选择"
                    @change="pageSizeChange(pageSize)">
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                  </el-select>
                </div>
                <div class="page">
                  <div class="button" @click="backPage"><</div>
                  <div class="num">{{ page }}</div>
                  <div class="button" @click="addPage">></div>
                </div>
              </div>
            </div>
          </div>
          <div class="integral-right">
            <div
              v-if="!vipData"
              style="display: flex; height: 60px; align-items: center; background: #fef3e4"
              v-loading="vipLoading">
              <div class="vip-default">
                <i class="iconfont icon-saoma"></i>
                <input
                  type="text"
                  ref="memberInput"
                  v-model="memberValue"
                  placeholder="请用扫码枪扫描会员码"
                  id="inputText"
                  @input="handleInput" />
                <span
                  style="
                    position: absolute;
                    right: 7px;
                    top: 5.5px;
                    border-radius: 6px;
                    display: inline-block;
                    font-size: 12px;
                    height: 26px;
                    width: 50px;
                    background: #ff9407;
                    color: #fff;
                    text-align: center;
                    line-height: 26px;
                    cursor: pointer;
                  "
                  v-if="memberValue.length >= 4"
                  @click="queryBuyMember"
                  >确定</span
                >
              </div>
              <div class="vip-tip-text">可在微信公众号打开[会<br />员中心]出示会员码</div>
            </div>
            <div class="vip-box" v-else v-loading="vipLoading">
              <div class="item-vip">
                <div class="vip-item-box" style="width: 67px; text-align: center" :title="vipData.nickName">
                  {{ vipData.nickName }}
                </div>
                <span>会员名称</span>
              </div>
              <div class="line-box"></div>
              <div class="item-vip">
                <div class="vip-item-box" style="width: 110px; text-align: center" :title="vipData.phone">
                  {{ vipData.phone }}
                </div>
                <span>手机号</span>
              </div>
              <div class="line-box"></div>
              <div class="item-vip">
                <div class="vip-item-box" style="width: 64px; text-align: center" :title="vipData.accountBalance">
                  {{ vipData.accountBalance }}
                </div>
                <span>余额</span>
              </div>
              <div class="line-box"></div>
              <div class="item-vip">
                <div class="vip-item-box" style="width: 54px; text-align: center" :title="vipData.integralBalance">
                  {{ vipData.integralBalance }}
                </div>
                <span>积分</span>
              </div>
              <div class="line-box"></div>
              <div style="width: 56px">
                <el-button
                  size="mini"
                  @click="
                    vipData = null
                    pointsIntegral = $amount.subtract(0, Number(allItemIntegral))
                    integralBalanceNum = '0'
                    memberValue = ''
                  "
                  >重选</el-button
                >
              </div>
            </div>
            <div class="image-box image-not" v-loading="rightLoading">
              <el-image
                class="img"
                v-if="dataObj && dataObj.imageList && dataObj.imageList.length"
                :src="dataObj.imageList[0]"
                :preview-src-list="dataObj.imageList"></el-image>
              <div class="img-bottom-box" v-if="dataObj && skuList">
                <div
                  style="width: 200px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis"
                  :title="dataObj.title">
                  货品名称：{{ dataObj.title }}
                </div>
                <div>
                  <span style="font-size: 20px; color: #f3900b; margin-right: 6px">{{ dataObjIntegral }}</span
                  >积分
                </div>
              </div>
            </div>
            <div class="detail-box" v-if="dataObj && skuList" v-loading="rightLoading">
              <div class="size" style="margin-top: 0">
                <span style="position: absolute; top: 10px; left: 0">尺码：</span>
                <div style="height: 100%; margin-left: -10px; display: inline-block; margin-left: 50px">
                  <div
                    v-for="(val, index) in dataObj.attrList[0].valueList"
                    :key="index"
                    :class="['size-box', sizeVal == val.value ? 'size-box-get' : '']"
                    :title="val.value"
                    @click="sizeClick(dataObj.attrList[0].code, val)">
                    {{ val.value }}
                  </div>
                </div>
              </div>
              <div class="size">
                <span style="position: absolute; top: 10px; left: 0">颜色：</span>
                <div style="height: 100%; margin-left: -10px; display: inline-block; margin-left: 50px">
                  <div
                    v-for="(val, index) in dataObj.attrList[1].valueList"
                    :key="index"
                    :class="['size-box', colorVal == val.value ? 'size-box-get' : '']"
                    :title="val.value"
                    @click="colorClick(dataObj.attrList[1].code, val)">
                    {{ val.value }}
                  </div>
                </div>
              </div>
              <div style="display: flex; justify-content: space-between; margin-top: 10px">
                <div>
                  兑换数量：
                  <div class="number-box">
                    <div class="minus-num" @click="numberMinus">
                      <span class="el-icon-minus"></span>
                    </div>
                    <input class="choose-num" v-model="number" @focus="inputFocus" @blur="inputBlur" />
                    <div class="plus-num" @click="numberPlus">
                      <span class="el-icon-plus"></span>
                    </div>
                  </div>
                </div>
                <div>库存：{{ stock }}</div>
              </div>
            </div>
            <div class="detail-box" v-else>
              <div
                style="
                  height: 100%;
                  width: 100%;
                  color: #eae8e8;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                ">
                <h2>请先选择积分礼品</h2>
              </div>
            </div>
            <div class="submit-box">
              <div class="content">
                <div>
                  合计：<span>{{ numFunction(Number(allItemIntegral)) || '0.00' }}</span
                  >积分
                </div>
                <div>
                  兑换后剩余：<span>{{ numFunction(Number(pointsIntegral)) || '0.00' }}</span
                  >积分
                </div>
              </div>
              <div class="submit" @click="sureSend">确认兑换</div>
            </div>
          </div>
        </div>
      </div>
    </dialogModule>
    <el-dialog title="提示" :visible.sync="effectStateDialog" width="18%" :before-close="effectStateClose">
      <span style="font-size: 16px"
        >抱歉，该会员资格暂时受到限制，无法使用，我们建议您联系IT了解具体情况并解决问题。</span
      >
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="effectStateDialog = false">确 定</el-button>
      </span>
    </el-dialog>
  </section>
</template>

<script>
import { printQueryVipInfo } from '@/libs/http/modules/vip'
import { crmIntegralList, crmIntegralListGood } from '@/libs/http/modules/vip'
import { queryStoreInfoList } from '@/libs/http/modules/posOrder'
import dialogModule from '@/components/dialogModule.vue'
export default {
  components: {
    dialogModule
  },
  props: ['curVip'],
  data() {
    return {
      effectStateDialog: false,
      timearr: [0, 0],
      vipLoading: false,
      state: '1',
      rightLoading: false,
      leftLoading: false,
      pointsIntegral: '0',
      allItemIntegral: '0', //总积分
      dataObjIntegral: '0',
      curRow: null,
      chooseVisiable: true,
      dataObj: null,
      colorCode: '',
      colorVal: '',
      sizeCode: '',
      sizeVal: '',
      price: '',
      barCode: '',
      title: '',
      stock: '0',
      skuList: [],
      filterList: [],
      defNumber: '',
      chooseSizeList: [],
      chooseColorList: [],
      chooseItem: null,
      loading: false,

      page: '1',
      pageSize: '100',
      options: [
        {
          value: '100',
          label: '100 条/页'
        },
        {
          value: '200',
          label: '200 条/页'
        },
        {
          value: '300',
          label: '300 条/页'
        },
        {
          value: '400',
          label: '400 条/页'
        },
        {
          value: '500',
          label: '500 条/页'
        }
      ],
      goodsName: '',
      number: '1',
      IntegralDialog: false,
      getTouch: '-1',
      itemsList: [],
      memberValue: '',
      integralBalanceNum: '0',
      itemData: null,
      itemIndex: '0',
      vipData: null
    }
  },
  created() {
    if (this.curVip) {
      this.vipData = this.curVip
      this.pointsIntegral = this.$amount.subtract(Number(this.vipData.integralBalance), Number(this.allItemIntegral))
      this.integralBalanceNum = this.vipData.integralBalance
    }
    this.IntegralDialog = true
    this.itemsList.forEach(item => {
      item.isType = false
    })
    this.getDataList()
  },
  methods: {
    handleInput(event) {
      this.memberValue = event.target.value
    },
    handleInput2(event) {
      this.goodsName = event.target.value
    },
    numFunction(num) {
      if (num) {
        Math.ceil(num * 100) / 100
        num = num.toFixed(2)
        return num
      }
    },
    effectStateClose() {
      this.effectStateDialog = false
    },
    sureSend() {
      if (!this.vipData) {
        return this.$message({
          type: 'error',
          message: '请先扫描会员码',
          duration: 2000,
          showClose: true
        })
      }
      if (this.vipData.effectState == '1') {
        this.effectStateDialog = true
        return
      }
      if (!this.itemData) {
        return this.$message({
          type: 'error',
          message: '请先选择礼品',
          duration: 2000,
          showClose: true
        })
      }
      if (this.number > this.itemData.singleChangeNum && this.itemData.singleChangeNum != '0') {
        return this.$message({
          type: 'error',
          message: `当前礼品单次限兑换${this.itemData.singleChangeNum}个`,
          duration: 2000,
          showClose: true
        })
      }
      const reqData = {
        barcode: this.barCode,
        exchangeNumber: this.number,
        crmBaseId: Number(this.vipData.id),
        exchangeGoodId: Number(this.itemData.id)
      }
      this.rightLoading = true
      this.leftLoading = true
      this.$confirm('确认兑换礼品？', '提示')
        .then(() => {
          crmIntegralListGood(reqData)
            .then(data => {
              if (data) {
                this.$message.success('兑换成功')
                this.getDataList('1')
                this.queryBuyMember()
                this.getItems(this.itemData, this.itemIndex, true)
                this.rightLoading = false
                this.leftLoading = false
                this.$emit('refresh')
              }
            })
            .catch(err => {
              this.rightLoading = false
              this.leftLoading = false
              this.$message({ type: 'error', message: err, duration: 2000, showClose: true })
            })
        })
        .catch(() => {
          this.rightLoading = false
          this.leftLoading = false
        })
    },
    inputBlur() {
      let numType = /^\d+$/.test(this.number)
      if (Number(this.number) <= 0 || !numType) {
        this.number = this.defNumber
        return this.$message({
          type: 'error',
          message: '请填写大于或等于1的整数',
          duration: 2000,
          showClose: true
        })
      }
      this.allItemIntegral = this.$amount.mul(Number(this.dataObjIntegral), Number(this.number))
      this.pointsIntegral = this.$amount.subtract(Number(this.integralBalanceNum), Number(this.allItemIntegral))
    },
    inputFocus() {
      this.defNumber = this.number
    },
    numberMinus() {
      if (Number(this.number) <= 1) {
        return this.$message({
          type: 'error',
          message: '最小数量为1，不能再减了',
          duration: 2000,
          showClose: true
        })
      }
      this.number--
      this.allItemIntegral = this.$amount.mul(Number(this.dataObjIntegral), Number(this.number))
      this.pointsIntegral = this.$amount.subtract(Number(this.integralBalanceNum), Number(this.allItemIntegral))
    },
    numberPlus() {
      this.number++
      this.allItemIntegral = this.$amount.mul(Number(this.dataObjIntegral), Number(this.number))
      this.pointsIntegral = this.$amount.subtract(Number(this.integralBalanceNum), Number(this.allItemIntegral))
    },
    getDataList(page) {
      this.leftLoading = true
      const reqData = {
        page: page ? page : this.page,
        pageSize: this.pageSize,
        state: this.state,
        isPos: 'Y'
      }
      if (this.goodsName) {
        reqData.goodsName = this.goodsName
      }
      crmIntegralList(reqData)
        .then(data => {
          this.itemsList = data.items
          this.vipListA = data.items || []
          this.totalVipB = Number(data.totalItem) || 0
          this.leftLoading = false
        })
        .catch(err => {
          this.leftLoading = false
          this.searchLoading = false
          this.$message({ type: 'error', message: err, duration: 2000, showClose: true })
        })
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize
      this.getDataList()
    },
    backPage() {
      this.leftLoading = true
      if (this.page > 1) {
        this.page--
        this.getDataList()
      } else {
        this.leftLoading = false
      }
    },
    addPage() {
      this.page++
      this.getDataList()
    },
    closeDialog() {
      this.IntegralDialog = false
      this.$emit('close')
    },
    getItems(item, index, type) {
      if (!item.isChange || item.isChange == '0') {
        return
      }

      // 获取当前日期并将时间部分置为0
      const currentDate = new Date()
      currentDate.setHours(0, 0, 0, 0)

      // 将输入的日期字符串转换为 Date 对象
      const inputDate = new Date(item.startTime)
      const inputDate2 = new Date(item.endTime)

      // 将礼品的兑换日期的时间部分置为0
      inputDate.setHours(0, 0, 0, 0)
      inputDate2.setHours(0, 0, 0, 0)

      // 比较两个日期
      if (currentDate < inputDate || currentDate > inputDate2) {
        return this.$message({
          type: 'error',
          message: this.$createElement(
            'div',
            {
              style: {
                width: '420px', // 根据需要调整宽度
                color: '#fff'
              }
            },
            `当前选中礼品兑换日期为 ${item.startTime} ~ ${item.endTime}`
          ),
          duration: 2000,
          showClose: true
        })
      }

      this.itemData = item
      this.itemIndex = index
      this.itemsList.forEach(item => {
        //这里条件等接口做后续调整
        if (item == this.itemsList[index]) {
          item.isType = !item.isType
        } else {
          item.isType = false
        }
      })
      if (type) {
        this.itemsList[index].isType = true
      }
      if (this.itemsList[index].isType) {
        this.getTouch = index
        this.rightLoading = true
        queryStoreInfoList({
          goodsNo: item.goodsNo
        })
          .then(data => {
            this.number = 1
            this.chooseItem = data.spuInfoBean.storeSkuInfoBeanList
            this.dataObj = data.spuInfoBean
            this.skuList = data.spuInfoBean.storeSkuInfoBeanList
            this.dataObjIntegral = item.integralPrice
            this.allItemIntegral = this.$amount.mul(Number(this.dataObjIntegral), Number(this.number))
            this.pointsIntegral = this.$amount.subtract(Number(this.integralBalanceNum), Number(this.allItemIntegral))
            this.sizeVal = this.dataObj.attrList[0].valueList[0].value
            this.colorVal = this.dataObj.attrList[1].valueList[0].value
            this.chooseItem.forEach(item => {
              if (item.attrList[0].value == this.colorVal && item.attrList[1].value == this.sizeVal) {
                this.stock = item.stock
                this.barCode = item.barCode
              }
            })
            this.rightLoading = false
          })
          .catch(err => {
            this.rightLoading = false
            this.$message({ type: 'error', message: err, duration: 2000, showClose: true })
          })
      } else {
        this.dataObj = null
        this.skuList = null
        this.getTouch = '-1'
        this.sizeVal = ''
        this.colorVal = ''
        if (this.vipData) {
          this.pointsIntegral = this.vipData.integralBalance
        } else {
          this.pointsIntegral = 0
        }
        this.allItemIntegral = 0
      }
    },
    queryBuyMember() {
      this.vipLoading = true
      let printInfo = this.memberValue.trim()
      if (!printInfo && !this.vipData) {
        return (this.vipLoading = false)
      }
      printQueryVipInfo({
        printInfo: printInfo ? printInfo : this.vipData ? this.vipData.phone : ''
      })
        .then(res => {
          if (res) {
            this.vipData = res[0]
            this.pointsIntegral = this.$amount.subtract(
              Number(this.vipData.integralBalance),
              Number(this.allItemIntegral)
            )
            this.integralBalanceNum = this.vipData.integralBalance
            this.vipLoading = false
          }
        })
        .catch(error => {
          this.vipLoading = false
          this.memberValue = ''
          this.$refs.memberInput.focus()
          this.$message({ type: 'error', message: error, duration: 2000, showClose: true })
        })
    },
    sizeClick(code, obj) {
      this.number = 1
      this.allItemIntegral = this.$amount.mul(Number(this.dataObjIntegral), Number(this.number))
      this.pointsIntegral = this.$amount.subtract(Number(this.integralBalanceNum), Number(this.allItemIntegral))

      this.sizeCode = code
      this.sizeVal = obj.value

      this.chooseItem.forEach(item => {
        if (item.attrList[0].value == this.colorVal && item.attrList[1].value == this.sizeVal) {
          this.stock = item.stock
          this.barCode = item.barCode
        }
      })
    },
    colorClick(code, obj) {
      this.number = 1
      this.allItemIntegral = this.$amount.mul(Number(this.dataObjIntegral), Number(this.number))
      this.pointsIntegral = this.$amount.subtract(Number(this.integralBalanceNum), Number(this.allItemIntegral))

      this.colorCode = code
      this.colorVal = obj.value

      this.chooseItem.forEach(item => {
        if (item.attrList[0].value == this.colorVal && item.attrList[1].value == this.sizeVal) {
          this.stock = item.stock
          this.barCode = item.barCode
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.Integral-box {
  width: 100%;
  height: 800px;
  display: flex;
  justify-content: space-between;
  .integral-right {
    width: 28%;
    height: 100%;
    background: #fff;
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    .vip-box {
      background: #fef3e4;
      display: flex;
      height: 60px;
      justify-content: space-around;
      align-items: center;
      .vip-item-box {
        cursor: pointer;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 18px;
        font-weight: 600;
        color: #000;
      }
      .item-vip {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #a39c9c;
      }
      .line-box {
        height: 30px;
        width: 1px;
        background: #cfc9c9;
      }
      .el-button {
        border: 1px solid #ff9f52;
        background: #fef3e4;
        color: #ff9f52;
      }
    }
    .vip-default {
      height: 40px;
      width: 66%;
      line-height: 40px;
      border: 1px solid orange;
      border-radius: 2px;
      padding-left: 10px;
      font-size: 16px;
      background: #fff;
      margin-left: 10px;
      position: relative;
      > i {
        font-size: 20px;
        font-weight: bold;
        color: orange;
      }
      > input {
        position: absolute;
        top: 0;
        left: 10%;
        width: 88%;
        line-height: 36px;
        height: 36px;
        outline: none;
        margin-left: 10px;
        font-size: 16px;
        float: right;
        border: none;
        margin-top: 2px;
        background: none;
        color: orange;
        padding-right: 10px;
      }
      input::-webkit-input-placeholder {
        color: orange;
      }
      input:focus {
        color: #c56209;
      }
      input:focus::-webkit-input-placeholder {
        color: #c56209;
      }
    }
    .vip-tip-text {
      width: 38%;
      margin-left: 10px;
      color: orange;
      font-size: 15px;
    }
    .image-box {
      width: 100%;
      height: 48%;
      background: #e4e3e1;
      position: relative;
      .img {
        width: 100%;
        height: 100%;
      }
      .img-bottom-box {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 50px;
        background: #0000008c;
        color: white;
        display: flex;
        justify-content: space-between;
        padding: 0 10px;
        line-height: 50px;
        font-size: 16px;
        font-weight: 600;
      }
    }
    .image-not {
      background: url(../../../assets/images/wutu.jpg) no-repeat;
      background-size: 150% 120%;
      background-position: center;
    }
    .detail-box {
      padding: 10px;
      width: 100%;
      height: 36%;
      display: flex;
      flex-direction: column;
      font-size: 18px;
      color: #000;
      overflow-y: scroll;
      .size {
        position: relative;
        margin-bottom: 20px;
        .size-box {
          user-select: none;
          display: inline-block;
          width: 69px;
          height: 28px;
          border-radius: 3px;
          text-align: center;
          line-height: 28px;
          margin-left: 10px;
          margin-top: 10px;
          border: 1px solid #eee;
          cursor: pointer;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .size-box-get {
          border: 1px solid #067cf2;
          background: #067cf2;
          color: #fff;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .number-box {
        display: inline-block;
        div {
          display: inline-block;
        }
        .minus-num {
          width: 40px;
          line-height: 30px;
          text-align: center;
          font-size: 18px;
          border: 1px solid #eee;
          color: #333;
          cursor: pointer;
        }
        .choose-num {
          width: 80px;
          line-height: 30px;
          text-align: center;
          font-size: 18px;
          border: 1px solid #eee;
          border-left: none;
          color: #333;
          outline: none;
        }
        .plus-num {
          background: #067cf2;
          color: #fff;
          width: 40px;
          line-height: 30px;
          text-align: center;
          font-size: 18px;
          cursor: pointer;
          border: 1px solid #067cf2;
        }
      }
    }
    .submit-box {
      width: 100%;
      height: 68px;
      display: flex;

      padding: 10px;
      .content {
        width: 50%;
        background: #eae8e8;
        border-radius: 3px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 10px;
        color: #000;
        span {
          color: #ff9407;
          font-weight: 600;
          font-size: 16px;
          margin-right: 5px;
        }
      }
      .submit {
        color: #fff;
        background: #077cf2;
        width: 50%;
        border-radius: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        cursor: pointer;
      }
    }
  }
  .integral-left {
    padding: 10px 20px;
    padding-right: 0;
    flex: 1;
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .left-top {
      display: flex;
      justify-content: space-between;
      .items-box {
        margin-right: 10px;
      }
      .button-box {
        cursor: pointer;
        background: #077cf2;
        width: 100px;
        height: 28px;
        border-radius: 3px;
        color: #fff;
        line-height: 28px;
        text-align: center;
      }
      .button-box:hover {
        box-shadow: 1px 1px 1px #81bcf8;
      }
      input {
        border-radius: 3px;
      }
      // input::-webkit-input-placeholder {
      //   color: #fff;
      // }
      // input:focus::-webkit-input-placeholder {
      //   color: #fff;
      // }
      input:focus {
        outline: none;
      }
    }
    .left-middle {
      width: 100%;
      height: 640px;
      overflow-y: scroll;
      padding: 5px;
      .middle-box-items {
        width: 48%;
        height: 120px;
        border-radius: 3px;
        margin-right: 2.002%;
        margin-bottom: 2.002%;
        box-shadow: 0px 0px 5px #d1d1d1;
        display: inline-block;
        cursor: pointer;
        .items-image {
          width: 120px;
          height: 120px;
          background: #e4e3e1;
          display: inline-block;
          .img {
            width: 100%;
            height: 100%;
          }
        }
        .image-not {
          background: url(../../../assets/images/wutu.jpg) no-repeat;
          background-size: 150% 120%;
          background-position: center;
        }
        .items-right {
          position: relative;
          display: inline-block;
          padding: 10px;
          width: 50%;
          height: 100%;
          .detail {
            width: 70%;
            position: absolute;
            top: 0px;
            padding: 10px;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
          }
          .yep {
            position: absolute;
            top: 36%;
            right: -43%;
            display: inline-block;
            width: 30px;
            height: 30px;
            background: url('../../../assets/images/isNot.png') 100% 100%;
            text-align: center;
            line-height: 30px;
            font-size: 24px;
            border-radius: 5px;
            color: #fff;
          }
          .typeTime {
            position: absolute;
            top: 73%;
            right: -43%;
          }
          .middle-items-yep {
            background: #43d168;
            background: url('../../../assets/images/isYep.png') 100% 100%;
          }
        }
      }
      .middle-items {
        cursor: pointer;
        box-shadow: #077cf2 0px 0px 5px;
      }
    }
    .left-bottom {
      width: 100%;
      padding-right: 20px;
      .page-box {
        height: 50px;
        background: #f2f2f2;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 15px;
        padding-right: 15px;
        .page {
          display: flex;
          .button {
            width: 26px;
            height: 26px;
            background: white;
            text-align: center;
            line-height: 26px;
            cursor: pointer;
            user-select: none;
          }
          .num {
            min-width: 26px;
            height: 26px;
            background: #077cf2;
            text-align: center;
            line-height: 26px;
            color: white;
          }
        }
      }
    }
  }
}
</style>
