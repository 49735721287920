<template>
  <section class="container">
    <stockTopMenu windowId="deptCollectMoney" module="money"></stockTopMenu>
    <section
      class="content row"
      v-loading="loading"
      element-loading-text="请稍后，全力加载中..."
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.2)">
      <main class="content-main">
        <noDataState
          class="main-info"
          v-if="!tableList.length && !loading"
          marginTop="280"
          textDesc="当前页面暂无货品数据"></noDataState>
        <section class="main-info" v-else>
          <grid-manager :option="gridOption"></grid-manager>
        </section>

        <section class="row align-center space-between bottom-tools">
          <div class="row align-center">
            <div class="row align-center active-list" v-if="activeList.length">
              <div
                class="row align-center active-item"
                v-for="item in activeList"
                :key="item.activityId"
                :style="item.selectAct ? 'border-color:#067CF2;background:#fff;' : ''"
                @click.stop="chooseActive(item)">
                <div class="active-type row align-center space-center">
                  {{ item.typeDesc }}
                </div>
                <div class="column space-center active-title">
                  <div :title="item.activityName">{{ item.activityName }}</div>
                  <p>
                    <span v-if="item.giftGoodInfos && item.giftGoodInfos.length" @click.stop="giftAdd('gift', item)">
                      {{ item.checkGift && item.checkGift.length ? '查看赠品' : '选择赠品' }}
                      <i class="el-icon-arrow-right"></i>
                    </span>
                    <span v-if="item.addGoodInfos && item.addGoodInfos.length" @click.stop="giftAdd('add', item)">
                      {{ item.checkGive && item.checkGive.length ? '查看加购' : '选择加购' }}
                      <i class="el-icon-arrow-right"></i>
                    </span>
                  </p>
                </div>
                <div
                  class="row align-center space-center active-flag"
                  :style="item.selectAct ? 'background:#43D268;' : ''">
                  <i class="iconfont icon-gou" style="color: #fff"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="action-btn">
            <div v-if="tableList.length" class="btn btn-thr" @click="clearAll">清空</div>
          </div>
        </section>
      </main>

      <aside class="content-aside">
        <div class="row tab-type">
          <div
            @click="tabType = '1'"
            :style="{ background: tabType === '1' ? '#067CF2' : '', color: tabType === '1' ? '#fff' : '#067CF2' }">
            售
          </div>
          <div
            @click="tabType = '6'"
            :style="{ background: tabType === '6' ? '#067CF2' : '', color: tabType === '6' ? '#fff' : '#067CF2' }">
            换
          </div>
          <div
            @click="tabType = '3'"
            :style="{ background: tabType === '3' ? '#067CF2' : '', color: tabType === '3' ? '#fff' : '#FF9000' }">
            礼
          </div>
        </div>

        <section class="aside-scan row align-center" :style="{ border: isFocus ? '2px solid #F8475F' : '' }">
          <i class="iconfont icon-saomafukuan-" :style="{ color: isFocus ? '#C56209' : '#067CF2' }"></i>
          <span :style="{ background: isFocus ? '#C56209' : '#067CF2' }"></span>
          <input
            type="text"
            ref="scanAdd"
            v-model="searchWord"
            v-focusInput="1"
            placeholder="扫码添加(按alt + 1)快速聚焦"
            @focus.stop="isFocus = true"
            @blur="isFocus = false"
            @keyup.enter="
              alterPriceInfo = {}
              goodInfos.forEach((item, index) => {
                if (item.hasAlterPriceReview == 'Y') {
                  if (item.retailPrice) {
                    $set(goodInfos[index], 'realPrice', item.retailPrice)
                    $set(goodInfos[index], 'hasAlterPriceReview', 'N')
                  }
                }
              })
              operateItem('', '1', '', 'Y', '', 'ned')
            " />
          <div class="scan-tip">
            扫码时请将输入法切换成英文
            <p class="el-icon-caret-bottom"></p>
          </div>
        </section>

        <section class="aside-goodsno row align-center">
          <div class="row align-center goods-input">
            <input
              placeholder="手动输货号/简码"
              type="text"
              v-focusInput
              v-model="searchSpu"
              @keyup.enter="
                () => {
                  isUpdatePrice = false
                  goodInfos.forEach((item, index) => {
                    if (item.hasAlterPriceReview == 'Y') {
                      if (item.retailPrice) {
                        $set(goodInfos[index], 'realPrice', item.retailPrice)
                        $set(goodInfos[index], 'hasAlterPriceReview', 'N')
                      }
                    }
                  })
                  page = 1
                  queryGoodsNoList()
                }
              " />
            <span
              @click.prevent="
                () => {
                  isUpdatePrice = false
                  goodInfos.forEach((item, index) => {
                    if (item.hasAlterPriceReview == 'Y') {
                      if (item.retailPrice) {
                        $set(goodInfos[index], 'realPrice', item.retailPrice)
                        $set(goodInfos[index], 'hasAlterPriceReview', 'N')
                      }
                    }
                  })
                  page = 1
                  queryGoodsNoList()
                }
              "
              ><i class="el-icon-loading" v-show="spuLoading"></i>搜索</span
            >
            <div v-if="spulistVisable" class="spu-card">
              <div class="spu-card-list">
                <div class="row align-center spu-card-title">
                  <div>货号</div>
                  <div>货品名称</div>
                </div>
                <div
                  v-for="item in spuList"
                  :key="item.sup"
                  class="spu-list row align-center"
                  @click.stop="curSpu(item)">
                  <div>{{ item.goodsNo }}</div>
                  <div>{{ item.goodsName }}</div>
                </div>
              </div>
              <section class="row align-center space-center spu-page">
                <el-pagination
                  small
                  layout="prev, pager, next"
                  :pager-count="5"
                  :page-size="20"
                  :total="totalList"
                  :current-page="page - 0"
                  @current-change="getCurPage">
                </el-pagination>
                <p
                  @click="spulistVisable = false"
                  style="color: #f8475f; font-size: 14px; cursor: pointer; border-bottom: 1px solid #f8475f">
                  关闭
                </p>
              </section>
            </div>
          </div>
          <div
            class="goods-attr row align-center space-center"
            @click.stop="chooseColor"
            :style="{ background: colorModul ? '#FFE190' : '' }">
            {{ curColor || '颜色' }}&nbsp;
            <i class="el-icon-arrow-down"></i>
            <div class="list-card" v-if="itemColor && colorModul">
              <div v-for="(item, c) in chooseColorList" :key="c" @click.stop="selectColor(item.value)">
                {{ item.value }}
              </div>
            </div>
          </div>
          <div
            class="goods-attr row align-center space-center"
            @click.stop="chooseSize"
            :style="{ background: sizeModule ? '#FFE190' : '' }">
            {{ curSize || '尺码' }}&nbsp;
            <i class="el-icon-arrow-down"></i>
            <div class="list-card" v-if="itemSize && sizeModule">
              <div v-for="(item, s) in chooseSizeList" :key="s" @click.stop="selectSize(item.value)">
                {{ item.value }}
              </div>
            </div>
          </div>
        </section>

        <section class="row align-center text-center flex-wrap stock-sales">
          <div @click="salesQueryDialog = true">
            <p>销售查询</p>
            <span>alt + s</span>
          </div>
          <div @click="singleModule = true">
            <p>货品查询</p>
            <span>alt + c</span>
          </div>
          <div @click="chooseBadGoods">
            <p>次品销售</p>
            <span>alt + q</span>
          </div>
          <div @click="chooseVip" class="none-margin-right">
            <p>会员功能</p>
            <span>alt + x</span>
          </div>
          <div @click="hangAndGet">
            <p>挂单取单</p>
            <span>alt + f</span>
          </div>
          <div @click="openNewWindow('4')">
            <p>客订记录</p>
            <span>alt + v</span>
          </div>
          <div @click="openNewWindow('5')">
            <p>调价通知</p>
            <span>alt + b</span>
          </div>
          <div @click="openNewWindow('6')" class="none-margin-right">
            <p>时段目标</p>
            <span>alt + r</span>
          </div>
          <div @click="stockAccountCardModule = true">
            <p>库存账卡</p>
            <span>alt + g</span>
          </div>
        </section>

        <section class="ned-class" v-if="nedTypeList && nedTypeList.length">
          <div class="top"><img src="@/assets/images/ned.png" alt="" />正在进行换货</div>
          <div class="center">
            跨店来退只允许换货，“换”的货品按本店最新的货品金额计算。打一件“换”价值100，下一件打“售”如果价值80，则“售”视为价值100；如果“售”的价值大于等于100则不受影响。
          </div>
          <div class="bottom">
            <span style="color: #888">换货总金额：</span
            ><span style="color: #f84d65; font-size: 20px; margin-right: 30px; font-weight: 500"
              >￥{{ reduceResult.changeTotalPrice }}</span
            ><span style="color: #888">总件数：</span
            ><span style="color: #f84d65; font-size: 20px; font-weight: 500">{{ reduceResult.changeNum }}</span>
          </div>
        </section>

        <section class="sales-ording row align-center space-between">
          <div class="row align-center item-card">
            <div
              class="item-value row align-center space-between"
              @click.stop="
                salesVisiable = !salesVisiable
                ordingVisiable = false
                shopListVisable = false
              ">
              <span>{{ curSalesTitle }}</span>
              <i class="el-icon-caret-bottom" :style="salesVisiable ? 'transform: rotate(180deg);' : ''"></i>
            </div>
            <div class="item-options" v-if="salesVisiable">
              <div v-for="item in salesList" :key="item.id" @click.stop="saleshande(item)">
                {{ item.title }}
              </div>
            </div>
          </div>
          <div class="row align-center item-card" v-if="salesTypeId === '1'">
            <div class="item-value row align-center space-between" @click="showGuideList">
              <input
                ref="focusGuider"
                type="text"
                v-model="shopGuide"
                placeholder="请选择营业员"
                v-focusInput="1"
                :disabled="openState === 'Y'"
                @focus="shopListVisable = true"
                @input="queryGuide"
                @blur="guideBlur" />
              <i class="el-icon-caret-bottom" :style="shopListVisable ? 'transform: rotate(180deg);' : ''"></i>
            </div>
            <div class="item-options user-option" v-if="shopListVisable">
              <div
                v-for="item in shopList"
                :key="item.id"
                class="row align-center search-user"
                @click.stop="getcurUser(item)">
                <div>
                  <div class="user-avat">
                    <el-image v-if="item.avatar" :src="item.avatar" class="image"></el-image>
                    <div v-else>{{ item.realName.substring(0, 1) }}</div>
                  </div>
                </div>
                <div class="user-data">
                  <p>{{ item.phone }}</p>
                  <p>{{ item.realName }}</p>
                </div>
              </div>
            </div>
          </div>
          <div
            class="row align-center item-card space-center show-allshop"
            v-if="salesTypeId === '2'"
            @click="guideModule = true">
            选择班组人员
          </div>
        </section>

        <div class="ganger-user" v-if="salesTypeId === '2' && guiderList.length">
          <span>已选择班组人员：</span>
          <span v-for="(user, index) in guiderList" :key="user.id">
            {{ user.realName }}
            <span v-if="index < guiderList.length - 1">、</span>
          </span>
        </div>
        <div class="row align-center space-between team-card">
          <div class="row align-center">
            <el-switch
              :value="openState === 'Y'"
              active-color="#13ce66"
              inactive-color="#eee"
              @change="changeState"></el-switch>
            <span style="margin-left: 8px" v-if="openState === 'N'">公单未开启</span>
            <span style="margin-left: 8px" v-if="openState === 'Y'">公单已开启</span>
          </div>
          <div v-if="openState === 'Y'" class="show-user" @click="settingPublic = true">
            查看公单参与人员({{ joinUser }}人)
          </div>
        </div>
        <div class="row align-center text-center vip-card" :class="curVip ? 'space-around' : ''">
          <template v-if="curVip">
            <p class="column">
              <span>{{ curVip.nickName }}</span>
              <span>会员名称</span>
            </p>
            <i></i>
            <p class="column">
              <span>{{ curVip.phone }}</span>
              <span>手机号</span>
            </p>
            <i></i>
            <p class="column">
              <span>{{ curVip.accountBalance || '0.00' }}</span>
              <span>余额</span>
            </p>
            <i></i>
            <p class="column">
              <span>{{ parseInt(curVip.integralBalance) || '0' }}</span>
              <span>积分</span>
            </p>
            <i></i>
            <span @click="curVip = null">重选</span>
          </template>
          <template v-else>
            <div class="vip-default">
              <i class="iconfont icon-saoma"></i>
              <input
                type="text"
                ref="memberInput"
                v-model="memberValue"
                v-focusInput="1"
                placeholder="扫描会员码或输入手机号"
                @blur="queryBuyMember"
                @keyup.enter="$event.target.blur()" />
            </div>
            <div class="vip-tip-text">可在微信公众号打开<br />[会员中心]出示会员码</div>
          </template>
        </div>

        <section class="about-price">
          <div class="row align-center">
            <span style="margin-top: 24px">应收：</span>
            <p>
              <span>￥</span>
              <span style="font-size: 50px">{{ reduceResult.receivablePrice || '0.00' }}</span>
              <a v-if="1 === 2" href="javascript:;" @click="changePriceModule">[整单改价]</a>
            </p>
          </div>
          <div class="row align-center">
            <span>销售件数：</span>
            <p>
              <span></span>
              <span>{{ reduceResult.numTotal || '0' }}</span>
            </p>
          </div>
          <div class="row align-center">
            <span>吊牌总额：</span>
            <p>
              <span style="color: #067cf2">￥</span>
              <span style="color: #067cf2">{{ reduceResult.tagPriceTotal || '0.00' }}</span>
            </p>
          </div>
        </section>

        <section class="aside-end text-center">
          <div class="aside-finaly column space-center" @mousedown="isDown = true" @mouseup="checkPublicState">
            <svg class="icon" aria-hidden="true">
              <use v-if="!isDown" xlink:href="#icon-jiesuan"></use>
              <use v-else xlink:href="#icon-jiesuan-down"></use>
            </svg>
            <div class="jiesuan column align-center space-center">
              <div style="margin-top: 10px; font-size: 28px">买 单</div>
              <p style="font-size: 12px">Alt + z</p>
            </div>
          </div>
        </section>
      </aside>
    </section>

    <ChooseGuige
      v-if="guigeModule"
      @close="closeGuigeModul"
      :curRow="curRow"
      :invoicesTypeId="ordingNature.id"></ChooseGuige>
    <OrdingPrice v-if="allPriceModule" @close="changeAllPrice" :priceAll="reduceResult.receivablePrice"></OrdingPrice>
    <Complimentaries v-if="giveModele" :currentActive="currentActive" @close="clsoePliment"></Complimentaries>
    <TradeInDialog v-if="tradeModule" :currentActive="currentActive" @close="closeTrade"></TradeInDialog>
    <ChangePrice
      v-if="priceModule"
      :alterPriceInfo="alterPriceInfo"
      :isAllOrding="isAllOrding"
      :invoicesTypeId="ordingNature.id"
      :salesTypeId="salesTypeId"
      :vipData="curVip"
      :activityInfo="activityInfo"
      :reduceResult="reduceResult"
      :list="tableList"
      :curGuider="guiderList"
      @close="cancelUpdatePrice"
      @setTablist="getState">
    </ChangePrice>
    <SingleRepertory
      v-if="singleModule"
      :init-value="curRow ? curRow['barCode'] : ''"
      @close="
        () => {
          this.singleModule = false
          this.curRow = null
        }
      "
      @skuData="getskuData">
    </SingleRepertory>
    <stockAccountCardVue v-if="stockAccountCardModule" @close="closeStockAccountCard"> </stockAccountCardVue>
    <PayDialog
      v-if="payTypeDialog"
      :orderNature="{
        isUpdatePrice: isUpdatePrice,
        orderTypeId: ordingNature.id,
        salesTypeId: salesTypeId,
        guiderList: guiderList,
        tableList: tableList
      }"
      :vipData="curVip"
      :orderMoney="reduceResult"
      :activityInfo="activityInfo"
      @close="payTypeDialog = false"
      @sendState="getState">
    </PayDialog>
    <ActiveGoodsList v-if="activeModule" :currentActive="currentActive" @close="activeModule = false"></ActiveGoodsList>
    <VipDialog
      v-if="vipVisiable"
      @close="vipVisiable = false"
      @sendVip="
        e => {
          this.curVip = e
        }
      "
      :curVip="curVip"></VipDialog>
    <SalesUserList
      v-if="guideModule"
      :curGuider="JSON.stringify(guiderList)"
      @close="guideModule = false"
      @sureBtn="getGanger"></SalesUserList>
    <hangRecord v-if="hangGetModule" @close="hangGetModule = false" @getOrding="getHangData"></hangRecord>
    <DoReceipts v-if="DoReceiptsModal" :webViewUrl="webViewUrl" @close="DoReceiptsModal = false"></DoReceipts>
    <SalesInquiry v-if="salesQueryDialog" @close="salesQueryDialog = false"></SalesInquiry>
    <SaleBadGoods
      v-if="badGoodsDialog"
      :tabList="tableList"
      @close="badGoodsDialog = false"
      @skuData="getskuData"
      @void="clearHandel"></SaleBadGoods>
    <PublicUser
      v-if="settingPublic"
      :open-state="openState"
      @close="settingPublic = false"
      @openSuccess="sureOpen"></PublicUser>

    <el-dialog title="提示" :visible.sync="effectStateDialog" width="18%" :before-close="effectStateClose">
      <span style="font-size: 16px"
        >抱歉，该会员资格暂时受到限制，无法使用，我们建议您联系IT了解具体情况并解决问题。</span
      >
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="effectStateDialog = false">确 定</el-button>
      </span>
    </el-dialog>
  </section>
</template>

<script>
import request from '@/libs/http'
import { ipcApiRoute, specialIpcRoute } from '@/api/main'
import { ipc } from '@/utils/ipcRenderer'
import Mousetrap from 'mousetrap'
import 'mousetrap/plugins/global-bind/mousetrap-global-bind'
import stockTopMenu from '@/components/stockTopMenu.vue'
import noDataState from '@/components/noDataState.vue'
import PayDialog from '@/components/PayDialog.vue'
import VipDialog from '@/views/vipModule/components/VipDialog'
import OrdingPrice from './components/OrdingPrice.vue'
import ChooseGuige from './components/ChooseGuige.vue'
import Complimentaries from './components/Complimentaries.vue'
import TradeInDialog from './components/TradeInDialog'
import ChangePrice from './components/ChangePrice.vue'
import OrdingDescript from './components/OrdingDescript.vue'
import SingleRepertory from './components/SingleRepertory.vue'
import ActiveGoodsList from './components/ActiveGoodsList.vue'
import hangRecord from './components/HangRecord.vue'
import SalesUserList from './components/SalesUserList.vue'
import DoReceipts from '@/views/salesRecord/components/DoReceipts.vue'
import SaleBadGoods from './components/SaleBadGoods.vue'
import stockAccountCardVue from './components/stockAccountCard.vue'
import SalesInquiry from './components/SalesInquiry.vue'
import PublicUser from './components/PublicUser'
import {
  queryShoppGuide,
  submitStoreInvoice,
  generatePayInfo,
  queryGoodsNo,
  operateGoodsNo,
  queryInvoiceType,
  queryBrokerage
} from '@/libs/http/modules/posOrder.js'
import { crmVipDetail, printQueryVipInfo } from '@/libs/http/modules/vip'
import { closePublicOrder, openPublicOrderValid, queryPublicOrderEnableState } from '@/libs/http/modules/erp'
export default {
  components: {
    stockTopMenu,
    noDataState,
    PayDialog,
    OrdingPrice,
    ChooseGuige,
    Complimentaries,
    ChangePrice,
    OrdingDescript,
    SingleRepertory,
    ActiveGoodsList,
    VipDialog,
    SalesInquiry,
    hangRecord,
    SalesUserList,
    DoReceipts,
    TradeInDialog,
    SaleBadGoods,
    PublicUser,
    stockAccountCardVue
  },
  data() {
    return {
      effectStateDialog: false,
      nedTypeList: [], //退货列表
      tabType: '1', //售赠退标识
      loading: false, //列表添加货品加载动画
      guigeModule: false, //选择规格弹窗
      payTypeDialog: false, //支付弹窗
      giveModele: false, //赠品弹窗
      tradeModule: false, //换购弹窗
      priceModule: false, //改价审批弹窗
      allPriceModule: false, //整单改价弹窗
      tableList: [], //货品数据列表
      singleModule: false, //单款库存弹窗
      curRow: null, //列表当前行数据
      isEdit: false, //是否编辑状态
      moreActive: false, //是否显示更多促销活动
      isDown: false, //买单按钮按下
      newAllPrice: '', //整单改价修改后整单的价格
      isAllOrding: false, //是否是整单改价
      vipVisiable: false, //会员弹窗
      curVip: null, //当前选中的会员
      isFocus: false, //条码输入框是否聚焦
      searchWord: '', //条码输入框的值
      searchSpu: '', //货号输入框的值
      page: 1, //货号查询到的货品当前页码
      spuList: [], //货号查询到的货品列表
      totalList: 0, //货号查询到的货品总数
      ordingTypeList: [], //订单性质列表
      ordingVisiable: false, //是否显示订单性质下拉列表
      ordingNature: null, //当前选中的订单性质
      salesVisiable: false, //是否显示业绩方式下拉列表
      salesTypeId: '1', //业绩方式主键
      curSalesTitle: '个人业绩', //业绩方式描述
      salesList: [], //业绩方式列表数组
      shopGuide: '', //营业员输入框的值
      shopList: [], //营业员列表
      shopListVisable: false, //是否显示营业员下拉列表
      guideModule: false, //多选营业员弹窗
      guiderList: [], //当前选择的营业员
      hangGetModule: false, //挂单记录弹窗
      DoReceiptsModal: false, //打印小票弹窗
      reduceResult: {}, //汇总数据
      activeList: [], //匹配到的活动列表
      activityInfo: [], //选中的活动列表
      isAllowNegativeStocks: '', //是否允许负库存
      alterPriceInfo: {}, //改价信息
      rightMenu: false, //是否显示右键菜单
      goodInfos: [], //原货品信息
      isUpdatePrice: false, //是否改价
      sell: require('@/assets/images/sell.png'),
      give: require('@/assets/images/give.png'),
      back: require('@/assets/images/return.png'),
      rite: require('@/assets/images/rite.png'),
      ned: require('@/assets/images/ned.png'),
      gridOption: {
        gridManagerName: 'collect-money',
        firstLoading: true,
        isCombSorting: true,
        supportAutoOrder: false,
        supportCheckbox: false,
        supportConfig: true,
        disableAutoLoading: true,
        disableBorder: true,
        disableLine: true,
        columnData: [
          {
            key: 'operationType',
            text: '类型',
            align: 'center',
            width: '80px',
            remind: {
              text: `
                售：售卖正价货品<br/>
                退：退换货<br/>
                赠：满足促销活动的赠品<br/>
                礼：非正常售卖的礼品，如：洗衣液、羽绒被<br/>
              `,
              style: {
                width: '300px',
                'text-align': 'left',
                background: '#000',
                'border-radius': '4px',
                padding: '10px'
              }
            },
            template: () => {
              return `
                <div>
                  <img style="width:40px;height:40px;" v-if="row.operationType === '1'" :src="sell" />
                  <img style="width:40px;height:40px;" v-if="row.operationType === '2'" :src="give" />
                  <img style="width:40px;height:40px;" v-if="row.operationType === '4'" :src="back" />
                  <img style="width:40px;height:40px;" v-if="row.operationType === '3'" :src="rite" />
                  <img style="width:40px;height:40px;" v-if="row.operationType === '6'" :src="ned" />
                </div>
              `
            }
          },
          {
            key: 'goodsInfo',
            text: '货品信息',
            align: 'center',
            width: '290px',
            template: () => {
              return `
                <div class="row align-center" style="text-align:left;">
                  <div style="position:relative;overflow:hidden;width:102px;height:92px;border-radius:4px;">
                    <el-image v-if="row.imageList && row.imageList[0]" style="width:102px;height:92px;border-radius:4px;float:left;" :preview-src-list="row.imageList" :src="row.imageList[0]"></el-image>
                    <div v-else style="width:102px;height:92px;border-radius:4px;text-align:center;line-height:92px;color:#999;background:#f5f5f5;">暂无图片</div>
                    <div v-if="row.activityId || row.defectiveApplyId || row.isExchange === 'Y'"
                      style="
                      width:70px;height:22px;line-height:22px;position:absolute;top:4px;right:-19px;transform: rotate(45deg);text-align:center;
                      background: linear-gradient(-90deg, #EC4620, #F08702, #F7D400);color:#fff;font-size:12px;">
                      {{row.defectiveApplyId ? '次品' : row.isExchange === 'Y' ? '加购' : '促销'}}
                    </div>
                  </div>
                  <div class="column space-around" style="margin-left:10px;flex:1;width:170px;">
                    <div style="font-size:18px;color:#333;margin-top:-4px;">{{row.goodsName}}</div>
                    <div style="margin:4px 0;color:#999;">货号：{{row.goodsNo}}</div>
                    <div style="color:#999;">条码：{{row.barCode}}</div>
                  </div>
                </div>
              `
            }
          },
          {
            key: 'attr',
            text: '颜色 / 尺码',
            align: 'center',
            width: '190px',
            template: () => {
              return `
                <div v-if="row.attr" class="row space-center align-center" style="width:160px;border:1px solid #DEEEF9;background:#fff;color:#333;line-height:26px;border-radius:2px;cursor:pointer;margin:0 auto" @click="guigeHandel(row, index)">
                  <p v-for="(item, index) in row.attr.slice(0, 2)" :key="item.code" style="margin-right:10px;">{{item.value}}
                   <span v-if="index == 0" style="margin:0 3px;">,</span>
                  </p>
                  <span class="el-icon-arrow-down" style="color:#067CF2;font-weight:bold;"></span>
                </div>
                <div style="color:#999;margin-top:10px;">可用库存：{{row.stock}}</div>
              `
            }
          },
          {
            key: 'number',
            text: '数量',
            align: 'center',
            width: '220px',
            template: () => {
              return `
                  <div class="row align-center space-center">
                    <div style="background: #f3f3f3;width:40px;height:50px;line-height:50px;text-align:center;font-size:18px;border:1px solid #eee;color:#333;cursor:pointer;" @click.stop="minusHandel(row, index)">
                      <span class="el-icon-minus"></span>
                    </div>
                    <input type="text" :disabled="row.defectiveApplyId || row.operationType === '2' || row.isExchange === 'Y'" v-model="row.number" @input="checkValue(row, index)" @change="blurHandel(row, index)" @keyup.enter="$event.target.blur()" style="width:60px;height:50px;line-height:50px;text-align:center;font-size:20px;border:1px solid #eee;border-left:none;color:#333;outline:none;background: #fff;" />
                    <div style="background:#067CF2;color:#fff;width:40px;height:50px;line-height:50px;text-align:center;font-size:18px;cursor:pointer;" @click.stop="plusHandel(row, index)">
                      <span class="el-icon-plus"></span>
                    </div>
                  </div>
                `
            }
          },
          {
            key: 'realPrice',
            text: '实卖价(单价)',
            align: 'center',
            width: '170px',
            template: () => {
              return `
                <div class="row align-center space-center" @click.stop="rowTip(row)">
                  <span style="color:#666;margin-right:8px;">￥</span>
                  <div style="width:90px;">
                    <input :disabled="row.operationType === '2' || row.isExchange === 'Y' || row.operationType == '6'" v-model="row.realPrice" @input="checkValue(row, 'price')" @change="getnewRow(row, index)" @keyup.enter="$event.target.blur()" style="border:1px solid #eee;outline:none;background:#fff;width:100%;text-align:center;height:46px;font-size:20px;border-radius:2px;" />
                    <div style="width:100%;height:20px;background:#ffa940;color:#fff;position:relative;" v-if="row.hasAlterPriceReview === 'Y' || row.isHasPremiumPrice === 'Y'">
                        {{row.hasAlterPriceReview === 'Y' ? '已改价' : '自动补价'}}
                        <span v-if="row.hasAlterPriceReview === 'Y'" style="position:absolute;top:-1px;right:3px;" @click="recoverPriceMount(row, index,'recoverNed')">x</span>
                    </div>    
                </div>
                </div>
              `
            }
          },
          {
            key: 'retailPrice',
            text: '零售价',
            width: '110px',
            align: 'center',
            template: () => {
              return `
                <div class="row align-center space-center" style="font-size:18px">
                  ￥{{row.retailPrice}}
                </div>
              `
            }
          },
          {
            key: 'tagPrice',
            text: '吊牌价',
            width: '110px',
            align: 'center',
            template: () => {
              return `
                <div class="row align-center space-center" style="font-size:18px">
                  ￥{{row.tagPrice}}
                </div>
              `
            }
          },
          {
            key: 'agoRealPrice',
            text: '原价',
            width: '110px',
            align: 'center',
            template: () => {
              return `
                <div class="row align-center space-center" style="font-size:18px">
                  ￥{{row.agoRealPrice}}
                </div>
              `
            }
          },
          {
            key: 'totalPrice',
            text: '金额(元)',
            align: 'center',
            template: () => {
              return `
                <div class="row align-center space-center" style="color:#F8475F;font-size:20px;">
                  ￥{{ row.totalPrice }}
                </div>
              `
            }
          },
          {
            key: 'action',
            text: '操作',
            align: 'center',
            template: () => {
              return `
                <div @click="delRow(row, index)" style="font-size:14px;color:#EAEAEA;cursor:pointer;color:#F8475F;">
                  <span style="border-bottom:1px solid #F8475F;">删除</span>
                </div>
              `
            }
          }
        ],
        ajaxData: this.showTable,
        menuHandler: list => {
          const menu = [
            {
              content: `
                <div class="row align-center space-between">
                  <div>查看库存</div>
                  <span class="iconfont icon-shoucang" style="color:#6E6FEC;font-size:20px;"></span>
                </div>
              `,
              line: true,
              onClick: (gridManagerName, cell) => {
                let row = this.rightClickRow(cell)
                if (row) {
                  this.curRow = row
                  this.singleModule = true
                }
              }
            },
            {
              content: `
                <div class="row align-center space-between">
                  <div style="color:#F8475F;">删除</div>
                  <span class="iconfont icon-shanchu" style="color:#F8475F;font-size:16px;"></span>
                </div>
              `,
              line: false,
              onClick: (gridManagerName, cell) => {
                this.rightDelete(gridManagerName, cell)
              }
            }
          ]
          list = menu
          return list
        }
      },
      spuLoading: false,
      activeModule: false,
      currentActive: null,
      payLoading: false,
      itemColor: null,
      itemSize: null,
      skuInfoObj: null,
      colorModul: false,
      sizeModule: false,
      curSize: '',
      curColor: '',
      chooseSizeList: [],
      chooseColorList: [],
      spulistVisable: false,
      tableIndex: null,
      badGoodsDialog: false,
      webViewUrl: '',
      stockAccountCardModule: false,
      memberValue: '',
      cashierItem: null,
      openState: 'N',
      joinUser: '',
      settingPublic: false,
      salesQueryDialog: false
    }
  },

  created() {
    this.cashierItem = JSON.parse(this.$cache.local.get('cashierDeskItem'))
    this.$nextTick(() => {
      this.$refs.scanAdd.focus()
    })
    this.queryGuide()
    this.quicklyCode()
    this.cancelOpen()
    this.queryInvoceList()
    this.queryBrokerageList()
  },
  mounted() {
    ipc.on(specialIpcRoute.window1ToWindow2, (event, arg) => {
      this.getHangData(arg)
    })
  },

  watch: {
    tableList: {
      handler() {
        if (!this.tableList.length) {
          this.goodInfos = []
          this.activeList = []
          this.activityInfo = []
          this.reduceResult = {}
          this.alterPriceInfo = {}
          this.isAllOrding = false
          this.isUpdatePrice = false
          this.isAllowNegativeStocks = ''
        }
      }
    }
  },

  activated() {
    document.body.addEventListener('click', () => {
      this.salesVisiable = false
      this.ordingVisiable = false
      this.colorModul = false
      this.sizeModule = false
    })
  },

  methods: {
    quicklyCode() {
      // 快捷键方法
      Mousetrap.bind(['alt+1', 'alt+1'], () => {
        //扫码聚焦
        this.$nextTick(() => {
          this.$refs.scanAdd.focus()
        })
      })
      Mousetrap.bind(['alt+s', 'alt+s'], () => {
        //销售查询
        this.salesQueryDialog = true
      })
      Mousetrap.bind(['alt+v', 'alt+v'], () => {
        //客订记录
        this.openNewWindow('4')
      })
      Mousetrap.bind(['alt+b', 'alt+b'], () => {
        //调价通知
        this.openNewWindow('5')
      })
      Mousetrap.bind(['alt+r', 'alt+r'], () => {
        //时段目标跟进
        this.openNewWindow('6')
      })
      Mousetrap.bind(['alt+g', 'alt+g'], () => {
        //库存账卡
        this.stockAccountCardModule = true
      })
      Mousetrap.bind(['alt+c', 'alt+c'], () => {
        //单款库存
        if (this.badGoodsDialog || this.vipVisiable || this.payTypeDialog || this.hangGetModule || this.priceModule) {
          return
        } else {
          this.singleModule = true
        }
      })
      Mousetrap.bind(['alt+f', 'alt+f'], () => {
        //挂单取单
        if (this.badGoodsDialog || this.vipVisiable || this.payTypeDialog || this.singleModule || this.priceModule) {
          return
        } else {
          this.hangAndGet()
        }
      })
      Mousetrap.bind(['alt+q', 'alt+q'], () => {
        //次品销售
        if (this.singleModule || this.payTypeDialog || this.hangGetModule || this.priceModule) {
          return
        } else {
          this.chooseBadGoods()
        }
      })
      Mousetrap.bind(['alt+x', 'alt+x'], () => {
        //会员功能
        if (this.badGoodsDialog || this.singleModule || this.payTypeDialog || this.hangGetModule || this.priceModule) {
          return
        } else {
          this.chooseVip()
        }
      })
      Mousetrap.bind(['alt+z', 'alt+z'], () => {
        //结算
        if (this.badGoodsDialog || this.singleModule || this.vipVisiable || this.hangGetModule || this.priceModule) {
          return
        } else {
          this.checkPublicState()
        }
      })
    },

    queryGoodsNoList() {
      //查询货号列表
      if (!this.searchSpu)
        return this.$message({
          showClose: true,
          type: 'error',
          message: '请输入货号或简码查询',
          duration: 2000
        })
      this.spuLoading = true
      queryGoodsNo({
        keyword: this.searchSpu.trim(),
        pageSize: '20',
        page: this.page
      })
        .then(data => {
          if (
            !data ||
            JSON.stringify(data) === '{}' ||
            (data && !data.spuInfoList) ||
            (data && data.spuInfoList && !data.spuInfoList[0])
          ) {
            this.searchSpu = ''
            this.$message({
              showClose: true,
              type: 'error',
              message: '未查询到货号信息，请重新输入货号',
              duration: 2000
            })
          } else {
            this.spulistVisable = true
            this.spuList = data.spuInfoList
            this.totalList = +data.totalItem
          }
          this.spuLoading = false
        })
        .catch(error => {
          this.spuLoading = false
          this.$message({ showClose: true, type: 'error', message: error, duration: 2000 })
        })
    },

    getCurPage(val) {
      //货号列表切换页码
      this.page = val
      this.queryGoodsNoList()
    },

    curSpu(item) {
      //当前选中货号
      this.searchSpu = item.goodsNo
      this.itemSize = item.spuInfoBean.attrList[0]
      this.itemColor = item.spuInfoBean.attrList[1]
      this.chooseSizeList = this.itemSize.valueList
      this.chooseColorList = this.itemColor.valueList
      this.skuInfoObj = item.spuInfoBean
      this.colorModul = true
      this.spulistVisable = false
    },

    chooseColor() {
      //颜色选择下拉框
      this.sizeModule = false
      if (!this.itemColor)
        return this.$message({ showClose: true, type: 'error', message: '请选择货号', duration: 2000 })
      this.colorModul = !this.colorModul
    },

    chooseSize() {
      //尺码选择下拉框
      this.colorModul = false
      if (!this.itemColor)
        return this.$message({ showClose: true, type: 'error', message: '请选择货号', duration: 2000 })
      this.sizeModule = !this.sizeModule
    },

    resetColorSize() {
      //重置货号选择相关信息
      this.searchSpu = ''
      this.curColor = ''
      this.curSize = ''
      this.keyword = ''
      this.itemSize = null
      this.itemColor = null
      this.skuInfoObj = null
      this.spuList = []
      this.chooseSizeList = []
      this.chooseColorList = []
    },

    selectColor(val) {
      //选择颜色
      this.curColor = val
      this.colorModul = false
      let curArray = []
      this.skuInfoObj.storeSkuInfoBeanList.forEach(item => {
        item.attrList.forEach(list => {
          if (this.curSize) {
            if (
              this.itemColor &&
              list.code === this.itemColor.code &&
              list.value === this.curColor &&
              this.curSize === item.attrList[1].value
            ) {
              this.resetColorSize()
              this.operateItem(item.barCode)
            }
          }
          if (this.itemColor && list.code === this.itemColor.code && list.value === this.curColor) {
            curArray.push(item)
          }
        })
      })
      let clickArr = curArray.map(item => {
        return item.attrList[1].value
      })
      if (!this.itemSize) return
      let newList = this.itemSize.valueList.filter(item => {
        return clickArr.includes(item.value)
      })
      this.chooseSizeList = newList
      if (!this.curSize) {
        this.sizeModule = true
      }
    },

    selectSize(val) {
      //选择尺码
      this.curSize = val
      this.sizeModule = false
      let curArray = []
      this.skuInfoObj.storeSkuInfoBeanList.forEach(item => {
        item.attrList.forEach(list => {
          if (this.curColor) {
            if (
              this.itemSize &&
              list.code === this.itemSize.code &&
              list.value === this.curSize &&
              this.curColor === item.attrList[0].value
            ) {
              this.resetColorSize()
              this.operateItem(item.barCode)
            }
          }
          if (this.itemSize && list.code === this.itemSize.code && list.value === this.curSize) {
            curArray.push(item)
          }
        })
      })
      let clickArr = curArray.map(item => {
        return item.attrList[0].value
      })
      if (!this.itemColor) return
      let newList = this.itemColor.valueList.filter(item => {
        return clickArr.includes(item.value)
      })
      this.chooseColorList = newList
      if (!this.curColor) {
        this.colorModul = true
      }
    },

    async openNewWindow(type) {
      //打开新窗口
      if (type === '1') {
        let options = {
          type: 'vue',
          content: '/salesRecord',
          windowName: '销售记录',
          winId: 'salesRecord'
        }
        ipc.invoke(ipcApiRoute.createWindow, options).then(r => {})
      }
      if (type === '2') {
        let options = {
          type: 'vue',
          content: '/timeQuantum',
          windowName: '时段业绩',
          winId: 'timeQuantum'
        }
        ipc.invoke(ipcApiRoute.createWindow, options).then(r => {})
      }
      if (type === '3') {
        let options = {
          type: 'vue',
          content: '/performanceCalendar',
          windowName: '业绩日历',
          winId: 'performanceCalendar'
        }
        ipc.invoke(ipcApiRoute.createWindow, options).then(r => {})
      }
      if (type === '4') {
        let options = {
          type: 'vue',
          content: '/customerOrder',
          windowName: '客订记录',
          winId: 'customerOrder'
        }
        ipc.invoke(ipcApiRoute.createWindow, options).then(r => {})
      }
      if (type === '5') {
        let options = {
          type: 'vue',
          content: '/priceAdjustmentNotice',
          windowName: '调价通知',
          winId: 'priceAdjustmentNotice'
        }
        ipc.invoke(ipcApiRoute.createWindow, options).then(r => {})
      }
      if (type === '6') {
        let options = {
          type: 'vue',
          width: 940,
          height: 808,
          content: '/timeTargetFollow',
          windowName: '时段目标跟进',
          winId: 'timeTargetFollow'
        }
        ipc.invoke(ipcApiRoute.createWindow, options).then(r => {})
      }
    },

    chooseBadGoods() {
      //选择次品
      let res = this.tableList.some(item => {
        return !item.defectiveApplyId
      })
      if (res)
        return this.$message({
          showClose: true,
          type: 'error',
          message: '当前货品列表不能添加次品，请清空后再添加',
          duration: 2000
        })
      this.badGoodsDialog = true
    },

    clearHandel(e) {
      //次品作废回调清空对应列表数据
      let index = this.tableList.findIndex(item => {
        return item.barCode === e.barCode && item.realPrice === e.suggestPrice
      })
      if (index !== -1) {
        let obj = {
          index: index,
          barCode: this.tableList[index].barCode,
          sku: this.tableList[index].sku,
          number: this.tableList[index].number * -1,
          operationType: this.tableList[index].operationType,
          alterGoodsWay: '4',
          defectiveId: this.tableList[index].defectiveApplyId
        }
        this.operateItem('', '4', obj)
      }
    },

    chooseVip() {
      //选择会员功能
      this.vipVisiable = true
    },

    hangAndGet() {
      //提交挂单信息
      if (this.tableList.length) {
        let shopArr = this.guiderList.map(item => {
          return item.id
        })
        this.loading = true
        submitStoreInvoice({
          storeInfoList: this.tableList,
          type: '1',
          source: '2',
          terminalId: this.cashierItem ? this.cashierItem.id : '',
          orderTypeId: this.ordingNature.id,
          brokerage: this.salesTypeId,
          crmVipId: this.curVip ? this.curVip.id : '',
          checkActivityInfos: this.activityInfo,
          isAlterpriceReview: this.isUpdatePrice ? 'Y' : 'N',
          shoppingGuideIds: shopArr,
          ...this.reduceResult
        })
          .then(data => {
            this.loading = false
            this.getState()
            this.$message({ type: 'success', message: '操作成功', duration: 2000, showClose: true })
          })
          .catch(err => {
            this.loading = false
            this.$message({ type: 'error', message: err, duration: 2000, showClose: true })
          })
      } else {
        this.hangGetModule = true
      }
    },

    getHangData(data) {
      //取出挂单数据
      if (data) {
        this.isUpdatePrice = false
        this.goodInfos = JSON.parse(JSON.stringify(data.goodInfos))
        this.tableList = data.goodInfos || []
        this.nedTypeList = []
        data.goodInfos.forEach(item => {
          if (item.operationType == '6') {
            this.nedTypeList.push(item)
          }
        })
        this.reduceResult = data.reduceResult
        this.activeList = data.activityInfos || []
        this.activityInfo = data.checkActivityInfos || []
        this.isAllowNegativeStocks = data.isAllowNegativeStocks
        if (data.brokerage) {
          this.salesTypeId = data.brokerage
          this.curSalesTitle = this.salesList[data.brokerage - 1].title
        }
        if (data.orderTypeId) {
          this.ordingNature = this.ordingTypeList.find(item => {
            return item.id === data.orderTypeId
          })
        }
        if (data.assistantUserIds) {
          this.guiderList = this.shopList.filter(item => {
            return data.assistantUserIds.includes(item.id)
          })
          if (data.brokerage === '1' && this.guiderList.length) {
            this.shopGuide = this.guiderList[0].realName
          }
        }
        if (data.crmVipId) {
          crmVipDetail({ id: data.crmVipId })
            .then(data => {
              this.curVip = data
            })
            .catch(err => {})
        }
        if (this.activeList && this.activeList.length) {
          this.activeList.forEach(act => {
            this.$set(act, 'selectAct', false)
            this.$set(act, 'checkGift', [])
            this.$set(act, 'checkGive', [])
            if (this.activityInfo && this.activityInfo.length) {
              this.activityInfo.forEach(check => {
                if (act.activityId === check.activityId) {
                  act.selectAct = true
                  if (check.giftGoodInfos && check.giftGoodInfos.length) {
                    act.checkGift = check.giftGoodInfos
                  }
                  if (check.addGoodInfos && check.addGoodInfos.length) {
                    act.checkGive = check.addGoodInfos
                  }
                }
              })
            }
          })
        }
      }
    },

    async queryInvoceList() {
      //查询订单性质
      let res = await queryInvoiceType()
      this.ordingTypeList = res || []
      if (res && res.length) {
        this.ordingNature = res[0]
      }
    },

    async queryBrokerageList() {
      //查询业绩方式
      let res = await queryBrokerage({ platform: 'pos' })
      if (res && res[0]) {
        this.salesList = res.map(item => {
          return {
            id: item.code,
            title: item.name
          }
        })
      }
    },

    showGuideList() {
      if (this.openState === 'Y')
        return this.$message({ type: 'error', message: '开启公单后不允许修改营业员', duration: 2000, showClose: true })
      this.shopListVisable = true
    },

    saleshande(item) {
      //选择业绩方式
      this.salesVisiable = false
      if (this.salesTypeId === item.id) return
      this.curSalesTitle = item.title
      this.salesTypeId = item.id
      this.guiderList = []
      this.shopGuide = ''
    },

    queryGuide() {
      // 查询营业员
      queryShoppGuide({
        page: '1',
        isSy: 'Y',
        pageSize: '50',
        keyword: this.shopGuide
      })
        .then(res => {
          if (res && res.items) {
            this.shopList = res.items || []
            if (this.openState === 'Y') {
              this.shopList = res.items.filter(item => {
                return item.realName === this.$cache.local.get('deptName')
              })
              this.guiderList = this.shopList
              this.shopGuide = this.guiderList[0].realName
            } else {
              this.shopList = res.items.filter(item => {
                return item.realName !== this.$cache.local.get('deptName')
              })
            }
          }
        })
        .catch(() => {})
    },

    getcurUser(item) {
      //选择营业员
      this.guiderList.splice(0, 1, item)
      this.shopGuide = item.realName
      this.shopListVisable = false
    },

    getGanger(e) {
      //班组营业员
      this.guiderList = []
      this.guideModule = false
      if (e) {
        this.guiderList = e
      }
    },

    guideBlur() {
      //清除选中营业员
      if (!this.shopGuide) {
        this.guiderList = []
      }
    },

    operateItem(barcode, type = '1', operateObj, isScan = 'N', allPrice, priceType) {
      //操作列表货品
      //barcode条码  type操作类型 1货品 2活动 3价格 4次品  operateObj操作对象(货品对象、活动对象) isScan是否是扫码  allPrice整单改价价格
      let res = this.tableList.some(item => {
        return item.defectiveApplyId
      })
      if (res && (barcode || isScan === 'Y')) {
        this.searchWord = ''
        return this.$message({
          type: 'error',
          message: '当前为次品销售，只能添加次品',
          duration: 2000,
          showClose: true
        })
      }
      if (isScan === 'Y' && !this.searchWord)
        return this.$message({ showClose: true, type: 'error', message: '请扫吊牌或输入条码', duration: 2000 })
      if (this.loading) return
      this.loading = true
      let reqData = {
        type: type,
        isUpdatePrice: this.isUpdatePrice ? 'Y' : 'N',
        operationType: this.tabType,
        typeId: this.ordingNature ? this.ordingNature.id : ''
      }
      if (this.alterPriceInfo && this.alterPriceInfo.alterPrice) {
        reqData['type'] = type === '4' ? type : '3'
        reqData['isUpdatePrice'] = 'Y'
      }
      if (this.searchWord) {
        reqData['scanSku'] = this.searchWord
      }
      if (barcode) {
        reqData['scanSku'] = barcode
      }
      if (this.isAllowNegativeStocks) {
        reqData['isAllowNegativeStocks'] = this.isAllowNegativeStocks
      }
      if (this.tableList && this.tableList.length) {
        reqData['goodInfos'] = this.goodInfos
      }
      if (operateObj) {
        if (type === '2') {
          reqData['operateActivityInfo'] = operateObj
        } else {
          reqData['operateGoodInfo'] = operateObj
        }
      }
      if (this.activityInfo.length) {
        reqData['checkActivityInfos'] = this.activityInfo
      }
      if (this.activeList.length) {
        reqData['activityInfos'] = this.activeList
      }
      if (allPrice && allPrice.price) {
        reqData['totalOrderAlterPrice'] = allPrice.price
      }
      if (priceType == 'ned') {
        this.isUpdatePrice = false
        reqData['isUpdatePrice'] = 'N'
      }
      operateGoodsNo(reqData)
        .then(data => {
          if (data && data.alterPriceInfo) {
            this.alterPriceInfo = data.alterPriceInfo
          }
          if (allPrice && data && data.alterPriceInfo) {
            this.alterPriceInfo.reduceResult = data.reduceResult
            this.loading = false
            this.isAllOrding = true
            this.priceModule = true
            return
          }
          if (!data || JSON.stringify(data) === '{}' || (data && !data.goodInfos)) {
            this.$message({ showClose: true, type: 'error', message: '没有查询到货品信息', duration: 2000 })
          } else {
            this.goodInfos = JSON.parse(JSON.stringify(data.goodInfos))
            this.tableList = data.goodInfos || []
            this.nedTypeList = []
            data.goodInfos.forEach(item => {
              if (item.operationType == '6') {
                this.nedTypeList.push(item)
              }
            })
            GridManager.refreshGrid(this.gridOption.gridManagerName)
            this.reduceResult = data.reduceResult
            this.activeList = data.activityInfos || []
            this.activityInfo = data.checkActivityInfos || []
            this.isAllowNegativeStocks = data.isAllowNegativeStocks
            if (this.activeList && this.activeList.length) {
              this.activeList.forEach(act => {
                this.$set(act, 'selectAct', false)
                this.$set(act, 'checkGift', [])
                this.$set(act, 'checkGive', [])
                if (this.activityInfo && this.activityInfo.length) {
                  this.activityInfo.forEach(check => {
                    if (act.activityId === check.activityId) {
                      act.selectAct = true
                      if (check.giftGoodInfos && check.giftGoodInfos.length) {
                        act.checkGift = check.giftGoodInfos
                      }
                      if (check.addGoodInfos && check.addGoodInfos.length) {
                        act.checkGive = check.addGoodInfos
                      }
                    }
                  })
                }
              })
            }
            this.$message({ showClose: true, type: 'success', message: '操作成功', duration: 2000 })
          }
          if (this.tableList[0]) {
            GridManager.renderGrid(this.gridOption.gridManagerName)
          }
          this.searchWord = ''
          this.loading = false
        })
        .catch(error => {
          this.searchWord = ''
          GridManager.renderGrid(this.gridOption.gridManagerName)
          if (this.activeList && this.activeList.length) {
            this.activeList.forEach(act => {
              this.$set(act, 'selectAct', false)
              this.$set(act, 'checkGift', [])
              this.$set(act, 'checkGive', [])
              if (this.activityInfo && this.activityInfo.length) {
                this.activityInfo.forEach(check => {
                  if (act.activityId === check.activityId) {
                    act.selectAct = true
                    if (check.giftGoodInfos && check.giftGoodInfos.length) {
                      act.checkGift = check.giftGoodInfos
                    }
                    if (check.addGoodInfos && check.addGoodInfos.length) {
                      act.checkGive = check.addGoodInfos
                    }
                  }
                })
              }
            })
          }
          this.loading = false
          this.$message({ showClose: true, type: 'error', message: error, duration: 2000 })
        })
    },

    showTable() {
      if (this.tableList.length) {
        let goodsNed = this.tableList.map(item => {
          return item.operationType
        })
        if (goodsNed.includes('6')) {
          GridManager.showTh(this.gridOption.gridManagerName, ['agoRealPrice'])
        } else {
          GridManager.hideTh(this.gridOption.gridManagerName, ['agoRealPrice'])
        }
      }

      // 渲染表格数据
      return new Promise((resolve, reject) => {
        const tableData = {
          data: this.tableList
        }
        resolve(tableData)
      })
    },

    checkValue(row, type) {
      //货品列表输入框的值验证
      if (type === 'price') {
        row.realPrice = row.realPrice
          .replace(/[^\d^\.]+/g, '')
          .replace('.', '$#$')
          .replace(/\./g, '')
          .replace('$#$', '.')
        row.realPrice = row.realPrice.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3')
      } else {
        row.number = row.number.replace(/\D|^0/g, '')
      }
    },
    rowTip(row) {
      if (row.operationType === '2' || row.isExchange === 'Y')
        this.$message({
          type: 'error',
          message: '赠品、加购货品不允许修改数量、价格和颜色尺码',
          duration: 2000,
          showClose: true
        })
    },
    minusHandel(row, index) {
      //数量减少
      if (row.defectiveApplyId || row.operationType === '2' || row.isExchange === 'Y') {
        return this.$message({
          type: 'error',
          message: '次品、赠品、加购货品不允许修改数量、价格和颜色尺码',
          duration: 2000,
          showClose: true
        })
      }
      let obj = {
        index: index,
        barCode: row.barCode,
        sku: row.sku,
        number: '-1',
        isRedemption: row.isRedemption || 'N',
        operationType: row.operationType,
        alterGoodsWay: '2'
      }
      if (row.activityId) {
        obj['activityId'] = row.activityId
      }
      if (row.alterRealPrice) {
        obj['alterRealPrice'] = row.alterRealPrice
      }
      if (row.hasAlterPriceReview === 'Y') {
        obj['hasAlterPriceReview'] = row.hasAlterPriceReview
      }
      this.alterPriceInfo = {}
      this.goodInfos.forEach((item, index) => {
        if (item.hasAlterPriceReview == 'Y') {
          if (item.retailPrice) {
            this.$set(this.goodInfos[index], 'realPrice', item.retailPrice)
            this.$set(this.goodInfos[index], 'hasAlterPriceReview', 'N')
          }
        }
      })
      this.operateItem('', '1', obj)
    },

    plusHandel(row, index) {
      //数量增加
      if (row.defectiveApplyId || row.operationType === '2' || row.isExchange === 'Y') {
        return this.$message({
          type: 'error',
          message: '次品、赠品、加购货品不允许修改数量、价格和颜色尺码',
          duration: 2000,
          showClose: true
        })
      }
      let obj = {
        index: index,
        barCode: row.barCode,
        sku: row.sku,
        number: '1',
        isRedemption: row.isRedemption || 'N',
        operationType: row.operationType,
        alterGoodsWay: '1'
      }
      if (row.activityId) {
        obj['activityId'] = row.activityId
      }
      if (row.alterRealPrice) {
        obj['alterRealPrice'] = row.alterRealPrice
      }
      if (row.hasAlterPriceReview === 'Y') {
        obj['hasAlterPriceReview'] = row.hasAlterPriceReview
      }
      this.alterPriceInfo = {}
      this.goodInfos.forEach((item, index) => {
        if (item.hasAlterPriceReview == 'Y') {
          if (item.retailPrice) {
            this.$set(this.goodInfos[index], 'realPrice', item.retailPrice)
            this.$set(this.goodInfos[index], 'hasAlterPriceReview', 'N')
          }
        }
      })
      this.operateItem('', '1', obj)
    },

    blurHandel(row, index) {
      //输入数量
      if (!row.number || +row.number <= 0) {
        GridManager.renderGrid(this.gridOption.gridManagerName)
        return this.$message({ type: 'error', message: '请输入有效数量', duration: 2000, showClose: true })
      }
      let obj = {
        index: index,
        barCode: row.barCode,
        sku: row.sku,
        number: row.number,
        isRedemption: row.isRedemption || 'N',
        operationType: row.operationType,
        alterGoodsWay: '3'
      }
      if (row.activityId) {
        obj['activityId'] = row.activityId
      }
      if (row.alterRealPrice) {
        obj['alterRealPrice'] = row.alterRealPrice
      }
      if (row.hasAlterPriceReview === 'Y') {
        obj['hasAlterPriceReview'] = row.hasAlterPriceReview
      }
      this.alterPriceInfo = {}
      this.goodInfos.forEach((item, index) => {
        if (item.hasAlterPriceReview == 'Y') {
          if (item.retailPrice) {
            this.$set(this.goodInfos[index], 'realPrice', item.retailPrice)
            this.$set(this.goodInfos[index], 'hasAlterPriceReview', 'N')
          }
        }
      })
      this.operateItem('', '1', obj)
    },

    recoverPriceMount(row, index, type) {
      this.$confirm('确认要取消改价', '提示')
        .then(res => {
          this.getnewRow(row, index, type)
        })
        .catch(() => {})
    },
    getnewRow(row, index, type) {
      // 修改当前行的实卖价
      if (row.defectiveApplyId && Number(row.realPrice) <= 0) {
        GridManager.renderGrid(this.gridOption.gridManagerName)
        return this.$message({ type: 'error', message: '请输入大于0的金额', duration: 2000, showClose: true })
      }
      if (!row.realPrice) {
        GridManager.renderGrid(this.gridOption.gridManagerName)
        return this.$message({ type: 'error', message: '请输入有效金额', duration: 2000, showClose: true })
      }
      if (this.activityInfo && this.activityInfo.length) {
        GridManager.renderGrid(this.gridOption.gridManagerName)
        return this.$message({
          type: 'error',
          message: '当前货品参与促销，左下角取消促销后再改价',
          duration: 2000,
          showClose: true
        })
      }
      let obj = {
        index: index,
        barCode: row.barCode,
        sku: row.sku,
        number: row.number,
        isRedemption: row.isRedemption || 'N',
        operationType: '5',
        price: row.realPrice,
        alterGoodsWay: '3'
      }

      if (row.activityId) {
        obj['activityId'] = row.activityId
      }
      if (row.alterRealPrice) {
        obj['alterRealPrice'] = row.alterRealPrice
      }
      if (row.hasAlterPriceReview === 'Y') {
        obj['hasAlterPriceReview'] = row.hasAlterPriceReview
      }
      if (type == 'recoverNed') {
        obj['price'] = row.retailPrice
        obj['hasAlterPriceReview'] = 'N'
        this.alterPriceInfo = {}
        this.goodInfos.forEach((item, index) => {
          if (item.hasAlterPriceReview == 'Y') {
            if (item.retailPrice) {
              this.$set(this.goodInfos[index], 'realPrice', item.retailPrice)
              this.$set(this.goodInfos[index], 'hasAlterPriceReview', 'N')
            }
          }
        })
      }
      let list = []
      this.tableList.filter((item, idx) => {
        if (item.operationType !== '6' && idx !== index) {
          list.push(item)
        }
      })
      let recoverPrice = true
      if (list && list.length) {
        recoverPrice = list.every(item => {
          return Number(item.realPrice) === Number(item.initRealPrice)
        })
      }
      if (type == 'recoverNed') {
        this.isUpdatePrice = !recoverPrice
        this.$set(this.goodInfos[index], 'hasAlterPriceReview', 'N')
        this.alterPriceInfo = {}
        obj['alterGoodsWay'] = '5'
        this.operateItem('', '1', obj)
      } else {
        if (Number(row.realPrice) === Number(row.agoRealPrice) && recoverPrice) {
          this.isUpdatePrice = false
          this.alterPriceInfo = {}
          obj['alterGoodsWay'] = '5'
          this.operateItem('', '1', obj)
        } else {
          this.isUpdatePrice = true
          this.$set(this.goodInfos[index], 'hasAlterPriceReview', 'Y')
          this.operateItem('', '3', obj)
        }
      }
    },

    cancelUpdatePrice() {
      if (this.isAllOrding) {
        this.alterPriceInfo = {}
        this.isUpdatePrice = false
      }
      this.priceModule = false
      this.isAllOrding = false
    },

    guigeHandel(row, index) {
      // 选择规格弹窗
      if (row.defectiveApplyId || row.operationType === '2' || row.isExchange === 'Y') {
        return this.$message({
          type: 'error',
          message: '次品、赠品、加购货品不允许修改数量、价格和颜色尺码',
          duration: 2000,
          showClose: true
        })
      }
      this.curRow = row
      this.guigeModule = true
      this.tableIndex = index
    },

    closeGuigeModul(row) {
      // 关闭选择规格
      this.guigeModule = false
      if (row) {
        let obj = {
          index: this.tableIndex,
          barCode: row.barCode,
          sku: row.sku,
          number: row.number,
          isRedemption: row.isRedemption || 'N',
          operationType: this.curRow.operationType,
          alterGoodsWay: '5'
        }
        if (this.curRow.activityId) {
          obj['activityId'] = this.curRow.activityId
        }
        if (row.alterRealPrice) {
          obj['alterRealPrice'] = row.alterRealPrice
        }
        if (row.hasAlterPriceReview === 'Y') {
          obj['hasAlterPriceReview'] = row.hasAlterPriceReview
        }
        this.operateItem('', '1', obj)
      }
    },
    //关闭库存账卡
    closeStockAccountCard() {
      this.stockAccountCardModule = false
    },
    delRow(row, index) {
      //删除表格中一条记录
      this.$confirm('是否删除该条记录', '提示')
        .then(() => {
          let obj = {
            index: index,
            barCode: row.barCode,
            sku: row.sku,
            number: row.number * -1,
            isRedemption: row.isRedemption || 'N',
            operationType: row.operationType,
            alterGoodsWay: '4'
          }
          if (row.defectiveApplyId) {
            obj['defectiveId'] = row.defectiveApplyId
          }
          if (row.activityId) {
            obj['activityId'] = row.activityId
          }
          this.alterPriceInfo = {}
          this.goodInfos.forEach((item, index) => {
            if (item.hasAlterPriceReview == 'Y') {
              if (item.retailPrice) {
                this.$set(this.goodInfos[index], 'realPrice', item.retailPrice)
                this.$set(this.goodInfos[index], 'hasAlterPriceReview', 'N')
              }
            }
          })
          this.operateItem('', row.defectiveApplyId ? '4' : '1', obj)
        })
        .catch(() => {})
    },

    clearAll() {
      // 清空表格数据
      if (!this.tableList.length) return
      this.$confirm('是否清空货品列表', '提示')
        .then(() => {
          this.tableList = []
          this.nedTypeList = []
          this.reduceResult = {}
          this.activeList = []
          this.$message({ type: 'success', message: '操作成功', duration: 2000, showClose: true })
        })
        .catch(() => {})
    },

    rightClickRow(cell) {
      // 表格右键方法获取当前行数据
      let tr = cell.parentNode
      let row = GridManager.getRowData(this.gridOption.gridManagerName, tr)
      while (!row['barCode']) {
        tr = tr.parentNode
        row = GridManager.getRowData(this.gridOption.gridManagerName, tr)
      }
      return row
    },

    rightDelete(g, cell) {
      // 右键删除
      let row = this.rightClickRow(cell)
      let index = this.tableList.findIndex(item => {
        return (
          item.barCode === row.barCode &&
          item.realPrice === row.realPrice &&
          row.activityId === item.activityId &&
          row.operationType === item.operationType
        )
      })
      this.delRow(row, index)
    },

    getskuData(e) {
      //单款库存和次品弹窗加到货品列表
      if (e) {
        if (e.id && e.badGoods) {
          let obj = {
            alterGoodsWay: '1',
            defectiveId: e.id
          }
          this.operateItem('', '4', obj)
        } else {
          this.operateItem(e)
        }
      }
    },

    chooseActive(item) {
      //选择活动
      item.selectAct = !item.selectAct
      if (item.selectAct) {
        let checkMutIds = []
        if (this.activityInfo && this.activityInfo.length) {
          this.activityInfo.forEach(check => {
            if (check.mutualExclusionActivityIds && check.mutualExclusionActivityIds.length) {
              checkMutIds = checkMutIds.concat(check.mutualExclusionActivityIds)
            }
          })
        }
        if (checkMutIds.includes(item.activityId)) {
          item.selectAct = false
          return this.$message({
            type: 'error',
            message: '当前活动与已选择的活动互斥不能选择',
            duration: 2000,
            showClose: true
          })
        }
        let obj = {
          operateType: 'Y',
          activityId: item.activityId
        }
        this.operateItem('', '2', obj)
      } else {
        let obj = {
          operateType: 'N',
          activityId: item.activityId
        }
        this.operateItem('', '2', obj)
      }
    },

    giftAdd(type, item) {
      if (!item.selectAct)
        return this.$message({ type: 'error', message: '请先选择该活动', duration: 2000, showClose: true })
      this.currentActive = item
      let checkMutIds = []
      if (this.activityInfo && this.activityInfo.length) {
        this.activityInfo.forEach(check => {
          if (check.mutualExclusionActivityIds && check.mutualExclusionActivityIds.length) {
            checkMutIds = checkMutIds.concat(check.mutualExclusionActivityIds)
          }
        })
      }
      if (checkMutIds.includes(item.activityId)) {
        return this.$message({
          type: 'error',
          message: '当前活动与已选择的活动互斥不能选择',
          duration: 2000,
          showClose: true
        })
      }
      if (type === 'gift') {
        this.giveModele = true
      } else {
        this.tradeModule = true
      }
    },

    clsoePliment(e) {
      //关闭选择赠品弹窗
      this.giveModele = false
      if (e) {
        let obj = {
          activityId: e.activityId,
          giftOrAddType: 'Y'
        }
        if (e.checkGift && e.checkGift.length) {
          obj['giftGoodInfos'] = e.checkGift
        }
        this.operateItem('', '2', obj)
      }
    },

    closeTrade(e) {
      //关闭加购货品弹窗
      this.tradeModule = false
      if (e) {
        let obj = {
          activityId: e.activityId,
          giftOrAddType: 'N'
        }
        if (e.checkGive && e.checkGive.length) {
          obj['addGoodInfos'] = e.checkGive
        }
        this.operateItem('', '2', obj)
      }
    },

    changePriceModule() {
      // 整单改价弹窗
      if (!this.tableList.length)
        return this.$message({ type: 'error', message: '请先添加商品', duration: 2000, showClose: true })
      let res = this.tableList.some(item => {
        return item.defectiveApplyId
      })
      if (res) {
        return this.$message({
          type: 'error',
          message: '当前为次品销售不允许改价',
          duration: 2000,
          showClose: true
        })
      }
      this.allPriceModule = true
    },

    changeAllPrice(e) {
      //整单改价
      this.allPriceModule = false
      if (e) {
        this.isUpdatePrice = true
        this.operateItem('', '3', '', 'N', e)
      }
    },

    checkPublicState() {
      if (Number(this.reduceResult.receivablePrice) < 0) {
        return this.$message.error('换货不支持退款，请推荐更换等价或更高价值的货品！')
      }
      let subList = this.goodInfos.map(item => {
        return item.operationType
      })
      if (subList.length && subList.includes('6') && !subList.includes('1')) {
        return this.$message({ type: 'error', message: '请先添加要换的货品！', duration: 2000, showClose: true })
      }
      if (this.openState === 'Y') {
        queryPublicOrderEnableState({
          page: '1',
          pageSizes: '1',
          marketIdList: [this.$cache.local.get('marketingUnitId')]
        })
          .then(res => {
            if (res && res.items && res.items[0].stateCode === '0') {
              this.$confirm('当前店铺公单已关闭，请重新设置', '提示', {
                showClose: false,
                showCancelButton: false,
                closeOnClickModal: false,
                closeOnPressEscape: false
              })
                .then(() => {
                  this.openState = 'N'
                  this.shopGuide = ''
                  this.guiderList = []
                  this.queryGuide()
                })
                .catch(() => {})
            } else {
              this.accountHandel()
            }
          })
          .catch(error => {})
      } else {
        this.accountHandel()
      }
    },
    effectStateClose() {
      this.effectStateDialog = false
    },
    accountHandel() {
      //点击结算按钮
      this.isDown = false
      if (this.curVip && this.curVip.effectState == '1') {
        this.effectStateDialog = true
        return
      }
      if (!this.tableList.length)
        return this.$message({ type: 'error', message: '请先添加商品', duration: 2000, showClose: true })
      if (!this.guiderList.length || !this.shopList.length) {
        if (this.salesTypeId === '1') {
          this.$nextTick(() => {
            this.$refs.focusGuider.focus()
          })
        } else {
          this.guideModule = true
        }
        return this.$message({ type: 'error', message: '请选择营业员', duration: 2000, showClose: true })
      }
      const list = this.tableList.filter(item => {
        return item.hasAlterPriceReview === 'Y' && !item.defectiveApplyId
      })
      let changGoodsNo = list.map(item => {
        return item.goodsNo
      })
      if (changGoodsNo && changGoodsNo[0]) {
        this.$confirm(`货品金额超出可改范围，请提交改价申请。改价需申请货品货号：${changGoodsNo}`, '改价金额超出限制', {
          closeOnClickModal: false
        })
          .then(() => {
            this.priceModule = true
          })
          .catch(error => {})
      } else {
        if (Number(this.reduceResult.receivablePrice) < 0) {
          return this.$message.error('换货不支持退款，请推荐更换等价或更高价值的货品！')
          // this.payLose()
        } else if (Number(this.reduceResult.receivablePrice) == 0) {
          this.payLose()
        } else {
          this.payTypeDialog = true
        }
      }
    },

    payLose() {
      //金额为负时直接支付
      if (this.payLoading) return
      this.loading = true
      this.payLoading = true
      let shopArr = this.guiderList.map(item => {
        return item.id
      })
      submitStoreInvoice({
        storeInfoList: this.tableList,
        type: '2',
        source: '2',
        terminalId: this.cashierItem ? this.cashierItem.id : '',
        orderTypeId: this.ordingNature.id,
        brokerage: this.salesTypeId,
        crmVipId: this.curVip ? this.curVip.id : '',
        isAlterpriceReview: this.isUpdatePrice ? 'Y' : 'N',
        checkActivityInfos: this.activityInfo,
        shoppingGuideIds: shopArr,
        ...this.reduceResult,
        //为0直接支付，必现金支付且必为0
        proceedsInfoList: [
          {
            id: '1',
            proceedsPrice: '0'
          }
        ]
      })
        .then(data => {
          this.getState()
          this.queryPrintData(data.innerSn)
          this.payLoading = false
          this.$message({ type: 'success', message: '结算完成！', duration: 2000, showClose: true })
          this.loading = false
        })
        .catch(err => {
          this.payLoading = false
          this.loading = false
          this.$message({ type: 'error', message: err, duration: 2000, showClose: true })
        })
      // .then(data => {
      //   generatePayInfo({
      //     id: data.id,
      //     isAlteration: 'Y',
      //     proceedsInfoList: [{ id: '1', proceedsPrice: this.reduceResult.receivablePrice }],
      //     terminalId: this.cashierItem ? this.cashierItem.id : ''
      //   })
      //     .then(res => {
      //       this.getState()
      //       this.queryPrintData(data.innerSn)
      //       this.payLoading = false
      //       this.$message({ type: 'success', message: '结算完成！', duration: 2000, showClose: true })
      //       this.loading = false
      //     })
      //     .catch(err => {
      //       this.payLoading = false
      //       this.loading = false
      //       this.$message({ type: 'error', message: err, duration: 2000, showClose: true })
      //     })
      // })
      // .catch(error => {
      //   this.payLoading = false
      //   this.loading = false
      //   this.$message({ type: 'error', message: error, duration: 2000, showClose: true })
      // })
    },

    queryPrintData(invoicesNo) {
      this.DoReceiptsModal = true
      let printer = this.$cache.local.get('printerModel')
      let printCount = this.$cache.local.get('retailReceiptCount')
      let printCode = this.$cache.local.get('retailReceiptCode')
      let params = {
        sn: invoicesNo
      }
      this.webViewUrl = `${
        request.printPreviewRoot
      }?printerName=${printer}&templateCode=${printCode}&printCount=0&data=${encodeURI(JSON.stringify(params))}`
      let eggRootUrl = `${request.printEggPreviewRoot}?printerName=${printer}&templateCode=${printCode}&`
      const eggSrc =
        eggRootUrl + `printCount=${printCount}&data=${encodeURI(JSON.stringify(params))}&sn=${params['sn']}`
      ipc.invoke(ipcApiRoute.print, { contentUrl: eggSrc, sn: params['sn'] }).then(r => {})
    },

    getState() {
      //订单生成成功回调重置首页一些数据
      this.tableList = []
      this.activeList = []
      this.activityInfo = []
      if (this.openState === 'N') {
        this.shopGuide = ''
        this.guiderList = []
      }
      this.reduceResult = {}
      this.curVip = null
      this.nedTypeList = []
    },
    queryBuyMember() {
      let printInfo = this.memberValue.trim()
      if (!printInfo) {
        this.memberValue = ''
        return
      }
      printQueryVipInfo({
        printInfo: printInfo
      })
        .then(res => {
          this.memberValue = ''
          if (res) {
            this.curVip = res[0]
          }
        })
        .catch(error => {
          this.memberValue = ''
          this.$nextTick(() => {
            this.$refs.memberInput.focus()
          })
          this.$message({ type: 'error', message: error, duration: 2000, showClose: true })
        })
    },
    changeState() {
      if (this.openState === 'N') {
        openPublicOrderValid()
          .then(res => {
            this.shopGuide = ''
            this.guiderList = []
            this.settingPublic = true
          })
          .catch(error => {
            this.$message({ type: 'error', message: error, duration: 2000, showClose: true })
          })
      }
      if (this.openState === 'Y') {
        this.$confirm('确认关闭公单', '提示')
          .then(res => {
            closePublicOrder()
              .then(res => {
                this.openState = 'N'
                this.shopGuide = ''
                this.guiderList = []
                this.queryGuide()
                this.$message({ type: 'success', message: '店铺公单已关闭', duration: 2000, showClose: true })
              })
              .catch(error => {
                this.$message({ type: 'error', message: error, duration: 2000, showClose: true })
              })
          })
          .catch(() => {})
      }
    },
    cancelOpen() {
      closePublicOrder()
        .then(res => {
          this.openState = 'N'
          this.shopGuide = ''
          this.guiderList = []
        })
        .catch(error => {
          this.$message({ type: 'error', message: error, duration: 2000, showClose: true })
        })
    },
    sureOpen(e) {
      this.queryGuide()
      this.joinUser = e
      this.openState = 'Y'
      this.settingPublic = false
    }
  }
}
</script>

<style lang="scss" scoped>
.content-main {
  width: calc(100vw - 430px);
  margin: 0 10px 10px;
  .main-info {
    margin-top: 10px;
    background: #ffffff;
    height: calc(100vh - 160px);
    width: calc(100vw - 430px);
    padding: 10px;
  }

  .bottom-tools {
    font-size: 14px;
    background: #fff;
    height: 80px;
    .active-list {
      max-width: calc(100vw - 500px);
      overflow: auto;
      .active-item {
        border: 1px solid #f9f9f9;
        background: #f9f9f9;
        border-radius: 8px;
        padding: 10px;
        width: 250px;
        position: relative;
        margin-left: 20px;
        margin-right: 10px;
        cursor: pointer;
        .active-type {
          background: #deeef9;
          width: 40px;
          height: 40px;
          border-radius: 4px;
          text-align: center;
          color: #067cf2;
          margin-right: 10px;
          margin-left: 15px;
          font-size: 12px;
        }
        .active-title {
          width: 160px;
          > div {
            color: #000;
            font-weight: bold;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          p {
            margin-top: 5px;
            span {
              color: #067cf2;
              cursor: pointer;
            }
          }
        }
        .active-flag {
          position: absolute;
          width: 30px;
          height: 30px;
          border-radius: 4px;
          background: #ebebeb;
          left: -15px;
          top: 15px;
        }
        .check-state {
          position: absolute;
          left: 50%;
          transform: translate(50%);
          border: 1px solid red;
        }
      }
    }

    .action-btn {
      .btn {
        line-height: 40px;
        border-radius: 4px;
        width: 90px;
        margin-right: 10px;
        cursor: pointer;
        font-size: 18px;
        color: #fff;
        text-align: center;
      }
      .btn-one {
        box-shadow: 5px 5px 10px 0px rgba(255, 144, 0, 0.38);
        background: #ff9000;
        padding: 0;
        color: #fff;
        border: none;
      }
      .btn-two {
        box-shadow: 5px 5px 10px 0px rgba(6, 124, 242, 0.38);
        background: #067cf2;
      }
      .btn-thr {
        box-shadow: 5px 5px 10px 0px rgba(248, 71, 95, 0.38);
        background: #f8475f;
      }
    }
  }
}

.content-aside {
  flex: 1;
  margin-right: 10px;
  margin-top: 10px;
  .tab-type {
    margin-bottom: 10px;
    font-size: 16px;
    box-shadow: 0px 2px 10px 0px rgba(6, 124, 242, 0.21);
    background: #fff;
    border-radius: 4px;
    padding: 5px 10px;
    width: 200px;
    cursor: pointer;
    div {
      width: calc(100% / 2);
      background: #fff;
      text-align: center;
      border-radius: 4px;
      padding: 5px 0;
    }
  }

  .aside-scan {
    box-shadow: 0px 2px 10px 0px rgba(6, 124, 242, 0.21);
    background: #fff;
    border-radius: 4px;
    color: #c56209;
    height: 50px;
    position: relative;
    border: 2px solid #fff;
    i {
      font-size: 30px;
      margin: 0 10px;
    }
    span {
      width: 1px;
      height: 20px;
      background: #c56209;
      margin-right: 10px;
    }
    input {
      background: none;
      outline: none;
      font-size: 20px;
      color: #067cf2;
      width: 100%;
      margin-right: 10px;
      line-height: 48px;
      border: none;
      font-family: 'font-Medium';
    }
    input::-webkit-input-placeholder {
      color: #067cf2;
    }
    input:focus {
      color: #c56209;
    }
    input:focus::-webkit-input-placeholder {
      color: #c56209;
    }
    .scan-tip {
      position: absolute;
      right: 0;
      top: -50px;
      width: 170px;
      background: #fff;
      box-shadow: 0px 2px 10px 0px rgba(6, 124, 242, 0.21);
      font-size: 12px;
      color: #067cf2;
      padding: 10px 5px;
      border-radius: 4px;
      p {
        color: #fff;
        position: absolute;
        left: 20px;
        bottom: -12px;
        font-size: 20px;
      }
    }
  }

  .aside-goodsno {
    font-family: 'font-Light';
    margin-top: 10px;
    .goods-input {
      position: relative;
      input {
        width: 150px;
        border: none;
        outline: none;
        background: #fff;
        box-shadow: 0px 2px 10px 0px rgba(6, 124, 242, 0.21);
        border-radius: 4px 0 0 4px;
        height: 36px;
        line-height: 36px;
        padding: 0 18px 0 10px;
        font-size: 16px;
        color: #067cf2;
      }
      input:-moz-placeholder {
        color: #067cf2;
      }
      input::-moz-placeholder {
        color: #067cf2;
      }
      input::-webkit-input-placeholder {
        color: #067cf2;
      }
      input:-ms-input-placeholder {
        color: #067cf2;
      }
      span {
        width: 60px;
        background: #067cf2;
        line-height: 36px;
        color: #fff;
        border-radius: 4px;
        margin-left: -10px;
        text-align: center;
        cursor: pointer;
        i {
          margin-right: 4px;
        }
      }
      .spu-card {
        position: absolute;
        left: 0;
        top: 38px;
        width: 266px;
        background: #fff;
        box-shadow: 0px -1px 6px 0px rgba(0, 0, 0, 0.3);
        color: #333;
        z-index: 99999;
        .spu-card-list {
          margin-top: 34px;
          max-height: 300px;
          overflow: auto;
          .spu-card-title {
            width: 100%;
            position: absolute;
            top: 0;
            z-index: 9;
            background: #eef2fb;
            line-height: 30px;
            font-family: 'font-Regular';
            div {
              width: 130px;
              text-align: center;
              color: #999;
            }
          }
        }
        .spu-list {
          border-bottom: 1px solid #f5f5f5;
          width: 260px;
          line-height: 30px;
          cursor: pointer;
          &:hover {
            background: #eff7ff;
          }
          div {
            width: 130px;
            text-align: center;
            overflow: hidden;
            white-space: nowrap;
          }
        }
      }
    }
    .goods-attr {
      background: #ffffff;
      box-shadow: 0px 2px 10px 0px rgba(6, 124, 242, 0.21);
      border-radius: 4px;
      line-height: 36px;
      position: relative;
      width: 90px;
      margin-left: 10px;
      color: #067cf2;
      > i {
        color: #000;
        font-weight: bold;
      }
      .list-card {
        position: absolute;
        top: 38px;
        left: 0;
        width: 90px;
        box-shadow: 0px -1px 6px 0px rgba(0, 0, 0, 0.3);
        background: #fff;
        border-radius: 4px;
        text-align: center;
        z-index: 9999;
        > div {
          border-bottom: 1px solid #eee;
          cursor: pointer;
          &:last-child {
            border-bottom: none;
          }
          &:hover {
            background: #eff7ff;
          }
        }
      }
    }
  }

  .stock-sales {
    margin-top: 10px;
    div {
      width: calc((100% - 30px) / 4);
      box-shadow: 0px 3px 8px 0px rgba(204, 204, 204, 0.54);
      border-radius: 4px;
      background: #d2e5fe;
      height: 60px;
      cursor: pointer;
      margin-bottom: 10px;
      margin-right: 10px;
      p {
        font-size: 16px;
        color: #08397c;
        margin-top: 10px;
      }
      span {
        font-size: 14px;
        color: #fff;
      }
    }
    .none-margin-right {
      margin-right: 0;
    }
  }

  .ned-class {
    font-size: 14px;
    padding: 10px;
    padding-top: 5px;
    background-color: white;
    border: 1px solid #ffd591;
    margin-bottom: 10px;
    .top {
      display: flex;
      align-items: center;
      font-weight: 600;
      img {
        width: 25px;
        height: 25px;
        margin-right: 5px;
      }
    }
    .center {
      margin: 5px 0;
      margin-bottom: 10px;
    }
    .bottom {
    }
  }

  .sales-ording {
    font-size: 14px;
    .item-card {
      width: calc((100% - 10px) / 2);
      position: relative;
      .item-value {
        box-shadow: 0px 2px 10px 0px rgba(6, 124, 242, 0.21);
        border-radius: 4px;
        height: 32px;
        line-height: 32px;
        width: 100%;
        background: #fff;
        padding: 0 10px;
        i {
          color: #999;
        }
        input {
          width: 100%;
          line-height: 30px;
          height: 32px;
          outline: none;
          border: none;
          background: none;
        }
      }
      .item-options {
        position: absolute;
        top: 34px;
        left: 0;
        background: #fff;
        border-radius: 4px;
        box-shadow: 0px 2px 10px 0px rgba(6, 124, 242, 0.21);
        width: 100%;
        z-index: 9;
        div {
          line-height: 34px;
          padding: 0 5px;
          cursor: pointer;
          &:hover {
            background: #eff7ff;
          }
        }
      }
      .user-option {
        left: 0;
        width: 100%;
        max-height: 300px;
        overflow: auto;
        background: #fff;
        .search-user {
          padding: 10px 5px;
          border-bottom: 1px solid #f5f5f5;
          cursor: pointer;
          .user-avat {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            margin-right: 10px;
            .image {
              width: 40px;
              height: 40px;
              border-radius: 50%;
              float: left;
              margin-right: 10px;
            }
            > div {
              color: #fff;
              font-size: 20px;
              width: 40px;
              height: 40px;
              border-radius: 50%;
              background: #067cf2;
              text-align: center;
              line-height: 40px;
            }
          }
          .user-data {
            height: 40px;
            line-height: 20px;
          }
        }
      }
    }
    .show-allshop {
      line-height: 32px;
      border-radius: 4px;
      background: #067cf2;
      color: #fff;
      text-align: center;
      cursor: pointer;
      box-shadow: 0px 2px 10px 0px rgba(6, 124, 242, 0.21);
    }
  }

  .ganger-user {
    font-size: 14px;
    margin-top: 10px;
    color: #999;
    line-height: 24px;
  }

  .team-card {
    background: #fef3e4;
    border: 1px solid #ffffff;
    border-radius: 4px;
    margin-top: 10px;
    height: 40px;
    line-height: 40px;
    padding: 0 10px;
    .show-user {
      border-radius: 4px;
      font-size: 14px;
      background: rgb(255, 213, 145);
      line-height: 26px;
      padding: 0 10px;
      cursor: pointer;
    }
  }

  .team-card-old {
    background: #fef3e4;
    border: 1px solid #ffffff;
    border-radius: 4px;
    margin-top: 10px;
    padding: 5px;
    > p {
      margin-bottom: 8px;
      i {
        color: orange;
        font-size: 20px;
        font-weight: bold;
        cursor: pointer;
      }
    }
    .team-item {
      span {
        &:first-child {
          margin-bottom: 5px;
          color: #000;
          font-weight: bold;
        }
        &:last-child {
          color: #999;
        }
      }
      i {
        width: 1px;
        height: 25px;
        background: #f3ddc0;
      }
    }
  }

  .vip-card {
    font-size: 14px;
    background: #fef3e4;
    border: 1px solid #ffffff;
    border-radius: 4px;
    margin-top: 10px;
    height: 54px;
    p {
      span {
        &:first-child {
          color: #492a00;
          font-size: 16px;
          font-weight: bold;
        }
        &:last-child {
          color: #999;
          margin-top: 5px;
        }
      }
    }
    > i {
      width: 1px;
      height: 25px;
      background: #f3ddc0;
    }
    > span {
      border: 1px solid #ff9f52;
      color: #ff9f52;
      border-radius: 4px;
      padding: 0 4px;
      cursor: pointer;
    }
    .vip-default {
      height: 40px;
      line-height: 40px;
      border: 1px solid orange;
      border-radius: 2px;
      padding-left: 10px;
      font-size: 16px;
      background: #fff;
      margin-left: 10px;
      > i {
        font-size: 20px;
        font-weight: bold;
        color: orange;
      }
      > input {
        width: 190px;
        line-height: 36px;
        height: 36px;
        outline: none;
        margin-left: 10px;
        font-size: 16px;
        float: right;
        border: none;
        margin-top: 2px;
        background: none;
        color: orange;
        padding-right: 10px;
      }
      input::-webkit-input-placeholder {
        color: orange;
      }
      input:focus {
        color: #c56209;
      }
      input:focus::-webkit-input-placeholder {
        color: #c56209;
      }
    }
    .vip-tip-text {
      margin-left: 10px;
      color: orange;
    }
  }

  .about-price {
    > div {
      margin-bottom: 10px;
      span {
        color: #999;
        font-size: 16px;
        margin-top: 14px;
      }
    }
    p {
      span {
        color: #f8475f;
        &:first-child {
          font-size: 16px;
        }
        &:nth-child(2) {
          font-size: 36px;
        }
      }
      a {
        font-size: 16px;
        color: #067cf2;
        border-bottom: 1px solid #067cf2;
        float: right;
        margin-top: 30px;
        margin-left: 10px;
      }
    }
  }

  .aside-end {
    margin-top: -25px;
    color: #333;
    .aside-finaly {
      cursor: pointer;
      color: #fff;
      position: relative;
    }
    .jiesuan {
      position: absolute;
      top: 32px;
      left: 50%;
      margin-left: -26px;
      font-size: 20px;
      span {
        margin-right: 8px;
      }
      p {
        font-size: 14px;
        color: #ccc;
      }
    }
  }
}
</style>

<style>
.main-info .table-wrap {
  height: calc(100vh - 180px) !important;
}
.main-info .highlight-bg td {
  background: #fff8ef !important;
}
.main-info tbody tr[gm-cache-key='0'] td {
  border-top: 3px dashed #067cf2 !important;
  border-bottom: 3px dashed #067cf2 !important;
}
.main-info .gm-remind-action i {
  left: 56px;
  color: #000;
}
</style>
