<template>
  <section>
    <dialogModule
      width="92%"
      top="10"
      title="会员"
      v-if="changeVisiable"
      :dialogVisible="changeVisiable"
      :closeOnPressEscape="false"
      bgColor="#fff"
      @close="close">
      <span slot="close" class="el-icon-close close" @click="close"></span>
      <div slot="change" class="row top-btn-box align-center">
        <div class="btn-item" @click="createVipDialog = true">创建会员</div>
        <div class="btn-item" @click="vipRechargeType = true">查看本店会员充值记录</div>
        <div class="btn-item" @click="integralVisible = true">积分兑换</div>
        <div class="btn-item" @click="imgVisible = true">查看店铺会员二维码</div>
      </div>
      <div slot="content" class="row space-between content-box" @click="searcListModule = false">
        <section class="vip-card">
          <div class="row align-center space-between vip-search">
            <div class="row align-center">
              <section @click.stop="searcListModule = !searcListModule">
                <span>{{ curSearchItem.title }}</span>
                <i class="el-icon-caret-bottom"></i>
                <div class="search-list" v-if="searcListModule">
                  <div v-for="item in searchList" :key="item.id" @click="curSearchItem = item">
                    {{ item.title }}
                  </div>
                </div>
              </section>
              <span class="line"></span>
              <i class="el-icon-search icon-sousuo"></i>
              <input
                type="text"
                :placeholder="'请输入' + curSearchItem.title"
                v-model="keyValue"
                @keyup.enter="reFetchVip" />
            </div>
            <el-button class="btn" @click="reFetchVip">搜 索</el-button>
          </div>
          <div class="searchBy row align-center">
            <span> 快捷搜索： </span>
            <div class="row align-center">
              <span :class="monthType ? 'btDays' : 'btDay'" @click="queryBirthVip">本月过生日的会员</span>
            </div>
          </div>
          <grid-manager :option="vipConfig"></grid-manager>
        </section>
        <section class="current-member">
          <template v-if="curRow">
            <div class="current-top">
              <div class="img-zuanshi">
                <img src="@/assets/images/Recharge(1).png" />
              </div>
              <div class="user-info">
                <el-image v-if="curRow.avatar" class="vip-avatar" :src="curRow.avatar"></el-image>
                <div v-else class="vip-avatar row align-center space-center">
                  <i class="el-icon-user-solid"></i>
                </div>
                <div class="member-data">
                  <div class="row align-center">
                    <span>{{ curRow.nickName }}</span>
                    <img
                      :src="
                        curRow.sex === '0' ? require('@/assets/images/girl.png') : require('@/assets/images/boy.png')
                      " />
                  </div>
                  <span>手 机 号：{{ curRow.phone.substr(0, 3) + '****' + curRow.phone.substr(7) }}</span>
                </div>
              </div>
              <div class="member-other row align-center space-between">
                <div class="row align-center text-center">
                  <div class="other-item column space-center">
                    <span>可用余额</span>
                    <p>¥{{ curRow.accountBalance || '0.00' }}</p>
                  </div>
                  <div class="other-item column space-center">
                    <span>占用余额</span>
                    <p>{{ curRow.disableBalance || '0' }}</p>
                  </div>
                  <div class="other-item column space-center">
                    <span>积分</span>
                    <p>{{ curRow.integralBalance || '0' }}</p>
                  </div>
                </div>
                <div class="recharge-btn" @click="rechargeMoney">余额充值</div>
              </div>
            </div>
            <div class="vip-form">
              <div class="form-label row align-center">
                <span></span>
                <div>会员信息</div>
              </div>
              <el-form>
                <el-row class="row align-center space-between vip-row">
                  <div class="form-left row align-center">
                    <span>会员昵称:</span>
                    <input v-model="curRow.nickName" class="onlyRead int" disabled />
                  </div>
                  <div class="form-left row align-center">
                    <span>真实姓名:</span>
                    <input v-model="curRow.realName" class="onlyRead int" disabled />
                  </div>
                </el-row>
                <el-row class="row align-center space-between vip-row">
                  <div class="form-left row align-center">
                    <span>手机号:</span>
                    <input
                      class="onlyRead int"
                      :value="curRow.phone.substr(0, 3) + '****' + curRow.phone.substr(7)"
                      disabled="true" />
                  </div>
                  <div class="form-left row align-center">
                    <span>性别:</span>
                    <input class="onlyRead int" v-model="curRow.sexName" disabled="true" />
                  </div>
                </el-row>
                <el-row class="row align-center space-between vip-row">
                  <div class="form-left row align-center">
                    <span>绑定店铺:</span>
                    <input class="onlyRead int" v-model="curRow.deptName" disabled="true" />
                  </div>
                  <div class="form-left row align-center">
                    <span>发卡导购:</span>
                    <input class="onlyRead int" v-model="curRow.assistantName" disabled="true" />
                  </div>
                </el-row>
                <el-row class="row align-center space-between vip-row">
                  <div class="form-left row align-center">
                    <span>生日:</span>
                    <input class="onlyRead int" v-model="curRow.birthday" disabled="true" />
                  </div>
                  <div class="form-left row align-center">
                    <span>身份证号:</span>
                    <input class="onlyRead int" v-model="curRow.idNum" disabled="true" />
                  </div>
                </el-row>
                <el-row class="row align-center space-between vip-row">
                  <div class="form-left row align-center">
                    <span>上衣尺码:</span>
                    <input class="int inp" v-model="curRow.coatSize" />
                  </div>
                  <div class="form-left row align-center">
                    <span>裤子尺码:</span>
                    <input class="int inp" v-model="curRow.trousersSize" />
                  </div>
                </el-row>
                <el-row class="row align-center space-between vip-row">
                  <div class="form-left row align-center">
                    <span>鞋子尺码:</span>
                    <input class="int inp" v-model="curRow.shoeSize" />
                  </div>
                  <div class="form-left row align-center">
                    <span>会员卡号:</span>
                    <input class="onlyRead int" v-model="curRow.cardNumber" disabled="true" />
                  </div>
                </el-row>
                <el-row class="row align-center space-between vip-row">
                  <div class="form-left row align-center">
                    <span>件单价:</span>
                    <input class="onlyRead int" v-model="curRow.averagePrice" disabled="true" />
                  </div>
                  <div class="form-left row align-center">
                    <span>平均消费:</span>
                    <input class="onlyRead int" v-model="curRow.averageAmount" disabled="true" />
                  </div>
                </el-row>
              </el-form>
            </div>
            <div class="save-member" @click="sureSelected">
              <p>保存会员信息</p>
              <span>CTRL+F5</span>
            </div>
          </template>
          <noDataState v-else marginTop="300" textDesc="请选择会员"></noDataState>
        </section>
        <section class="record-info">
          <div class="record-tab row">
            <div
              class="items-records"
              v-for="(item, index) in vipRecord"
              :key="item.value"
              @click="isType(index, item.value)">
              {{ item.label }}
            </div>
            <div class="items-box">消费记录</div>
          </div>
          <div class="search-records row flex-wrap">
            <div class="search-div" v-if="vipRecordType === '2'">
              类型&nbsp;
              <el-select clearable style="width: 150px" v-model="queryParams.type" size="small">
                <el-option
                  v-for="(item, index) in amountTypeList"
                  :key="index"
                  :label="item.label"
                  :value="item.id"></el-option>
              </el-select>
            </div>
            <div class="search-div" v-if="vipRecordType === '3'">
              类型&nbsp;
              <el-select clearable style="width: 150px" v-model="queryParams.type" size="small">
                <el-option
                  v-for="(item, index) in typeItemList"
                  :key="index"
                  :label="item.label"
                  :value="item.id"></el-option>
              </el-select>
            </div>
            <div class="search-div" v-show="vipRecordType === '1'">
              单号&nbsp;
              <el-input
                style="width: 150px"
                clearable
                placeholder="请输入单号"
                type="index"
                size="small"
                v-model="queryParams.billSn"></el-input>
            </div>
            <div class="search-div" v-show="vipRecordType === '4'">
              货号&nbsp;
              <el-input
                clearable
                style="width: 166px"
                placeholder="多个货号用空格隔开"
                type="index"
                size="small"
                v-model="queryParams.goodsNoList"></el-input>
            </div>
            <div class="search-div">
              {{ vipRecordType === '4' ? '兑换时间' : '时间范围' }}&nbsp;
              <el-date-picker
                size="small"
                style="width: 240px"
                v-model="queryParams.timer"
                type="daterange"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                range-separator="-"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
              </el-date-picker>
            </div>
            <div v-show="vipRecordType === '3' || vipRecordType === '2'">
              <div class="search-div">
                单号&nbsp;
                <el-input
                  clearable
                  style="width: 150px"
                  placeholder="请输入单号"
                  type="index"
                  size="small"
                  v-model="queryParams.billSn"></el-input>
              </div>
            </div>
            <div class="row search-div">
              <el-button class="reset" size="small" @click="resetSearch">重置</el-button>
              <el-button class="search" size="small" @click="searchRecharge">确定</el-button>
            </div>
          </div>
          <div v-show="vipRecordType === '1'">
            <grid-manager :option="consumeConfig"></grid-manager>
          </div>
          <div v-show="vipRecordType === '2'">
            <grid-manager :option="balanceConfig"></grid-manager>
          </div>
          <div v-show="vipRecordType === '3'">
            <grid-manager :option="integralConfig"></grid-manager>
          </div>
          <div v-show="vipRecordType === '4'">
            <grid-manager :option="gridOption"></grid-manager>
          </div>
        </section>
      </div>
    </dialogModule>
    <vipRecharge v-if="vipRechargeType" @close="vipRechargeType = false"></vipRecharge>
    <CreateVip v-if="createVipDialog" @close="closeCreate"></CreateVip>
    <MemberRecharge v-if="rechargeDialog" @close="closeCharge" :curRow="curRow"></MemberRecharge>
    <IntegralDialog
      v-if="integralVisible"
      @close="integralVisible = false"
      @refresh="refreshList"
      :curVip="curVip"></IntegralDialog>

    <!-- 二维码 -->
    <el-dialog title="查看店铺会员二维码" :visible.sync="imgVisible" width="30%" :before-close="handleClose">
      <div style="width: 100%; height: 100%; display: flex; flex-direction: column; align-items: center">
        <img style="width: 260px; height: 260px; margin-bottom: 40px" :src="imgUrl" alt="" />
        <div
          style="text-decoration: underline; color: #2396ff; font-size: 18px; font-weight: 400"
          @click="handDown(imgUrl)">
          下载图片
        </div>
      </div>
    </el-dialog>

    <el-dialog title="提示" :visible.sync="effectStateDialog" width="18%" :before-close="effectStateClose">
      <span style="font-size: 16px"
        >抱歉，该会员资格暂时受到限制，无法使用，我们建议您联系IT了解具体情况并解决问题。</span
      >
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="effectStateDialog = false">确 定</el-button>
      </span>
    </el-dialog>

    <el-image-viewer class="image-view" v-if="showViewer" :on-close="closeViewer" :url-list="imageList" />
  </section>
</template>

<script>
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
import IntegralDialog from './IntegralDialog.vue'
import moment from 'moment'
import Mousetrap from 'mousetrap'
import 'mousetrap/plugins/global-bind/mousetrap-global-bind'
import CreateVip from './CreateVip'
import MemberRecharge from './MemberRecharge'
import vipRecharge from './VipRecharge.vue'
import dialogModule from '@/components/dialogModule.vue'
import noDataState from '@/components/noDataState.vue'
import { vipListConfig } from '../listConfig'
import {
  queryMemberList,
  crmVipDetail,
  crmVipQueryConsume,
  vipAccountBalance,
  vipAccountIntegral,
  vipSaveVipUserInfo,
  queryExchangeRecordList,
  crmVipGetWxQrcode,
  querySceneTypeList,
  querySecondActionType
} from '@/libs/http/modules/vip'
import { ipc } from '@/utils/ipcRenderer'
import { ipcApiRoute } from '@/api/main'
export default {
  name: 'VipDialog',
  props: ['curVip'],
  components: {
    dialogModule,
    noDataState,
    CreateVip,
    MemberRecharge,
    vipRecharge,
    IntegralDialog,
    ElImageViewer
  },
  data() {
    return {
      typeItemList: [],
      amountTypeList: [],
      effectStateDialog: false,
      imgUrl: '',
      imgVisible: false,
      imageList: [],
      showViewer: false,
      vipConfig: {
        gridManagerName: 'memberList',
        firstLoading: true,
        isCombSorting: true,
        supportCheckbox: false,
        supportAutoOrder: false,
        lineHeight: '30px',
        useTrFocus: true,
        columnData: vipListConfig,
        ajaxData: this.queryVipList,
        supportAjaxPage: true,
        rowClick: this.rowClick,
        height: '700px',
        ajaxPageTemplate: `
                    <div class="gm-toolbar" {{vm.keyName}}="{{vm.gridManagerName}}">
                        <!--每页显示条数-->
                        <div class="change-size">
                            {{ vm.pageSizeOptionTpl }}
                        </div>
                        <!--分页描述-->
                        <div class="toolbar-info page-info"></div>
                        <!--分页切换-->
                        <div class="pagination">
                            <!--上一页、首页区-->
                            <ul pagination-before>
                                <li class="previous-page">
                                    {{ vm.previousPageText }}
                                </li>
                            </ul>
                            <!--下一页、尾页区-->
                            <ul pagination-after>
                                <li class="next-page">
                                    {{ vm.nextPageText }}
                                </li>
                            </ul>
                        </div>
                    </div>
                `
      },
      consumeConfig: {
        gridManagerName: 'consumeList',
        firstLoading: true,
        isCombSorting: true,
        supportCheckbox: false,
        supportAutoOrder: false,
        lineHeight: '30px',
        useTrFocus: true,
        columnData: [
          {
            key: 'innerSn',
            text: '单号',
            width: '220px',
            template: () => {
              return `
                                <div @click="openNew(row.innerSn, 'xf', row.marketId)">
                                    <span style="color:#067cf2;text-decoration: underline;cursor: pointer;">{{ row.innerSn }}</span>
                                </div>
                            `
            }
          },
          { key: 'createTime', text: '时间', width: '170px' },
          { key: 'totalNumber', text: '件数' },
          { key: 'realPrice', text: '金额' }
        ],
        ajaxData: this.fetchConsumeList,
        supportAjaxPage: true,
        height: '600px',
        ajaxPageTemplate: `<div class="gm-toolbar" {{vm.keyName}}="{{vm.gridManagerName}}">
                    <!--每页显示条数-->
                    <div class="change-size">
                        {{ vm.pageSizeOptionTpl }}
                    </div>
                    <!--分页描述-->
                    <div class="toolbar-info page-info"></div>
                    <!--分页切换-->
                    <div class="pagination">
                        <!--上一页、首页区-->
                        <ul pagination-before>
                            <li class="previous-page">
                                {{ vm.previousPageText }}
                            </li>
                        </ul>
                        <!--下一页、尾页区-->
                        <ul pagination-after>
                            <li class="next-page">
                                {{ vm.nextPageText }}
                            </li>
                        </ul>
                    </div>
                </div>`
      },
      balanceConfig: {
        gridManagerName: 'balanceList',
        firstLoading: true,
        isCombSorting: true,
        supportCheckbox: false,
        supportAutoOrder: false,
        lineHeight: '30px',
        useTrFocus: true,
        columnData: [
          {
            key: 'actionDesc',
            text: '类型',
            width: '55px'
          },
          {
            key: 'billSn',
            text: '单号',
            width: '220px',
            template: () => {
              return `
                                <div @click="openNew(row.billSn, 'ye', row.erpMarketingUnitId)">
                                    <span style="color:#067cf2;text-decoration: underline;cursor: pointer;">{{ row.billSn }}</span>
                                </div>
                            `
            }
          },
          { key: 'createTime', text: '时间', width: '170px' },
          { key: 'lastBalance', text: '交易前' },
          { key: 'balance', text: '本次' },
          { key: 'afterBalance', text: '交易后' }
        ],
        ajaxData: this.fetchBalance,
        supportAjaxPage: true,
        height: '600px',
        ajaxPageTemplate: `<div class="gm-toolbar" {{vm.keyName}}="{{vm.gridManagerName}}">
                    <!--每页显示条数-->
                    <div class="change-size">
                        {{ vm.pageSizeOptionTpl }}
                    </div>
                    <!--分页描述-->
                    <div class="toolbar-info page-info"></div>
                    <!--分页切换-->
                    <div class="pagination">
                        <!--上一页、首页区-->
                        <ul pagination-before>
                            <li class="previous-page">
                                {{ vm.previousPageText }}
                            </li>
                        </ul>
                        <!--下一页、尾页区-->
                        <ul pagination-after>
                            <li class="next-page">
                                {{ vm.nextPageText }}
                            </li>
                        </ul>
                    </div>
                </div>`
      },
      integralConfig: {
        gridManagerName: 'integralList',
        firstLoading: true,
        isCombSorting: true,
        supportCheckbox: false,
        supportAutoOrder: false,
        lineHeight: '30px',
        useTrFocus: true,
        columnData: [
          {
            key: 'scene',
            text: '类型',
            width: '55px'
          },
          {
            key: 'billSn',
            text: '单号',
            width: '220px',
            template: () => {
              return `
                                <div @click="openNew(row.billSn, 'jf', row.erpMarketingUnitId, row.scene)">
                                    <span style="color:#067cf2;text-decoration: underline;cursor: pointer;">{{ row.billSn }}</span>
                                </div>
                            `
            }
          },
          { key: 'createTime', text: '时间', width: '170px' },
          { key: 'lastBalance', text: '交易前' },
          { key: 'balance', text: '本次' },
          { key: 'afterBalance', text: '交易后' }
        ],
        ajaxData: this.fetchIntegralList,
        supportAjaxPage: true,
        height: '600px',
        ajaxPageTemplate: `<div class="gm-toolbar" {{vm.keyName}}="{{vm.gridManagerName}}">
                    <!--每页显示条数-->
                    <div class="change-size">
                        {{ vm.pageSizeOptionTpl }}
                    </div>
                    <!--分页描述-->
                    <div class="toolbar-info page-info"></div>
                    <!--分页切换-->
                    <div class="pagination">
                        <!--上一页、首页区-->
                        <ul pagination-before>
                            <li class="previous-page">
                                {{ vm.previousPageText }}
                            </li>
                        </ul>
                        <!--下一页、尾页区-->
                        <ul pagination-after>
                            <li class="next-page">
                                {{ vm.nextPageText }}
                            </li>
                        </ul>
                    </div>
                </div>`
      },
      gridOption: {
        gridManagerName: 'integralExchange',
        firstLoading: true,
        isCombSorting: true,
        supportCheckbox: false,
        supportAutoOrder: false,
        lineHeight: '30px',
        useTrFocus: true,
        columnData: [
          {
            key: 'goodsNo',
            text: '货号',
            width: '120px',
            template: () => {
              return `
                                <div style="color:#067cf2;text-decoration: underline;cursor: pointer;" @click="openView(row)">{{ row.goodsNo }}</div>
                            `
            }
          },
          { key: 'goodsName', text: '货品名称', width: '120px' },
          { key: 'integralPrice', text: '所需积分', width: '90px' },
          { key: 'exchangeNum', text: '兑换数量', width: '90px' },
          { key: 'consumeIntegral', text: '消耗总积分', width: '100px' },
          { key: 'exchangeMarket', text: '兑换店铺', width: '100px' },
          { key: 'exchangeTime', text: '兑换时间', width: '170px' }
        ],
        ajaxData: this.fetchChange,
        supportAjaxPage: true,
        height: '600px',
        ajaxPageTemplate: `<div class="gm-toolbar" {{vm.keyName}}="{{vm.gridManagerName}}">
                    <!--每页显示条数-->
                    <div class="change-size">
                        {{ vm.pageSizeOptionTpl }}
                    </div>
                    <!--分页描述-->
                    <div class="toolbar-info page-info"></div>
                    <!--分页切换-->
                    <div class="pagination">
                        <!--上一页、首页区-->
                        <ul pagination-before>
                            <li class="previous-page">
                                {{ vm.previousPageText }}
                            </li>
                        </ul>
                        <!--下一页、尾页区-->
                        <ul pagination-after>
                            <li class="next-page">
                                {{ vm.nextPageText }}
                            </li>
                        </ul>
                    </div>
                </div>`,
        summaryHandler: data => {
          let list = ['exchangeNum', 'consumeIntegral']
          let obj = {
            goodsNo: '本页汇总'
          }
          list.forEach(key => {
            if (key === 'exchangeNum') {
              obj[key] = data.reduce((c, R) => c + Number(R[key] || 0), 0)
            } else {
              obj[key] = data.reduce((c, R) => c + Number(R[key] || 0), 0).toFixed(2)
            }
          })
          return obj
        }
      },
      integralVisible: false,
      salesUnitId: '',
      monthType: false,
      keyValue: '',
      queryParams: {
        type: '',
        timer: '',
        billSn: '',
        goodsNoList: ''
      },
      vipRecord: [
        { label: '消费记录', value: '1' },
        { label: '余额明细', value: '2' },
        { label: '积分明细', value: '3' },
        { label: '积分兑换记录', value: '4' }
      ],
      vipRecordType: '1',
      changeVisiable: false,
      curRow: null,
      searcListModule: false,
      curSearchItem: null,
      searchList: [
        { id: '1', title: '手机号' },
        { id: '2', title: '会员名称' },
        { id: '3', title: '卡号' }
      ],
      createVipDialog: false,
      rechargeDialog: false,
      vipRechargeType: false,
      currentLine: null
    }
  },
  created() {
    this.curSearchItem = this.searchList[0]
    this.changeVisiable = true
    this.quicklyCode()
    this.salesUnitId = this.$cache.local.get('marketingUnitId')
    crmVipGetWxQrcode()
      .then(data => {
        if (data) {
          this.imgUrl = data
        }
      })
      .catch(error => {
        this.$message({ type: 'error', message: error, duration: 2000, showClose: true })
      })
    querySceneTypeList({})
      .then(data => {
        if (data) {
          this.typeItemList = data.map(item => {
            return {
              label: item.name,
              id: item.code
            }
          })
        }
      })
      .catch(error => {
        this.$message({ type: 'error', message: error, duration: 2000, showClose: true })
      })

    querySecondActionType({})
      .then(data => {
        if (data) {
          this.amountTypeList = data.map(item => {
            return {
              label: item.name,
              id: item.code
            }
          })
        }
      })
      .catch(error => {
        this.$message({ type: 'error', message: error, duration: 2000, showClose: true })
      })
  },
  methods: {
    downloadPicture(imgSrc, name) {
      const image = new Image()
      // 解决跨域 Canvas 污染问题
      image.setAttribute('crossOrigin', 'anonymous')
      image.src = imgSrc
      image.onload = () => {
        const canvas = document.createElement('canvas')
        canvas.width = image.width
        canvas.height = image.height
        const context = canvas.getContext('2d')
        context.drawImage(image, 0, 0, image.width, image.height)
        canvas.toBlob(blob => {
          const url = URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.download = name || 'photo'
          a.href = url
          a.click()
          a.remove()
          URL.revokeObjectURL(url)
        })
      }
    },
    handDown(url) {
      this.downloadPicture(url, 'pic')
    },
    effectStateClose() {
      this.effectStateDialog = false
    },
    rechargeMoney() {
      if (this.currentLine && this.currentLine.effectState == '1') {
        this.effectStateDialog = true
      } else {
        this.rechargeDialog = true
      }
    },
    handleClose() {
      this.imgVisible = false
    },
    queryVipList(params) {
      //查询会员列表
      return new Promise(async (resolve, reject) => {
        try {
          let reqData = {
            page: params.pageData.cPage,
            pageSize: params.pageData.pSize
          }
          if (this.keyValue) {
            if (this.curSearchItem.id === '1') {
              reqData['phone'] = this.keyValue
            }
            if (this.curSearchItem.id === '2') {
              reqData['name'] = this.keyValue
            }
            if (this.curSearchItem.id === '3') {
              reqData['cardNumber'] = this.keyValue
            }
          }
          if (this.monthType) {
            reqData['birthdayMonth'] = moment().format('MM')
          }
          let data = await queryMemberList(reqData)
          const tableData = {
            data: data.items,
            totals: Number(data.totalItem) || 0
          }
          resolve(tableData)
        } catch (e) {
          this.$message({ type: 'error', message: e, duration: 2000, showClose: true })
          reject('data err')
        }
      })
    },
    queryBirthVip() {
      //查询过生日会员
      this.monthType = !this.monthType
      this.reFetchVip()
    },
    reFetchVip() {
      //刷新会员列表
      GridManager.refreshGrid(this.vipConfig.gridManagerName, true)
    },
    rowClick(row) {
      //会员列表行单击
      if (this.currentLine && this.currentLine.id === row.id) return
      this.currentLine = row
      this.getcurrentVip()
    },
    fetchConsumeList(params) {
      //消费记录
      if (!this.curRow) return
      return new Promise(async (resolve, reject) => {
        try {
          let reqData = {
            id: this.curRow.id,
            page: params.pageData.cPage,
            pageSize: params.pageData.pSize
          }
          if (this.queryParams.timer) {
            reqData['startTime'] = this.queryParams.timer[0]
            reqData['endTime'] = this.queryParams.timer[1]
          }
          if (this.queryParams.billSn) {
            reqData['billSn'] = this.queryParams.billSn
          }
          let data = await crmVipQueryConsume(reqData)
          const tableData = {
            data: data.items,
            totals: Number(data.totalItem) || 0
          }
          resolve(tableData)
        } catch (e) {
          this.$message({ type: 'error', message: e, duration: 2000, showClose: true })
          reject('data err')
        }
      })
    },
    fetchIntegralList(params) {
      //积分明细
      if (!this.curRow) return
      return new Promise(async (resolve, reject) => {
        try {
          let reqData = {
            id: this.curRow.id,
            page: params.pageData.cPage,
            pageSize: params.pageData.pSize
          }
          if (this.queryParams.timer) {
            reqData['startTime'] = this.queryParams.timer[0]
            reqData['endTime'] = this.queryParams.timer[1]
          }
          if (this.queryParams.type) {
            reqData['scene'] = this.queryParams.type
          }
          if (this.queryParams.billSn) {
            reqData['billSn'] = this.queryParams.billSn
          }
          let data = await vipAccountIntegral(reqData)
          const tableData = {
            data: data.items,
            totals: Number(data.totalItem) || 0
          }
          resolve(tableData)
        } catch (e) {
          this.$message({ type: 'error', message: e, duration: 2000, showClose: true })
          reject('data err')
        }
      })
    },
    fetchBalance(params) {
      //余额明细
      if (!this.curRow) return
      return new Promise(async (resolve, reject) => {
        try {
          let reqData = {
            id: this.curRow.id,
            page: params.pageData.cPage,
            pageSize: params.pageData.pSize
          }
          if (this.queryParams.timer) {
            reqData['startTime'] = this.queryParams.timer[0]
            reqData['endTime'] = this.queryParams.timer[1]
          }
          if (this.queryParams.type) {
            reqData['type'] = this.queryParams.type
          }
          if (this.queryParams.billSn) {
            reqData['billSn'] = this.queryParams.billSn
          }
          let data = await vipAccountBalance(reqData)
          const tableData = {
            data: data.items,
            totals: Number(data.totalItem) || 0
          }
          resolve(tableData)
        } catch (e) {
          this.$message({ type: 'error', message: e, duration: 2000, showClose: true })
          reject('data err')
        }
      })
    },
    fetchChange(params) {
      //积分兑换记录
      if (!this.curRow) return
      return new Promise(async (resolve, reject) => {
        try {
          let reqData = {
            page: params.pageData.cPage,
            pageSize: params.pageData.pSize,
            userId: this.curRow.id
          }
          if (this.queryParams.goodsNoList) {
            reqData['goodsNoList'] = this.queryParams.goodsNoList.split(' ')
          }
          if (this.queryParams.timer) {
            reqData['exchangeDates'] = this.queryParams.timer
          }
          if (this.queryParams.billSn) {
            reqData['billSn'] = this.queryParams.billSn
          }
          let data = await queryExchangeRecordList(reqData)
          const tableData = {
            data: data.items,
            totals: Number(data.totalItem) || 0
          }
          resolve(tableData)
        } catch (e) {
          this.$message({ type: 'error', message: e, duration: 2000, showClose: true })
          reject('data err')
        }
      })
    },
    getcurrentVip() {
      //获取当前会员信息
      this.resetSearch()
      const itemsBox = document.querySelector('.items-box')
      itemsBox.style.left = '0'
      itemsBox.textContent = '消费记录'
      this.vipRecordType = '1'
      let reqData = {
        id: this.currentLine.id
      }
      crmVipDetail(reqData)
        .then(data => {
          this.curRow = data
          if (this.curRow) {
            if (this.curRow.sex === '0') {
              this.$set(this.curRow, 'sexName', '女')
            } else if (this.curRow.sex === '1') {
              this.$set(this.curRow, 'sexName', '男')
            } else {
              this.$set(this.curRow, 'sexName', '未知')
            }
            if (!this.curRow.trousersSize) {
              this.curRow.trousersSize = ''
            }
            GridManager.refreshGrid(this.consumeConfig.gridManagerName, true)
          }
        })
        .catch(err => {
          this.$message({ type: 'error', message: err, duration: 2000, showClose: true })
        })
    },
    isType(index, type) {
      //右侧tab切换
      this.resetSearch()
      if (!this.curRow)
        return this.$message({ type: 'error', message: '请先选择会员', duration: 2000, showClose: true })
      if (type === this.vipRecordType) return
      this.vipRecordType = type
      const itemsBox = document.querySelector('.items-box')
      if (index === 0) {
        itemsBox.style.left = '0'
        itemsBox.textContent = '消费记录'
      }
      if (index === 1) {
        itemsBox.style.left = '25%'
        itemsBox.textContent = '余额明细'
      }
      if (index === 2) {
        itemsBox.style.left = '50%'
        itemsBox.textContent = '积分明细'
      }
      if (index === 3) {
        itemsBox.style.left = '75%'
        itemsBox.textContent = '积分兑换记录'
      }
      this.searchRecharge()
    },
    searchRecharge() {
      //按条件查询
      if (!this.curRow)
        return this.$message({ type: 'error', message: '请先选择会员', duration: 2000, showClose: true })
      if (this.vipRecordType === '1') {
        GridManager.refreshGrid(this.consumeConfig.gridManagerName, true)
      }
      if (this.vipRecordType === '2') {
        GridManager.refreshGrid(this.balanceConfig.gridManagerName, true)
      }
      if (this.vipRecordType === '3') {
        GridManager.refreshGrid(this.integralConfig.gridManagerName, true)
      }
      if (this.vipRecordType === '4') {
        GridManager.refreshGrid(this.gridOption.gridManagerName, true)
      }
    },
    resetSearch() {
      //查询条件重置
      for (let key in this.queryParams) {
        this.queryParams[key] = ''
      }
    },
    sureSelected() {
      //保存会员信息
      let reqData = {}
      for (let key in this.curRow) {
        if (this.curRow[key] && key !== 'sexName') {
          reqData[key] = this.curRow[key]
        }
      }
      if (this.curRow && this.curRow.sexName) {
        if (this.curRow.sexName === '男') {
          reqData['sex'] = '1'
        } else if (this.curRow.sexName === '女') {
          reqData['sex'] = '0'
        } else {
          reqData['sex'] = '-1'
        }
      }
      reqData['id'] = this.curRow.id
      vipSaveVipUserInfo(reqData)
        .then(data => {
          this.$message({ type: 'success', message: '信息保存成功', duration: 2000, showClose: true })
          GridManager.refreshGrid(this.vipConfig.gridManagerName)
        })
        .catch(error => {
          this.$message({ type: 'error', message: error, duration: 2000, showClose: true })
        })
    },
    quicklyCode() {
      //会员信息保存快捷键
      Mousetrap.bind(['ctrl+f5', 'ctrl+f5'], () => {
        this.sureSelected()
      })
    },
    openView(row) {
      //列表货号点击
      if (row && row.imageList) {
        this.imageList = row.imageList
      } else {
        return this.$message({
          type: 'error',
          message: '当前货品暂无图片预览',
          duration: 2000,
          showClose: true
        })
      }
      this.showViewer = true
    },
    closeViewer() {
      //关闭货号图片预览
      this.showViewer = false
    },
    openNew(no, type, marketId, scene) {
      //列表单号点击
      if (scene && scene === '4') return
      if (this.salesUnitId !== marketId + '')
        return this.$message({
          type: 'error',
          message: '其他店铺消费的订单，无法查看',
          duration: 2000,
          showClose: true
        })
      if (type === 'xf' || no.includes('S')) {
        let options = {
          type: 'vue',
          content: '/salesRecord',
          windowName: '销售记录',
          winId: 'salesRecord'
        }
        ipc.invoke(ipcApiRoute.createWindow, options).then(r => {})
      } else {
        this.vipRechargeType = true
      }
    },
    refreshList() {
      if (!this.curRow) return
      this.searchRecharge()
    },
    closeCharge(e) {
      this.rechargeDialog = false
      if (e === 'refresh') {
        this.getcurrentVip()
      }
    },
    close() {
      this.changeVisiable = false
      this.$emit('close')
    },
    closeCreate(e) {
      this.createVipDialog = false
      if (e === 'refresh') {
        GridManager.refreshGrid(this.vipConfig.gridManagerName)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.image-view {
  z-index: 99999 !important;
}
.el-image-viewer {
  z-index: 99999 !important;
}

.close {
  color: #999;
  padding: 10px;
  margin-right: -10px;
  cursor: pointer;
}
.top-btn-box {
  margin-left: 30px;
  .btn-item {
    background: #067cf2;
    color: #fff;
    border-radius: 4px;
    padding: 4px 10px;
    cursor: pointer;
    margin-left: 20px;
  }
}

.content-box {
  background: #f3f3f3;
  padding: 10px;
  .vip-card {
    width: 30%;
    //height: 810px;
    font-size: 14px;
    padding: 10px;
    background: #fff;
    .vip-search {
      border: 1px solid #ccc;
      background: #eef2fb;
      border-radius: 6px;
      section {
        position: relative;
        width: 100px;
        text-align: center;
        span {
          margin-right: 10px;
        }
        .search-list {
          position: absolute;
          width: 120px;
          top: 32px;
          background: #fff;
          border-radius: 4px;
          z-index: 99;
          box-shadow: 0 2px 10px 0 rgba(6, 124, 242, 0.21);
          div {
            line-height: 36px;
            cursor: pointer;
            &:hover {
              background: #eff7ff;
            }
          }
        }
      }
      .line {
        width: 1px;
        height: 20px;
        background: #ccc;
      }
      .icon-sousuo {
        margin: 0 8px 0 15px;
        font-size: 14px;
      }
      input {
        background: none;
        outline: none;
        width: auto;
        margin-right: 18px;
        line-height: 36px;
        border: none;
      }
      .btn {
        background: #067cf2;
        color: #fff;
        border: 1px solid #067cf2;
        width: 92px;
        font-family: 'font-Regular';
        font-size: 16px;
      }
    }
    .searchBy {
      margin: 10px 0;
      .btDay {
        text-align: center;
        width: 128px;
        height: 26px;
        line-height: 26px;
        border: 1px solid #067cf2;
        border-radius: 4px;
        background: #f6faff;
        cursor: pointer;
        color: #333;
      }
      .btDays {
        text-align: center;
        width: 128px;
        height: 26px;
        line-height: 26px;
        border: 1px solid #067cf2;
        border-radius: 4px;
        background: #067cf2;
        cursor: pointer;
        color: #fff;
      }
    }
  }
  .current-member {
    width: 500px;
    background: #fff;
    padding: 10px;
    .current-top {
      position: relative;
      .img-zuanshi {
        width: 100%;
        height: 120px;
        background: #f8e9bd;
        overflow: hidden;
        position: relative;
        border-radius: 8px 8px 0 0;
        img {
          width: 100px;
          position: absolute;
          top: 20px;
          right: -36px;
        }
      }
      .user-info {
        display: flex;
        align-items: center;
        position: absolute;
        left: 20px;
        top: 20px;
        color: #391c00;
        .vip-avatar {
          width: 80px;
          height: 80px;
          border-radius: 50%;
          border: 2px solid #fff;
          float: left;
          margin-right: 10px;
          font-size: 22px;
          font-family: 'font-Regular';
          background: #f9d396;
          i {
            font-size: 72px;
            color: white;
          }
        }
        .member-data {
          padding-left: 8px;
          > div {
            padding-bottom: 8px;
            justify-content: unset;
            span {
              font-size: 20px;
              font-weight: 700;
              color: #391c00;
            }
            img {
              width: 22px;
              height: 22px;
              margin-left: 10px;
            }
          }
          > span {
            font-size: 16px;
          }
        }
      }
      .member-other {
        width: 100%;
        height: 86px;
        background: #fdf5e2;
        border-radius: 0 0 8px 8px;
        > div {
          flex: 1;
        }
        .other-item {
          width: 120px;
          &:first-child {
            margin-left: -5px;
          }
          span {
            font-size: 14px;
          }
          p {
            color: #391c00;
            font-weight: 800;
            font-size: 22px;
          }
        }
        .recharge-btn {
          font-size: 20px;
          color: white;
          height: 50px;
          line-height: 50px;
          border-radius: 25px;
          width: 100px;
          text-align: center;
          background: #a45f23;
          cursor: pointer;
          margin-right: 10px;
        }
      }
    }
    .vip-form {
      width: 100%;
      font-size: 12px;
      color: #b0b6c5;
      margin-top: 20px;
      .form-label {
        justify-content: unset;
        margin-bottom: 20px;
        span {
          width: 3px;
          height: 16px;
          background: #067cf2;
          margin-right: 5px;
        }
        div {
          font-size: 15px;
          font-weight: 600;
          color: #000;
        }
      }
      .vip-row {
        margin-bottom: 23px;
        .form-left {
          //width: calc((100% - 80px) / 2);
          span {
            width: 60px;
          }
        }
        input {
          border: 1px solid #eff0f5;
          text-align: center;
          border-radius: 3px;
          height: 26px;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        .onlyRead {
          text-align: center;
          border: none;
          background-color: #eff0f5;
          border-radius: 3px;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        .int {
          height: 32px;
        }
        .inp {
          border: 1px solid #e0e0e0;
        }
      }
    }
    .save-member {
      background: #067cf2;
      color: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 18px;
      font-family: 'font-Regular';
      border-radius: 6px;
      margin-top: 40px;
      height: 62px;
      cursor: pointer;
      p {
        line-height: 36px;
      }
      span {
        font-size: 16px;
        color: #9bc0f8;
        line-height: 16px;
      }
    }
  }
  .record-info {
    width: calc(70% - 520px);
    background: #fff;
    padding: 10px;
    .record-tab {
      margin: auto;
      width: 100%;
      height: 52px;
      border-radius: 6px;
      border: 1px solid #017df2;
      padding: 3px;
      text-align: center;
      line-height: 46px;
      font-size: 16px;
      position: relative;
      .items-records {
        cursor: pointer;
        width: 25%;
        height: 100%;
        border-radius: 6px;
      }
      .items-box {
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
        width: 25%;
        height: 100%;
        border-radius: 6px;
        transition: all linear 0.12s;
        border: #fff 3px solid;
        background-color: #067cf2;
        color: white;
      }
    }
    .search-records {
      padding: 0 10px 10px;
      margin: 10px auto;
      box-shadow: 0.5px 0.5px 2px 2px #f7f7f7;
      .search-div {
        margin-right: 20px;
        margin-top: 15px;
        .reset {
          width: 88px;
          height: 32px;
          border-radius: 5px;
        }
        .search {
          width: 88px;
          height: 32px;
          border-radius: 5px;
          background: #017df2;
          color: #fff;
        }
      }
    }
  }
}
</style>
