<template>
  <section>
    <dialogModule
      width="1400px"
      top="10"
      title="库存账卡"
      v-if="changeVisiable"
      :dialogVisible="changeVisiable"
      @close="close">
      <span slot="close" class="el-icon-close close" @click="close"></span>

      <div slot="content" style="padding: 15px">
        <section class="top">
          <el-form :inline="true" :model="form" size="small">
            <el-row>
              <el-form-item label="货号" required>
                <el-input v-model="form.goodsNo" style="width: 150px" placeholder="请输入" clearable></el-input>
              </el-form-item>
              <el-form-item label="单据编号">
                <el-input v-model="form.sn" placeholder="请输入" clearable></el-input>
              </el-form-item>
              <el-form-item label="单据类型">
                <el-select v-model="form.invoicesType" multiple placeholder="请选择" clearable>
                  <el-option v-for="item in invoicesTypeList" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="日期范围" required>
                <el-date-picker
                  v-model="form.data"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd"
                  :picker-options="pickerOptions"
                  clearable>
                </el-date-picker>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" style="width: 100px" @click="onSubmit" :loading="loading"> 查询 </el-button>
              </el-form-item>
            </el-row>
          </el-form>
        </section>
        <section class="mid">
          <grid-manager :option="gridOption"></grid-manager>
        </section>
      </div>
    </dialogModule>
  </section>
</template>

<script>
import dialogModule from '@/components/dialogModule.vue'
import noDataState from '@/components/noDataState.vue'
import lodash from 'lodash'

let TABLEDATA = []
let SIZEGROUP = []

import { erp2OperatingQuerySizeGroupInfoIist, erp2OperatingQueryStockAccountCard } from '@/libs/http/modules/erp.js'

export default {
  props: ['goodsNo'],
  components: {
    dialogModule,
    noDataState
  },
  data() {
    return {
      loading: false,
      changeVisiable: false,
      form: {
        goodsNo: '',
        sn: '',
        invoicesType: [],
        data: []
      },
      gridOption: {
        height: '65vh',
        gridManagerName: 'stockAccountCardTable',
        firstLoading: false,
        isCombSorting: true,
        supportCheckbox: false,
        supportAutoOrder: false,
        useTrFocus: true,
        lineHeight: '20px',
        checkboxConfig: {
          fixed: 'left'
        },
        columnData: [
          {
            key: 'invoicesDate',
            text: '变动日期',
            width: '200px',
            fixed: 'left'
          },
          {
            key: 'invoicesType',
            text: '单据类型',
            width: '120px',
            fixed: 'left'
          },
          { key: 'sn', text: '单据编号', width: '220px', fixed: 'left' },
          { key: 'goodsNo', text: '货号', width: '150px' },
          { key: 'goodsName', text: '货品名称' },
          { key: 'color', text: '颜色' },
          { key: 'outUnitCode', text: '出库单位编号' },
          { key: 'outUnitName', text: '出库单位名称' },
          { key: 'inUnitCode', text: '入库单位编号' },
          { key: 'inUnitName', text: '入库单位名称' }
        ],
        ajaxData: this.fetch,
        //合计暂时去掉
        // summaryHandler: (data) => {
        // 	let list = []

        // 	SIZEGROUP.map((item) => {
        // 		if (item.valueGroupId == TABLEDATA[1].items[0].valueGroupId) {
        // 			for (let i of item.columnData) {
        // 				list.push(i.key)
        // 			}
        // 		}
        // 	})
        // 	let obj = {
        // 		inUnitName: '合计'
        // 	}
        // 	list.forEach((key) => {
        // 		obj[key] = data.reduce((c, R) => c + Number(R[key] || 0), 0)
        // 	})
        // 	return obj
        // },
        supportAjaxPage: false
      },
      invoicesTypeList: [
        {
          label: '零售核销单',
          value: '1'
        },
        {
          label: '店铺调拨单',
          value: '2'
        },
        {
          label: '店铺配货单',
          value: '3'
        },
        {
          label: '店铺退仓单',
          value: '4'
        },
        {
          label: '盘点过账',
          value: '5'
        },
        {
          label: '库存调整单',
          value: '6'
        },
        {
          label: '积分兑换',
          value: '7'
        },
        {
          label: '店铺客订单',
          value: '8'
        },

      ],
      sizeGroup: [],
      valueGroupIdList: [],
      tableData: [],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        }
      }
    }
  },
  created() {
    if (this.goodsNo) {
      this.form.goodsNo = this.goodsNo
    }
    this.fetchSizeInfo()
    this.changeVisiable = true
  },
  methods: {
    onSubmit() {
      if (this.form.goodsNo === '') {
        return this.$message.error({ type: 'error', message: '货号不能为空', duration: 2000, showClose: true })
      }
      if (!this.form.data || !this.form.data.length) {
        return this.$message.error({ type: 'error', message: '日期不能为空', duration: 2000, showClose: true })
      }
      GridManager.refreshGrid(this.gridOption.gridManagerName)
    },
    close() {
      this.changeVisiable = false
      this.$emit('close')
    },
    fetch(params) {
      return new Promise(async (resolve, reject) => {
        try {
          let reqData = {
            page: params.pageData.cPage,
            pageSize: params.pageData.pSize
          }
          for (let key in this.form) {
            if (!['data', 'invoicesType'].includes(key)) {
              if (this.form[key]) {
                reqData[key] = this.form[key]
              }
            }
            if (key == 'data') {
              reqData['startDate'] = this.form[key][0]
              reqData['endDate'] = this.form[key][1]
            }
            if (key == 'invoicesType' && this.form[key].length) {
              reqData[key] = this.form[key]
            }
          }

          this.loading = true
          let data = await erp2OperatingQueryStockAccountCard(reqData)
          if (data.length) {
            let endNum = data[0].startNum * 1 //期末库存一开始和期初库存一样
            for (let i of data) {
              if (i.items.length) {
                let sum = 0
                for (let j of i.items) {
                  i[`${j.value}Key`] = j.num
                  sum += +j.num || 0
                }
                i.sum = sum
                endNum += sum //期末库存 - 行数据的总计
              }
            }
            //前端期初期末暂时去掉
            // data.unshift({sum: data[0].startNum, invoicesType: '期初', invoicesDate: this.form.data[0]})
            // data.push({sum: endNum, invoicesType: '期末', invoicesDate: this.form.data[1]})
            TABLEDATA = data
            if (SIZEGROUP.length) {
              this.reRender()
            }
          } else {
            data = []
          }
          const tableData = {
            data: data
          }
          this.loading = false
          resolve(tableData)
        } catch (e) {
          this.loading = false
          this.$message({ type: 'error', message: e, duration: 2000, showClose: true })
          reject(e)
        }
      })
    },

    //查询尺码组
    fetchSizeInfo() {
      erp2OperatingQuerySizeGroupInfoIist({ valueGroupIds: this.valueGroupIdList || [] })
        .then(data => {
          if (data && data.length) {
            for (let i of data) {
              let arr = []
              i.valueInfoList.sort((a, b) => a.sort - b.sort)
              for (let j of i.valueInfoList) {
                arr.push({
                  text: j.value,
                  key: j.value + 'Key',
                  width: '75px',
                  align: 'center'
                })
              }
              i.columnData = [].concat(arr)
            }
            // for(let i of data){
            //     if(i.valueGroupId =='8'){
            //          let columnData = this.gridOption.columnData
            //          columnData.push(...i.columnData)
            //          GridManager.renderGrid(this.gridOption.gridManagerName,columnData)
            //     }
            // }
            // this.reRender()

            SIZEGROUP = data || []
          }
        })
        .catch(err => {
          this.$message({
            type: 'error',
            message: err,
            duration: 2000,
            showClose: true
          })
        })
    },
    reRender() {
      GridManager.showLoading(this.gridOption.gridManagerName)
      for (let i of SIZEGROUP) {
        if (i.valueGroupId == TABLEDATA[1].items[0].valueGroupId) {
          let columnData = lodash.cloneDeep(this.gridOption.columnData)
          columnData.push(...i.columnData)
          columnData.push({ key: 'sum', text: '合计', width: '100px' })

          GridManager.renderGrid(this.gridOption.gridManagerName, columnData)
        }
      }
      GridManager.hideLoading(this.gridOption.gridManagerName, 100)
    }
  }
}
</script>
<style scoped>
.close {
  color: #999;
  padding: 10px;
  margin-right: -10px;
  cursor: pointer;
}
</style>
