<template>
  <dialogModule width="800px" title="选择规格" v-if="chooseVisiable" :dialogVisible="chooseVisiable" @close="close">
    <span slot="close" class="el-icon-close close" @click="close"></span>
    <div
      slot="content"
      style="padding: 15px"
      v-loading="loading"
      element-loading-text="请稍后，全力加载中..."
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0)">
      <div class="guige row">
        <template v-if="dataObj">
          <div class="goods-img">
            <el-image
              class="img"
              v-if="dataObj.imageList && dataObj.imageList.length"
              :src="dataObj.imageList[0]"
              :preview-src-list="dataObj.imageList"></el-image>
            <div v-else class="img-null row align-center space-center"><span>暂无图片</span></div>
          </div>
          <div style="flex: 1">
            <div class="row align-end top-data">
              <div class="title">{{ title || dataObj.title }}</div>
              <div class="bar-num">货号：{{ curRow.goodsNo }}</div>
              <div class="bar-num">条码：{{ barCode || curRow.barCode }}</div>
            </div>
            <p class="price">¥{{ price || curRow.tagPrice || '0.00' }}</p>
            <div class="line"></div>

            <div class="row color-size">
              <div class="title">{{ dataObj.attrList[0].title }}：</div>
              <div class="row flex-wrap text-center value-card">
                <div
                  v-for="(val, index) in chooseSizeList.length ? chooseSizeList : dataObj.attrList[0].valueList"
                  :key="index"
                  :class="['card-item', sizeVal == val.value ? 'item-check' : '']"
                  @click="sizeClick(dataObj.attrList[0].code, val)">
                  {{ val.value }}
                </div>
              </div>
            </div>
            <div class="row color-size">
              <div class="title">{{ dataObj.attrList[1].title }}：</div>
              <div class="row flex-wrap text-center value-card">
                <div
                  v-for="(val, index) in chooseColorList.length ? chooseColorList : dataObj.attrList[1].valueList"
                  :key="index"
                  :class="['card-item', colorVal == val.value ? 'item-check' : '']"
                  @click="colorClick(dataObj.attrList[1].code, val)">
                  {{ val.value }}
                </div>
              </div>
            </div>

            <div class="row align-center inven-number">
              <div class="repertory">
                库存：<span>{{ stock || curRow.stock || 0 }}</span
                >件
              </div>
              <div class="row align-center space-center">
                <div class="minus-num" @click="numberMinus">
                  <span class="el-icon-minus"></span>
                </div>
                <input
                  class="choose-num"
                  v-model="number"
                  @focus="inputFocus"
                  @blur="inputBlur"
                  :disabled="!chooseItem" />
                <div class="plus-num" @click="numberPlus">
                  <span class="el-icon-plus"></span>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
      <div class="row align-center space-center bot-handel">
        <div @click="close">取 消</div>
        <div @click="sureSend">确 定</div>
      </div>
    </div>
  </dialogModule>
</template>

<script>
import dialogModule from '@/components/dialogModule.vue'
import { queryStoreInfoList } from '@/libs/http/modules/posOrder.js'
export default {
  props: ['curRow', 'invoicesTypeId', 'type', 'invoiceCode'],
  components: {
    dialogModule
  },
  data() {
    return {
      number: '1',
      chooseVisiable: true,
      dataObj: null,
      colorCode: '',
      colorVal: '',
      sizeCode: '',
      sizeVal: '',
      price: '',
      barCode: '',
      title: '',
      stock: '',
      skuList: [],
      filterList: [],
      defNumber: '',
      chooseSizeList: [],
      chooseColorList: [],
      chooseItem: null,
      loading: false
    }
  },
  created() {
    this.chooseItem = this.curRow
    this.number = this.curRow.number
    this.sizeVal = this.curRow.attr[1].value
    this.colorVal = this.curRow.attr[0].value
    this.skuSearch()
  },
  methods: {
    close() {
      this.chooseVisiable = false
      this.$emit('close')
    },
    numberMinus() {
      if (!this.sizeVal) {
        return this.$message({ type: 'error', message: '请先选择尺码', duration: 2000, showClose: true })
      }
      if (!this.colorVal) {
        return this.$message({ type: 'error', message: '请先选择颜色', duration: 2000, showClose: true })
      }
      if (Number(this.number) <= 1) {
        return this.$message({
          type: 'error',
          message: '最小数量为1，不能再减了',
          duration: 2000,
          showClose: true
        })
      }
      this.number--
    },
    numberPlus() {
      if (!this.sizeVal) {
        return this.$message({ type: 'error', message: '请先选择尺码', duration: 2000, showClose: true })
      }
      if (!this.colorVal) {
        return this.$message({ type: 'error', message: '请先选择颜色', duration: 2000, showClose: true })
      }
      if (this.chooseItem.isAllowNegativeStocks == 'N' && Number(this.chooseItem.stock) <= Number(this.number)) {
        return this.$message({ type: 'error', message: '当前货品库存不足', duration: 2000, showClose: true })
      }
      this.number++
    },
    inputFocus() {
      this.defNumber = this.number
    },
    inputBlur() {
      if (Number(this.number) <= 0) {
        this.number = this.defNumber
        return this.$message({
          type: 'error',
          message: '请填写大于或等于1的整数',
          duration: 2000,
          showClose: true
        })
      }
      if (this.chooseItem.isAllowNegativeStocks == 'N' && Number(this.chooseItem.stock) <= Number(this.number)) {
        this.number = this.defNumber
        return this.$message({ type: 'error', message: '当前货品库存不足', duration: 2000, showClose: true })
      }
    },
    sureSend() {
      if (!this.colorVal) {
        return this.$message({ type: 'error', message: '请选择颜色', duration: 2000, showClose: true })
      }
      if (!this.sizeVal) {
        return this.$message({ type: 'error', message: '请选择尺码', duration: 2000, showClose: true })
      }
      if (this.chooseItem.isAllowNegativeStocks == 'N' && Number(this.chooseItem.stock) < Number(this.number)) {
        return this.$message({ type: 'error', message: '当前货品库存不足', duration: 2000, showClose: true })
      }
      if (this.type === 'ZP') {
        let obj = {
          barCode: this.chooseItem.barCode,
          colorCode: this.colorCode,
          colorVal: this.colorVal,
          sizeCode: this.sizeCode,
          sizeVal: this.sizeVal,
          giftCount: this.number,
          sku: this.chooseItem.sku
        }
        this.$emit('close', obj)
      } else {
        let obj = {
          number: this.number,
          sku: this.chooseItem.sku,
          barCode: this.chooseItem.barCode,
          isRedemption: this.chooseItem.isRedemption
        }
        this.$emit('close', obj)
      }
    },
    skuSearch() {
      this.loading = true
      queryStoreInfoList({
        skuBarCode: this.curRow.barCode
      })
        .then(data => {
          this.dataObj = data.spuInfoBean
          this.skuList = data.spuInfoBean.storeSkuInfoBeanList
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          this.$message({ type: 'error', message: err, duration: 2000, showClose: true })
        })
    },

    sizeClick(code, obj) {
      this.number = 1
      this.chooseItem = null
      if (this.sizeVal && this.sizeVal == obj.value) {
        this.sizeCode = ''
        this.sizeVal = ''
        if (!this.colorVal) {
          this.chooseItem = null
          this.title = this.dataObj.title
          this.barCode = this.curRow.barCode
          this.price = this.curRow.price
          this.stock = this.curRow.stock
        }
      } else {
        this.sizeCode = code
        this.sizeVal = obj.value
      }
      let curArray = []
      this.skuList.forEach(item => {
        item.attrList.forEach(list => {
          if (this.colorVal) {
            if (list.code == this.sizeCode && list.value == this.sizeVal && this.colorVal == item.attrList[0].value) {
              this.chooseItem = item
              this.title = item.title
              this.barCode = item.barCode
              this.price = item.price
              this.stock = item.stock
            }
          }
          if (list.code == this.sizeCode && list.value == this.sizeVal) {
            curArray.push(item)
          }
        })
      })
      let clickArr = curArray.map(item => {
        return item.attrList[0].value
      })
      let newList = this.dataObj.attrList[1].valueList.filter(item => {
        return clickArr.includes(item.value)
      })
      this.chooseColorList = newList
    },
    colorClick(code, obj) {
      this.number = 1
      this.chooseItem = null
      if (this.colorVal && this.colorVal == obj.value) {
        this.colorCode = ''
        this.colorVal = ''
        if (!this.sizeVal) {
          this.chooseItem = null
          this.title = this.dataObj.title
          this.barCode = this.curRow.barCode
          this.price = this.curRow.price
          this.stock = this.curRow.stock
        }
      } else {
        this.colorCode = code
        this.colorVal = obj.value
      }
      let curArray = []
      this.skuList.forEach(item => {
        item.attrList.forEach(list => {
          if (this.sizeVal) {
            if (list.code == this.colorCode && list.value == this.colorVal && this.sizeVal == item.attrList[1].value) {
              this.chooseItem = item
              this.title = item.title
              this.barCode = item.barCode
              this.price = item.price
              this.stock = item.stock
            }
          }
          if (list.code == this.colorCode && list.value == this.colorVal) {
            curArray.push(item)
          }
        })
      })
      let clickArr = curArray.map(item => {
        return item.attrList[1].value
      })
      let newList = this.dataObj.attrList[0].valueList.filter(item => {
        return clickArr.includes(item.value)
      })
      this.chooseSizeList = newList
    }
  }
}
</script>

<style lang="scss" scoped>
.close {
  color: #999;
  padding: 10px;
  margin-right: -10px;
  cursor: pointer;
}

.guige {
  font-family: 'font-Light';
  min-height: 200px;
  .goods-img {
    width: 255px;
    height: 260px;
    margin-right: 20px;
    font-size: 0;
    .img {
      width: 255px;
      height: 260px;
    }
    .img-null {
      width: 255px;
      height: 260px;
      background: #f5f5f5;
      font-size: 24px;
      color: #999;
    }
  }
  .top-data {
    .title {
      font-family: 'font-Regular';
      font-size: 18px;
      color: #333;
    }
    .bar-num {
      font-size: 14px;
      color: #666;
      margin-left: 30px;
    }
  }
  .price {
    font-size: 24px;
    font-family: 'font-Medium';
    color: #f8475f;
    margin: 10px 0 20px;
  }
  .line {
    width: 100%;
    height: 1px;
    background: #eee;
    margin-bottom: 20px;
  }
  .color-size {
    .title {
      width: 60px;
      margin-top: 6px;
    }
    .value-card {
      flex: 1;
      .card-item {
        width: 60px;
        line-height: 30px;
        border: 1px solid #eee;
        border-radius: 4px;
        margin: 0 10px 10px 0;
        cursor: pointer;
      }
      .item-check {
        border: 1px solid #067cf2;
        background: #067cf2;
        color: #fff;
      }
    }
  }
  .inven-number {
    margin-bottom: 60px;
    margin-top: 10px;
    .repertory {
      width: 130px;
      span {
        color: #f8475f;
        margin-left: 18px;
      }
    }
    .minus-num {
      width: 40px;
      line-height: 30px;
      text-align: center;
      font-size: 18px;
      border: 1px solid #eee;
      color: #333;
      cursor: pointer;
    }
    .choose-num {
      width: 80px;
      line-height: 30px;
      text-align: center;
      font-size: 18px;
      border: 1px solid #eee;
      border-left: none;
      color: #333;
      outline: none;
    }
    .plus-num {
      background: #067cf2;
      color: #fff;
      width: 40px;
      line-height: 30px;
      text-align: center;
      font-size: 18px;
      cursor: pointer;
      border: 1px solid #067cf2;
    }
  }
}

.bot-handel {
  text-align: center;
  margin: 20px auto;
  font-family: 'font-Regular';
  font-size: 16px;
  & :first-child {
    width: 100px;
    line-height: 40px;
    border: 1px solid #dcdfe6;
    border-radius: 4px;
    margin-right: 10px;
    cursor: pointer;
  }
  & :last-child {
    width: 100px;
    line-height: 40px;
    border-radius: 4px;
    background: #067cf2;
    color: #fff;
    cursor: pointer;
  }
}
</style>
