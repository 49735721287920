<template>
  <el-drawer title="改价申请" v-if="changeVisiable" size="80%" :visible.sync="changeVisiable" @close="close">
    <div class="content" v-if="alterPriceInfo">
      <div class="applyInfo">
        <div class="shop-info row align-center">
          <i class="el-icon-s-order"></i>
          申请单信息
        </div>
        <div class="submit-form">
          <el-row>
            <el-col :span="8">
              <div class="row align-center">
                <div class="label"><span style="color: red">*</span>提交人</div>
                <div style="width: 70%">
                  <el-select
                    v-model="queryForm.submitUser"
                    placeholder="请选择"
                    clearable
                    filterable
                    remote
                    :remote-method="queryGuide"
                    @focus="queryGuide()"
                    @clear="queryGuide('')"
                    style="width: 100%">
                    <el-option v-for="item in userList" :key="item.id" :label="item.realName" :value="item.id">
                    </el-option>
                  </el-select>
                </div></div
            ></el-col>
            <el-col :span="8">
              <div class="row align-center">
                <div class="label">提交时间</div>
                <div style="width: 70%">
                  <el-input disabled size="medium" v-model="queryForm.submitTime"></el-input>
                </div></div
            ></el-col>
            <el-col :span="8">
              <div class="row align-center">
                <div class="label">店铺名称</div>
                <div style="width: 70%">
                  <el-input disabled size="medium" v-model="queryForm.submitDept"></el-input>
                </div></div
            ></el-col>
          </el-row>

          <el-row style="margin-top: 20px">
            <el-col :span="8">
              <div class="row align-center">
                <div class="label">原价</div>
                <div style="width: 70%">
                  <el-input disabled size="medium" v-model="alterPriceInfo.oldPrice"></el-input>
                </div></div
            ></el-col>
            <el-col :span="8">
              <div class="row align-center">
                <div class="label">改后金额</div>
                <div style="width: 70%">
                  <el-input size="medium" v-model="alterPriceInfo.alterPrice" disabled></el-input>
                </div></div
            ></el-col>
            <!-- <el-col :span="8">
              <div class="row align-center">
                <div class="label">低于原价(%)</div>
                <div style="width: 70%">
                  <el-input
                    disabled
                    size="medium"
                    v-model="alterPriceInfo.differenceRatio"
                  ></el-input>
                </div></div
            ></el-col> -->
            <el-col :span="8">
              <div class="row align-center">
                <div class="label">变动金额</div>
                <div style="width: 70%">
                  <el-input disabled size="medium" v-model="alterPriceInfo.changeMount"></el-input>
                </div></div
            ></el-col>
          </el-row>

          <!-- <el-row style="margin-top: 20px">
            <el-col :span="8">
              <div class="row align-center">
                <div class="label">低于原价(元)</div>
                <div style="width: 70%">
                  <el-input
                    disabled
                    size="medium"
                    v-model="alterPriceInfo.differencePrice"
                  ></el-input>
                </div></div
            ></el-col>
          </el-row> -->
          <el-row style="margin-top: 20px">
            <el-col :span="24">
              <div class="row">
                <div class="label">备注</div>
                <div style="width: 100%">
                  <el-input
                    v-model="queryForm.description"
                    type="textarea"
                    :rows="3"
                    placeholder="请输入备注"
                    resize="none"></el-input>
                </div></div
            ></el-col>
          </el-row>
        </div>
      </div>
      <div style="height: 10px; background: #f5f5f5; margin-top: 20px"></div>
      <section class="change-price" style="margin: 20px">
        <div class="shop-info row align-center"><i class="el-icon-s-order"></i>改价货品</div>
        <grid-manager v-if="gridOption && gridOption.ajaxData" :option="gridOption"></grid-manager>
      </section>
      <div style="height: 10px; background: #f5f5f5; margin-top: 20px"></div>
      <section style="margin: 20px">
        <FlowSteps :flowTypeId="alterPriceInfo.hrFlowTypeId"></FlowSteps>
      </section>
    </div>
    <div class="row align-center space-center bot-handel">
      <div class="cancel-btn" @click="close">取 消</div>
      <el-button class="sure-btn" @click="sureHandel" :disabled="loading" :loading="loading">确 定</el-button>
    </div>
  </el-drawer>
</template>

<script>
import dialogModule from '@/components/dialogModule.vue'
import FlowSteps from '@/components/FlowSteps.vue'
import moment from 'moment'
import { submitStoreInvoice, queryShoppGuide, posSubmitPosAlterPrice } from '@/libs/http/modules/posOrder'
export default {
  props: [
    'alterPriceInfo',
    'isAllOrding',
    'invoicesTypeId',
    'salesTypeId',
    'vipData',
    'activityInfo',
    'curGuider',
    'reduceResult',
    'list'
  ],
  components: {
    dialogModule,
    FlowSteps
  },
  data() {
    return {
      sell: require('@/assets/images/sell.png'),
      give: require('@/assets/images/give.png'),
      back: require('@/assets/images/return.png'),
      rite: require('@/assets/images/rite.png'),
      ned: require('@/assets/images/ned.png'),
      changeVisiable: true,
      loading: false,
      queryForm: {
        submitUser: '',
        submitTime: moment().format('yyyy-MM-DD HH:mm'),
        submitDept: this.$cache.local.get('deptName'),
        description: ''
      },
      gridOption: {
        gridManagerName: 'changePrice',
        firstLoading: true,
        height: '280px',
        isCombSorting: true,
        supportAutoOrder: false,
        supportCheckbox: false,
        supportConfig: true,
        disableAutoLoading: true,
        supportMenu: false,
        disableBorder: true,
        disableLine: true,
        columnData: [
          {
            key: 'operationType',
            text: '类型',
            align: 'center',
            width: '80px',
            template: () => {
              return `
                <div>
                  <img style="width:40px;height:40px;" v-if="row.operationType === '1'" :src="sell" />
                  <img style="width:40px;height:40px;" v-if="row.operationType === '2'" :src="give" />
                  <img style="width:40px;height:40px;" v-if="row.operationType === '4'" :src="back" />
                  <img style="width:40px;height:40px;" v-if="row.operationType === '3'" :src="rite" />
                  <img style="width:40px;height:40px;" v-if="row.operationType === '6'" :src="ned" />
                </div>
              `
            }
          },
          {
            key: 'imageList',
            text: '货品信息',
            align: 'center',
            width: '280px',
            template: () => {
              return `
                <div class="row align-cneter" style="text-align:left;">
                  <div style="position:relative;overflow:hidden;width:102px;height:92px;border-radius:4px;">
                    <el-image v-if="row.imageList && row.imageList[0]" style="width:102px;height:92px;border-radius:4px;float:left;" :preview-src-list="row.imageList" :src="row.imageList[0]"></el-image>
                    <div v-else style="width:102px;height:92px;border-radius:4px;text-align:center;line-height:92px;color:#999;background:#f5f5f5;">暂无图片</div>
                  </div>
                  <div class="column space-around" style="margin-left:10px;flex:1;width:170px;">
                    <div style="font-size:18px;color:#333;margin-top:-4px;">{{row.goodsName}}</div>
                    <div style="font-size:14px;color:#999;margin:4px 0;">货号：{{row.goodsNo}}</div>
                    <div style="font-size:14px;color:#999;">条码：{{row.barCode}}</div>
                  </div>
                </div>
              `
            }
          },
          {
            key: 'attr',
            text: '颜色 / 尺码',
            align: 'center',
            width: '200px',
            template: () => {
              return `
                <div v-if="row.attr" class="row space-center align-center" style="width:130px;border:1px solid #DEEEF9;background:#fff;color:#333;line-height:26px;border-radius:2px;cursor:pointer;margin:0 auto">
                  <p v-for="(item, index) in row.attr.slice(0, 2)" :key="item.code" style="margin-right:10px;">{{item.value}}
                   <span v-if="index == 0" style="margin:0 3px;">,</span>
                  </p>
                </div>
                <div style="color:#999;margin-top:10px;">可用库存：{{row.stock}}</div>
              `
            }
          },
          {
            key: 'tagPrice',
            text: '吊牌价',
            align: 'center',
            template: () => {
              return `
                <div class="row align-cneter space-center" style="font-size:18px">
                  ￥{{row.tagPrice}}
                </div>
              `
            }
          },
          {
            key: 'defaultDiscount',
            text: '折扣',
            align: 'center',
            template: () => {
              return `
                <div class="row align-cneter space-center" style="font-size:18px">
                  {{(row.realPrice / row.tagPrice * 10).toFixed(1)}}折
                </div>
              `
            }
          },
          {
            key: 'number',
            text: '数量',
            align: 'center',
            template: () => {
              return `
                  <div class="row align-center space-center">
                    {{row.number}}
                  </div>
                `
            }
          },
          {
            key: 'agoRealPrice',
            text: '改前单价',
            align: 'center',
            template: () => {
              return `
                <div class="row align-center space-center" style="font-size:18px;">
                  <span>￥</span>{{row.agoRealPrice}}
                </div>
              `
            }
          },
          {
            key: 'realPrice',
            text: '改后单价',
            align: 'center',
            width: '130px',
            template: () => {
              return `
                <div class="row align-center space-center" style="font-size:18px;">
                  <div v-if="row.isNotAll" class="row align-center space-center">
                    <span style="color:#666;margin-right:8px;">￥</span>
                    <input disabled v-model="row.realPrice" @focus="getdefRow(row)" @blur="getnewRow(row, index)" @keyup.enter="$event.target.blur()" style="border:1px solid #eee;outline:none;background:#FAFAFA;width:90px;text-align:center;height:46px;font-size:20px;border-radius:2px;" />
                  </div>
                  <p v-else class="row align-center space-center">
                    <span>￥</span>
                    <span style="border:1px solid #eee;background:#FAFAFA;width:90px;line-height:46px;">{{row.realPrice}}</span>
                  </p>
                </div>
              `
            }
          },
          {
            key: 'afterTotalPrice',
            text: '改前总价',
            align: 'center',
            template: () => {
              return `
                <div class="row align-cneter space-center" style="color:#F8475F;font-size:20px;">
                  ￥{{(row.agoRealPrice * row.number).toFixed(2)}}
                </div>
              `
            }
          },
          {
            key: 'allRealPrice',
            text: '改后总价',
            align: 'center',
            template: () => {
              return `
                <div class="row align-cneter space-center" style="color:#F8475F;font-size:20px;">
                  ￥{{(row.realPrice * row.number).toFixed(2)}}
                </div>
              `
            }
          }
        ],
        ajaxData: this.showGoodsTable
      },
      submitList: [],
      userList: []
    }
  },
  created() {
    this.userList = this.curGuider || []
    this.queryForm.submitUser = this.curGuider.length ? this.curGuider[0].id : ''
    this.submitList = this.alterPriceInfo.goodInfos || []
    this.alterPriceInfo['changeMount'] =
      Number(this.alterPriceInfo.oldPrice) - Number(this.alterPriceInfo.alterPrice) < 0
        ? '增加' + (Number(this.alterPriceInfo.oldPrice) - Number(this.alterPriceInfo.alterPrice))
        : '减少' + (Number(this.alterPriceInfo.oldPrice) - Number(this.alterPriceInfo.alterPrice))
  },
  methods: {
    async queryGuide(name) {
      let res = await queryShoppGuide({ keyword: name, page: '1', pageSize: '50' })
      this.userList = res.items || []
    },
    close() {
      this.changeVisiable = false
      this.$emit('close')
    },
    showGoodsTable() {
      if (this.submitList && this.submitList.length) {
        return new Promise((resolve, reject) => {
          const tableData = {
            data: this.submitList
          }
          resolve(tableData)
        })
      }
    },
    sureHandel() {
      if (!this.queryForm.submitUser) {
        return this.$message({ type: 'error', message: '请选择申请人', duration: 2000, showClose: true })
      }
      if (this.$cache.local.get('saleQuality') === 'ZY' && !this.queryForm.description) {
        return this.$message({ type: 'error', message: '请填写审批备注', duration: 2000, showClose: true })
      }
      const cashierItem = JSON.parse(this.$cache.local.get('cashierDeskItem'))
      this.loading = true
      let shopArr = this.curGuider.map(item => {
        return item.id
      })
      let reqData = {}
      let obj = {
        storeInfoList: this.isAllOrding ? this.submitList : this.list,
        type: '2',
        orderTypeId: this.invoicesTypeId,
        brokerage: this.salesTypeId,
        crmVipId: this.vipData ? this.vipData.id : '',
        source: '2',
        checkActivityInfos: this.activityInfo,
        isAlterpriceReview: 'Y',
        shoppingGuideIds: shopArr.length ? shopArr : [this.queryForm.submitUser]
      }
      if (this.isAllOrding) {
        reqData = { ...obj, ...this.alterPriceInfo.reduceResult }
      } else {
        reqData = { ...obj, ...this.reduceResult }
      }
      if (cashierItem) {
        reqData['terminalId'] = cashierItem.id
      }
      submitStoreInvoice(reqData)
        .then(data => {
          this.$emit('setTablist')
          this.submitApprove(data.id)
        })
        .catch(err => {
          this.loading = false
          this.$message({ type: 'error', message: err, duration: 2000, showClose: true })
        })
    },
    submitApprove(id) {
      let list = this.submitList.map(item => {
        return {
          sku: item.sku,
          number: item.number,
          beforePrice: item.agoRealPrice,
          afterPrice: item.realPrice
        }
      })
      posSubmitPosAlterPrice({
        invoicesId: id,
        goodsInfos: list,
        alterType: this.isAllOrding ? '0' : '1',
        description: this.queryForm.description,
        submitUser: this.queryForm.submitUser,
        hrFlowTypeId: this.alterPriceInfo.hrFlowTypeId
      })
        .then(data => {
          this.loading = false
          this.close()
          this.$message({ type: 'success', message: '改价申请已提交', duration: 2000, showClose: true })
        })
        .catch(err => {
          this.loading = false
          this.$message({ type: 'error', message: err, duration: 2000, showClose: true })
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.close {
  color: #999;
  padding: 10px;
  margin-right: -10px;
  cursor: pointer;
}
.content {
  height: 82vh;
  overflow: auto;
}
.shop-info {
  color: #656d78;
  font-weight: 500;
  font-size: 18px;
  font-family: 'font-Medium';
  text-align: left;
  margin-bottom: 20px;
  i {
    font-size: 22px;
    margin-right: 8px;
  }
}

.applyInfo {
  background: #ffffff;
  border-radius: 4px;
  margin: 0 20px;
  .submit-form {
    .label {
      width: 100px;
      font-size: 14px;
      font-family: 'font-Light';
      color: #333;
    }
  }
}

.bot-handel {
  text-align: center;
  margin: 40px auto 10px;
  font-family: 'font-Regular';
  font-size: 16px;
  .cancel-btn {
    width: 100px;
    line-height: 40px;
    border: 1px solid #dcdfe6;
    border-radius: 4px;
    margin-right: 10px;
    cursor: pointer;
  }
  .sure-btn {
    width: 100px;
    line-height: 40px;
    border-radius: 4px;
    background: #067cf2;
    color: #fff;
    cursor: pointer;
    padding: 0;
    border: none;
    font-size: 16px;
  }
}
</style>

<style>
.change-price .table-wrap {
  height: auto !important;
}
.submit-form .el-input__inner {
  background: #eef2fb;
  border: 1px solid #ccc;
}
</style>
