<template>
	<section class="mask-shade row align-center space-center">
		<div class="shade-box">
			<div class="row space-between align-center shade-title">
				<div class="row align-center">
					<i></i>
					<span v-if="openState === 'N'">设置参与公单人员</span>
					<span v-if="openState === 'Y'">参与公单的人员</span>
				</div>
				<span class="el-icon-close" @click="closeMask"></span>
			</div>
			<div v-if="openState === 'N'" class="set-user">
				<div class="tips">
					<p>公单业绩计算逻辑：</p>
					<span>打单业绩÷参与公单人员的总份额×个人的份额</span>
				</div>
				<el-select multiple v-model="selectList" placeholder="请选择参与人员" size="small" style="width: 100%">
					<el-option
						v-for="item in userList"
						:key="item.userId"
						:label="item.userName"
						:value="item.userId">
					</el-option>
				</el-select>
				<div class="public-rule">
					<p>本店公单份额
					<div class="row space-around text-center">
						<div class="post-rate" v-for="(item, idx) in postRate" :key="idx" :style="{width: `calc(100% / ${postRate.length})`}">
							<p>{{item.rete}}</p>
							<span>{{item.title}}</span>
						</div>
					</div>
				</div>
				<div class="action row space-around">
					<el-button size="small" @click="closeMask" style="width: 100px;">取消</el-button>
					<el-button size="small" type="primary" :loading="loading" style="width: 100px;" @click="sureHandel">确定</el-button>
				</div>
			</div>
			<div class="show-user" v-else>
				<div class="list-card">
					<div class="row align-center show-list" v-for="(user, u) in showList" :key="u">
						<div>{{user.userName}}</div>
						<div>{{user.postDesc}}</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import {openPublicOrder, publicOrderJoin, queryMarketUserPost} from "@/libs/http/modules/erp";

export default {
	name: 'PublicUser',
	props: {
		openState: {
			type: String,
			default: () => ''
		}
	},
	data(){
		return{
			selectList: [],
			userList: [],
			postRate: [],
			showList: [],
			loading: false
		}
	},
	created() {
		this.fetchJoinUser()
		if(this.openState === 'N'){
			this.fetchUser()
		}
	},
	methods: {
		closeMask(){
			this.$emit('close')
		},
		fetchUser(){
			queryMarketUserPost({
				marketId: this.$cache.local.get('marketingUnitId')
			}).then(res=>{
				this.userList = res.marketUserPostInfoList || []
			}).catch(error=>{
				this.$message({ type: 'error', message: error, duration: 2000, showClose: true })
			})
		},
		fetchJoinUser(){
			publicOrderJoin().then(res=>{
				if(this.openState === 'N'){
					this.postRate = []
					if(Number(res.managerProportion) > 0){
						this.postRate.push({
							title: '店长',
							rete: res.managerProportion
						})
					}
					if(Number(res.assistantProportion) > 0){
						this.postRate.push({
							title: '店助',
							rete: res.assistantProportion
						})
					}
					if(Number(res.guideProportion) > 0){
						this.postRate.push({
							title: '导购',
							rete: res.guideProportion
						})
					}
					if(Number(res.partTimeProportion) > 0){
						this.postRate.push({
							title: '兼职',
							rete: res.partTimeProportion
						})
					}
				}else{
					res.joinUserInfoList.unshift({
						userName: '姓名',
						postDesc: '职位'
					})
					this.showList = res.joinUserInfoList
				}
			}).catch(error=>{
				this.$message({ type: 'error', message: error, duration: 2000, showClose: true })
			})
		},
		sureHandel(){
			if(this.selectList.length < 2){
				return this.$message({ type: 'error', message: '参与公单的人员不能少于两个人', duration: 2000, showClose: true })
			}
			this.loading = true
			openPublicOrder({
				userIdList: this.selectList
			}).then(res=>{
				this.loading = false
				this.$emit('openSuccess', this.selectList.length)
				this.$message({ type: 'success', message: '店铺公单已开启', duration: 2000, showClose: true })
			}).catch(error=>{
				this.loading = false
				this.$message({ type: 'error', message: error, duration: 2000, showClose: true })
			})
		}
	}
}
</script>

<style lang="scss" scoped>
.mask-shade{
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	background: rgba(0, 0, 0, 0.5);
	.shade-box{
		width: 400px;
	}
	.shade-title{
		background: rgb(245,247,250);
		padding: 10px;
		border-radius: 4px 4px 0 0;
		i{
			width: 4px;
			height: 20px;
			background: rgb(7,124,242);
			margin-right: 10px;
			float: left;
			margin-top: 1px;
		}
		>span{
			font-size: 20px;
			color: #666;
			cursor: pointer;
		}
	}
	.set-user{
		background: #fff;
		border-radius: 0 0 4px 4px;
		padding: 10px 10px 30px;
		.tips{
			background: rgb(247,247,247);
			padding: 10px;
			margin-bottom: 10px;
			p{
				color: #666;
				margin-bottom: 10px;
			}
		}
		.public-rule{
			margin: 30px;
			>p{
				color: #666;
				text-align: center;
				margin-bottom: 20px;
			}
			.post-rate{
				border-right: 1px solid #F5F5F5;
				p{
					font-size: 17px;
				}
				span{
					color: #999;
					font-size: 13px;
				}
				&:last-child{
					border-right: none;
				}
			}
		}
	}
	.show-user{
		background: #fff;
		border-radius: 0 0 4px 4px;
		.list-card{
			padding: 20px 40px 30px;
			.show-list{
				border-left: 1px solid #ddd;
				border-top: 1px solid #ddd;
				div{
					width: calc(100% / 2);
					line-height: 34px;
					padding: 0 10px;
					border-right: 1px solid #ddd;
				}
				&:first-child{
					background: rgb(232,232,232);
				}
				&:last-child{
					border-bottom: 1px solid #ddd;
				}
			}
		}
	}
}
</style>