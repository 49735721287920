const vipListConfig = [
  { key: 'nickName', text: '会员名称', width: '120px' },
  {
    key: 'phone',
    text: '手机号',
    width: '120px',
    template: cell => {
      if (cell) {
        return cell.substr(0, 3) + '****' + cell.substr(7)
      } else {
        return ''
      }
    }
  },
  { key: 'cardNumber', text: '卡号', width: '120px' },
  {
    key: 'sex',
    text: '性别',
    width: '50px',
    template: cell => {
      if (cell === '0') {
        return '女'
      } else if (cell === '1') {
        return '男'
      } else {
        return '未知'
      }
    }
  },
  {
    key: 'effectState',
    text: '会员状态',
    template: () => {
      return `
            <div style="display:flex;align-items:center;">
                <div :style="{width:'10px',height:'10px',background:row.effectState == '0'?'#43d168':row.effectState == '1'?'#f8475f':'#ff9000',marginRight:'10px',borderRadius: '35%'}"></div>
                <div>
                    {{ row.effectState == '0'?'正常':row.effectState == '1'?'拉黑':'异常' }}    
                </div>
            </div>
        `
    }
  }
]

export { vipListConfig }
