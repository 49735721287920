<template>
    <section class="container">
        <dialogModule
            :width="width"
            top="10"
            title="会员余额充值"
            v-if="popupDialog"
            :dialogVisible="popupDialog"
            :closeOnPressEscape="false"
            bgColor="#F5F7FA"
            @close="closeDialog"
        >
            <span slot="close" class="el-icon-close close cursor" @click="closeDialog"></span>
            <div slot="change" style="margin-left: 30px" class="row">
                <div
                    v-if="curRow && curRow.chooseAssistant === 'true'"
                    style="color: #067cf2; cursor: pointer; border-bottom: 1px solid #067cf2"
                    @click="userDialog = true"
                >
                    <span v-if="chooseUser && chooseUser.length && JSON.parse(chooseUser)[0]"
                        >营业员：{{ JSON.parse(chooseUser)[0].realName }}</span
                    >
                    <span v-else>选择营业员</span>
                </div>
            </div>
            <div slot="content" class="row" style="background: #fff; padding: 15px">
                <div v-if="payAgain === 'N'" class="recharge">
                    <div v-if="!memberData" class="row align-center space-between no-member">
                        <div class="row align-center">
                            <div class="row space-center">
                                <i class="el-icon-user-solid"></i>
                            </div>
                            <span>请选择会员</span>
                        </div>
                        <i class="el-icon-arrow-right"></i>
                    </div>
                    <div v-else class="has-member">
                        <div class="row align-center member-data">
                            <div class="member-avat">
                                <img v-if="memberData.avatar" :src="memberData.avatar" />
                                <div v-else class="row space-center">
                                    <i class="el-icon-user-solid"></i>
                                </div>
                            </div>
                            <div class="column member-info">
                                <p class="row align-center">
                                    会员名称：{{ memberData.nickName }}
                                    <img v-if="memberData.sex === '0'" src="@/assets/images/girl.png" />
                                    <img v-else src="@/assets/images/boy.png" />
                                </p>
                                <span>手机号：{{ memberData.phone }}</span>
                                <div>会员卡号：{{ memberData.cardNumber }}</div>
                            </div>
                        </div>
                        <div class="row align-center space-between member-money">
                            <div>
                                可用余额<span>¥ {{ memberData.accountBalance || '0.00' }}</span>
                            </div>
                            <div>
                                占用余额<span>¥ {{ memberData.disableBalance || '0.00' }}</span>
                            </div>
                            <span @click="closeDialog">切换会员</span>
                        </div>
                        <img class="left-icon" src="@/assets/images/Recharge(1).png" />
                    </div>
                    <noDataState
                        v-if="!templateList.length"
                        textDesc="店铺还未设置充值模板"
                        style="margin: 120px"
                    ></noDataState>
                    <div v-else class="row align-center flex-wrap text-center recharge-type">
                        <div
                            v-for="(item, index) in templateList"
                            :key="index"
                            class="column align-center space-center"
                            :style="{
                                marginRight: index % 3 !== 2 ? '10px' : '',
                                background: '#FFF8EF',
                                border: index === currentType ? '2px solid #067CF2' : ''
                            }"
                            @click="chooseType(item, index)"
                        >
                            <p style="color: #f8475f"><span>￥</span>{{ item.payAmount }}</p>
                            <!-- <span class="give-text">充{{item.payAmount}}实际到账{{item.transferAmount}}元</span> -->
                            <div class="type-select row" v-if="index === currentType">
                                <span class="iconfont icon-gou"></span>
                            </div>
                            <div class="give-tip">
                                <img src="@/assets/images/tall.png" style="width: 90px" />
                                <span class="row align-center space-center" style="width: 75px"
                                    >送 {{ item.giveIntegral ? item.giveIntegral : '0' }} 积分</span
                                >
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pay">
                    <noDataState
                        v-if="!payList.length"
                        textDesc="店铺还未关联支付方式"
                        style="margin: 20vh 0 30vh"
                    ></noDataState>
                    <template v-else>
                        <div class="pay-tip">
                            <img src="@/assets/images/paytype-tips.png" />
                        </div>
                        <div style="background: #f3f3f3; height: 70vh">
                            <div class="row align-center space-around pay-list">
                                <div
                                    v-for="pay in payList"
                                    :key="pay.id"
                                    @click.left="choosePayItemSingle(pay)"
                                    @contextmenu.prevent="choosePayItem(pay)"
                                    :class="[
                                        'pay-item',
                                        'column',
                                        'align-center',
                                        'space-center',
                                        pay.isChecked ? 'choose-pay' : ''
                                    ]"
                                >
                                    <img :src="pay.icon || '../assets/images/cash-money.png'" />
                                    <p>{{ pay.name }}</p>
                                    <div class="pay-select row" v-if="pay.isChecked">
                                        <span class="iconfont icon-gou"></span>
                                    </div>
                                </div>
                            </div>
                            <div class="choose-list">
                                <div class="choose-item" v-for="choose in chooseList" :key="choose.id">
                                    <div class="row space-between align-center">
                                        <div class="choose-title">
                                            <span
                                                style="
                                                    background: linear-gradient(66deg, #00cb0e, #24ea65);
                                                    color: #fff;
                                                    font-size: 14px;
                                                    padding: 2px 10px;
                                                    border-radius: 0 20px 20px 0;
                                                "
                                                >已选择</span
                                            >
                                            <span style="color: #f8475f">【</span>
                                            <span>{{ choose.name }}</span>
                                            <span style="color: #f8475f">】</span>
                                        </div>
                                        <div class="pay-split" v-if="choose.isChecked && choose.isSplit === 'Y'">
                                            <a href="javascript:;" @click="splictPayItem(choose)">拆分</a>
                                        </div>
                                        <p class="row align-center choose-body">
                                            <span>￥</span>
                                            <input
                                                type="text"
                                                placeholder="0.00"
                                                v-model="choose.price"
                                                @keyup.enter="
                                                    computedMoney(choose)
                                                    isComputer = true
                                                "
                                                @blur="
                                                    computedMoney(choose)
                                                    isComputer = true
                                                "
                                                @input="resetMoney(choose)"
                                            />
                                            <span>元</span>
                                        </p>
                                    </div>
                                    <div v-if="choose.name.includes('现金')" class="return-tool">
                                        <div class="row space-center align-center tool-title">找零小工具</div>
                                        <div class="row align-center flex-wrap text-center tool-money">
                                            <div class="tool-row" style="border-bottom: none">实收金额</div>
                                            <div class="tool-row" style="border-bottom: none; border-left: none">
                                                找零金额
                                            </div>
                                            <div class="tool-line tool-input row align-center space-center">
                                                <span>￥</span>
                                                <input
                                                    type="text"
                                                    :disabled="!choose.price"
                                                    placeholder="0.00"
                                                    @keyup.enter="$event.target.blur()"
                                                    @blur="calcCash"
                                                    v-model="cashMoney"
                                                />
                                                <span>元</span>
                                            </div>
                                            <div class="tool-line" style="border-left: none">
                                                ￥{{ returnMoney || '0.00' }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="split-card" v-if="choose.splitItem && choose.isChecked">
                                        <div class="row space-center align-center split-title">拆分明细</div>
                                        <div class="row align-center text-center title-row">
                                            <div class="cell" style="border-bottom: none">收款次数</div>
                                            <div class="cell" style="border-bottom: none; border-left: none">
                                                支付金额
                                            </div>
                                            <div class="cell" style="border-bottom: none; border-left: none">操作</div>
                                        </div>
                                        <div
                                            class="row align-center text-center body-row"
                                            v-for="(pay, index) in choose.splitList"
                                            :key="index"
                                        >
                                            <div class="cell" style="border-bottom: none">
                                                第{{ index - 0 + 1 }}次收款
                                            </div>
                                            <div
                                                class="cell row align-center cell-input"
                                                style="border-bottom: none; border-left: none"
                                            >
                                                <p class="row align-center">
                                                    <span>￥</span>
                                                    <input
                                                        type="text"
                                                        placeholder="0.00"
                                                        v-model="pay.value"
                                                        @keyup.enter="$event.target.blur()"
                                                        @blur="calucItemMoney(choose, pay)"
                                                    />
                                                    <span>元</span>
                                                </p>
                                            </div>
                                            <div class="delete-split cell" @click="splicePay(choose, index)">
                                                <span>删除</span>
                                            </div>
                                        </div>
                                        <div class="row align-center text-center bott-row">
                                            <div class="cell"></div>
                                            <div class="cell" style="border-left: none">
                                                可拆分金额：<span
                                                    style="color: #f8475f; font-size: 18px; font-family: 'font-Regular'"
                                                    >￥{{ choose.surplusPrice }}</span
                                                >
                                            </div>
                                            <div class="cell row align-center" style="border-left: none">
                                                <p @click="pushPayItem(choose)">新增</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="pay-total"
                                style="background: #fff; margin: 10px; padding: 10px; border-radius: 4px"
                            >
                                <div class="row align-center space-center">
                                    <span style="color: #999; font-size: 14px; margin-right: 4px; margin-top: 7px"
                                        >合计收款：</span
                                    >
                                    <p style="font-size: 22px; font-weight: bold; color: #f8475f">
                                        ¥{{ realGetMoney || '0.00' }}
                                    </p>
                                </div>
                                <div
                                    style="
                                        background: #067cf2;
                                        width: 400px;
                                        margin: 10px auto 0;
                                        color: #fff;
                                        font-size: 18px;
                                        text-align: center;
                                        height: 46px;
                                        line-height: 46px;
                                        cursor: pointer;
                                        border-radius: 4px;
                                    "
                                    @click="payBefore"
                                >
                                    确认收款
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </dialogModule>
        <SalesUserList
            v-if="userDialog"
            type="vip"
            :curGuider="chooseUser"
            @close="userDialog = false"
            @sureBtn="getChoose"
        ></SalesUserList>
        <DoReceipts v-if="DoReceiptsModal" :webViewUrl="webViewUrl" @close="successClose"></DoReceipts>
        <ScanPayWaiting
            v-if="scanWating"
            :ordingId="ordingObj.id"
            :payData="payData"
            @print="queryPrintData"
            @close="errorClose"
        ></ScanPayWaiting>
    </section>
</template>

<script>
import dialogModule from '@/components/dialogModule.vue'
import SalesUserList from '@/views/deptCollectMoney/components/SalesUserList'
import noDataState from '@/components/noDataState.vue'
import ScanPayWaiting from './ScanPayWaiting'
import DoReceipts from '@/views/salesRecord/components/DoReceipts.vue'
import {
    queryDeptPayList,
    queryRechargeTemp,
    createBill,
    payVipOrding,
    queryMemberPayType
} from '@/libs/http/modules/vip'
import request from '@/libs/http'
import {ipc} from "@/utils/ipcRenderer";
import {ipcApiRoute} from "@/api/main";
export default {
    name: 'MemberRecharge',
    props: {
        curRow: {
            type: Object,
            default: () => null
        },
        payAgain: {
            type: String,
            default: 'N'
        },
        againData: {
            type: Object,
            default: () => null
        }
    },
    components: {
        dialogModule,
        SalesUserList,
        noDataState,
        DoReceipts,
        ScanPayWaiting
    },
    data() {
        return {
            popupDialog: false,
            userDialog: false,
            chooseUser: [],
            currentType: '',
            payList: [],
            chooseList: [],
            returnMoney: '',
            cashMoney: '',
            realGetMoney: '',
            memberData: null,
            templateId: '',
            isComputer: false,
            isCalcLsat: false,
            templateList: [],
            DoReceiptsModal: false,
            scanWating: false,
            payData: [],
            ordingObj: {},
            webViewUrl: ''
        }
    },
    created() {
        if (this.curRow) {
            this.memberData = this.curRow
        }
        if (this.againData) {
            this.realGetMoney = this.againData.unpaidAmount
        }
        this.popupDialog = true
        this.queryTemp()
        this.queryPayList()
    },
    computed: {
        width() {
            if (this.payAgain === 'N') {
                return '1100px'
            } else {
                return '700px'
            }
        }
    },
    methods: {
        closeDialog(type) {
            this.popupDialog = false
            if (type === 'refresh') {
                this.$emit('close', 'refresh')
            } else {
                this.$emit('close')
            }
        },
        successClose(e) {
            this.closeDialog('refresh')
        },
        errorClose(e) {
            this.closeDialog(e)
            this.$emit('lookRecharge') //取消支付之后再调用一次接口
        },
        getChoose(e) {
            //选择营业员
            this.userDialog = false
            if (e) {
                this.chooseUser = JSON.stringify(e)
            } else {
                this.chooseUser = []
            }
        },
        chooseType(item, idx) {
            //选择充值模板
            this.currentType = idx
            this.realGetMoney = item.payAmount
            this.templateId = item.id
            this.queryPayList()
        },
        queryTemp() {
            //查询充值模板
            queryRechargeTemp({
                page: '1',
                pageSize: '10'
            })
                .then((data) => {
                    this.templateList = data.items || []
                })
                .catch((error) => {
                    this.$message({ type: 'error', message: error, duration: 2000, showClose: true })
                })
        },

        async queryPayList() {
            //查询支付方式
            this.chooseList = []
            this.payList = await queryMemberPayType()
            this.payList.forEach((item) => {
                if (item.isSplit === 'Y') {
                    this.$set(item, 'splitItem', false)
                    this.$set(item, 'splitList', [{ value: '' }])
                    this.$set(item, 'splitPrice', '')
                    this.$set(item, 'surplusPrice', '')
                }
                if (item.isDefault === 'Y') {
                    this.$set(item, 'isChecked', true)
                    this.$set(item, 'price', this.realGetMoney)
                    this.chooseList.push(item)
                } else {
                    this.$set(item, 'isChecked', false)
                }
            })
        },
        choosePayItemSingle(item) {
            //选择单个支付方式
            this.chooseList = []
            this.returnMoney = '0.00'
            this.cashMoney = ''
            this.payList.forEach((choose) => {
                if (choose.id !== item.id) {
                    choose.isChecked = false
                } else {
                    choose.isChecked = true
                    this.$set(item, 'price', '')
                    this.chooseList.push(item)
                    this.chooseList[0].price = (this.realGetMoney * 1).toFixed(2)
                }
            })
        },
        choosePayItem(item) {
            //选择多个支付方式
            item.isChecked = !item.isChecked
            this.returnMoney = '0.00'
            this.cashMoney = ''
            let index = this.chooseList.findIndex((choose) => {
                return choose.id === item.id
            })
            if (item.isChecked && index === -1) {
                this.$set(item, 'price', '')
                this.chooseList.push(item)
            }
            if (!item.isChecked && index !== -1) {
                this.chooseList.splice(index, 1)
            }
            this.chooseList.forEach((choose) => {
                if (choose.isSplit === 'Y') {
                    choose.splitItem = false
                    choose.splitList = [{ value: '' }]
                    choose.splitPrice = ''
                    choose.surplusPrice = ''
                }
            })
            if (this.chooseList.length === 1) {
                if (this.chooseList[0].type === '3') {
                    this.chooseList[0].price = ''
                } else {
                    this.chooseList[0].price = (this.realGetMoney * 1).toFixed(2)
                    this.chooseList[0].price = (this.realGetMoney * 1).toFixed(2)
                }
            } else {
                this.chooseList.map((item) => {
                    item.price = ''
                })
            }
        },

        //处理填写的支付金额
        computedMoney(val) {
            if (!val.price) return
            if (
                isNaN(val.price) ||
                (this.chooseList.length === 1 && !this.$amount.equals(val.price, this.realGetMoney || 0)) ||
                Number(val.price) > Number(this.realGetMoney)
            ) {
                val.price = ''
                return this.$message({ type: 'error', message: '请填写正确的金额', duration: 2000, showClose: true })
            }
            if (this.isComputer) return
            if (val.price) {
                val.price = Number(val.price).toFixed(2)
            }
            let money = 0
            this.isCalcLsat = false
            this.chooseList.forEach((item) => {
                money = Number(money) + Number(item.price)
                if (item.splitList && item.splitList.length) {
                    item.splitList.forEach((spl) => {
                        spl.value = ''
                    })
                }
            })
            let arrr = this.chooseList.filter((item) => {
                return !item.price
            })
            if (arrr.length === 1) {
                this.chooseList.map((item) => {
                    if (item.id === arrr[0].id) {
                        item.price = this.$amount.subtract(this.realGetMoney, money, 2)
                        this.isCalcLsat = true
                    }
                })
            }
            if (this.cashMoney) {
                this.cashMoney = ''
                this.returnMoney = ''
            }
        },
        resetMoney(item) {
            this.isComputer = false
            if (this.isCalcLsat) {
                this.chooseList.forEach((pay) => {
                    if (pay.id !== item.id) {
                        pay.price = ''
                    }
                })
            }
            this.chooseList.forEach((pay) => {
                if (pay.id === item.id && pay.splitPrice) {
                    pay.splitPrice = Number(item.price).toFixed(2)
                    pay.surplusPrice = Number(item.price).toFixed(2)
                    pay.splitList.forEach((val) => {
                        val.value = ''
                    })
                }
            })
        },
        calcCash() {
            this.chooseList.forEach((item) => {
                if (item.name.includes('现金') && this.cashMoney) {
                    this.returnMoney = this.$amount.subtract(this.cashMoney, item.price, 2)
                }
            })
            this.cashMoney = Number(this.cashMoney).toFixed(2)
        },
        splictPayItem(item) {
            let obj = this.chooseList.find((pay) => {
                return pay.name === item.name
            })
            if (!obj.price) {
                return this.$message({ type: 'error', message: '请先填写金额', duration: 2000, showClose: true })
            }
            item.splitPrice = obj.price
            item.surplusPrice = obj.price
            item.splitItem = true
        },
        pushPayItem(item) {
            item.splitList.push({
                value: ''
            })
        },
        splicePay(item, index) {
            let money = 0
            item.splitList.splice(index, 1)
            item.splitList.forEach((pay) => {
                money = this.$amount.add(money, pay.value || 0, 2)
            })
            item.surplusPrice = this.$amount.subtract(item.splitPrice, money, 2)
            if (!item.splitList.length) {
                item.splitItem = false
            }
        },
        calucItemMoney(item, pay) {
            if (isNaN(pay.value) || Number(pay.value) <= 0) {
                pay.value = ''
                this.$message({ type: 'error', message: '请填写大于0的有效金额', duration: 2000, showClose: true })
            }
            let money = 0
            item.splitList.forEach((item) => {
                money = this.$amount.add(money, item.value || 0, 2)
            })
            item.surplusPrice = this.$amount.subtract(item.splitPrice, money, 2)
            if (pay.value) {
                pay.value = Number(pay.value).toFixed(2)
            }
        },

        payBefore() {
            //生成充值订单
            if (
                this.curRow &&
                this.curRow.chooseAssistant === 'true' &&
                this.payAgain !== 'Y' &&
                !this.chooseUser.length
            )
                return this.$message({ type: 'error', message: '请选择营业员', duration: 2000, showClose: true })
            if (!this.memberData && this.payAgain !== 'Y')
                return this.$message({ type: 'error', message: '请选择要充值的会员', duration: 2000, showClose: true })
            if (!this.templateId && this.payAgain !== 'Y')
                return this.$message({ type: 'error', message: '请选择一种充值模板', duration: 2000, showClose: true })
            if (!this.chooseList.length)
                return this.$message({
                    type: 'error',
                    message: '请至少选择一种支付方式',
                    duration: 2000,
                    showClose: true
                })
            const all = this.chooseList.reduce((total, item) => {
                return total + Number(item.price)
            }, 0)
            if (Number(this.realGetMoney) !== Number(all)) {
                return this.$message({ type: 'error', message: '请检查金额是否正确', duration: 2000, showClose: true })
            }
            let subPayList = []
            let isOnline = false
            let checkSplit = false
            this.chooseList.forEach((item) => {
                if (item.splitItem && item.splitList.length) {
                    let itemTotal = 0
                    item.splitList.forEach((val) => {
                        itemTotal = Number(itemTotal) + Number(val.value)
                        if (val.value) {
                            subPayList.push({
                                id: item.id,
                                proceedsPrice: val.value
                            })
                        }
                    })
                    if (!this.$amount.equals(item.splitPrice, itemTotal)) {
                        checkSplit = true
                    }
                } else {
                    subPayList.push({
                        id: item.id,
                        proceedsPrice: item.price
                    })
                }
                if (item.type === '1') {
                    isOnline = true
                }
            })
            if (checkSplit)
                return this.$message({ type: 'error', message: '请检查金额是否正确', duration: 2000, showClose: true })
            let isMinus = this.chooseList.some((item) => {
                return Number(item.price) <= 0
            })
            if (isMinus)
                return this.$message({
                    type: 'error',
                    message: '请取消勾选金额小于等于0元的收款方式',
                    duration: 2000,
                    showClose: true
                })
            if (this.payAgain === 'Y') {
                this.ordingObj = this.againData
                this.surePay(isOnline, subPayList)
            } else {
                const reqData = {
                    vipId: this.memberData.id,
                    rechargeTemplateId: this.templateId
                }
                if (this.chooseUser && this.chooseUser.length && JSON.parse(this.chooseUser)[0]) {
                    reqData['assistantUserId'] = JSON.parse(this.chooseUser)[0].id
                }
                createBill(reqData)
                    .then((data) => {
                        this.ordingObj = data
                        this.$emit('lookRecharge')
                        this.surePay(isOnline, subPayList)
                    })
                    .catch((error) => {
                        this.$message({ type: 'error', message: error, duration: 2000, showClose: true })
                    })
            }
        },
        surePay(online, payList) {
            //确认支付订单
            payVipOrding({
                id: this.ordingObj.id,
                isAgainPayment: this.payAgain,
                proceedsInfoList: payList
            })
                .then((data) => {
                    if (online) {
                        this.payData = data.onLinePaymentInfos || []
                        this.scanWating = true
                    } else {
                        this.queryPrintData()
                    }
                })
                .catch((error) => {
                    this.$message({ type: 'error', message: error, duration: 2000, showClose: true })
                })
        },
        // 打印支付凭证
        queryPrintData() {
			this.$store.commit('playPaySuccess')
            this.DoReceiptsModal = true
            let printer = this.$cache.local.get('printerModel')
            let printCount = this.$cache.local.get('crmReceiptCount')
            let printCode = this.$cache.local.get('crmReceiptCode')
            let params = {
                sn: this.ordingObj.sn
            }
            this.webViewUrl = `${request.printPreviewRoot}?printerName=${printer}&templateCode=${printCode}&printCount=0&data=${encodeURI(JSON.stringify(params))}`
			let eggRootUrl = `${request.printEggPreviewRoot}?printerName=${printer}&templateCode=${printCode}&`
			const eggSrc = eggRootUrl + `printCount=${printCount}&data=${encodeURI(JSON.stringify(params))}&sn=${params['sn']}`;
			ipc.invoke(ipcApiRoute.print, {contentUrl: eggSrc, sn: params['sn']}).then(r => {})
        }
    }
}
</script>

<style lang="scss" scoped>
.recharge {
    width: 470px;
    .no-member {
        border: 1px solid #067cf2;
        padding: 10px;
        background: #f6faff;
        border-radius: 8px;
        cursor: pointer;
        div div {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background: #95caff;
            margin-right: 10px;
            overflow: hidden;
            i {
                color: #fff;
                font-size: 40px;
                align-items: flex-end;
                margin-top: 5px;
            }
        }
        div span {
            font-size: 16px;
            color: #000;
        }
    }
    .has-member {
        box-shadow: 0px 8px 10px 0px rgba(213, 213, 213, 0.35);
        margin-bottom: 20px;
        border-radius: 8px;
        position: relative;
        .member-data {
            background: linear-gradient(120deg, #fcf3d6, #f7e5b6);
            padding: 15px 10px;
            border-radius: 8px 8px 0 0;
            .member-avat {
                margin-right: 10px;
                img {
                    width: 70px;
                    height: 70px;
                    border-radius: 50%;
                    border: 2px solid #fff;
                    float: left;
                }
                div {
                    width: 70px;
                    height: 70px;
                    border-radius: 50%;
                    border: 2px solid #fff;
                    color: #fff;
                    background: #f9d396;
                    overflow: hidden;
                    i {
                        color: #fff;
                        font-size: 60px;
                        margin-top: 12px;
                    }
                }
            }
            .member-info {
                p {
                    color: #3c2200;
                    font-size: 16px;
                    font-weight: 700;
                    font-family: 'font-Medium';
                    img {
                        width: 20px;
                        margin-left: 5px;
                    }
                }
                span {
                    margin: 5px 0;
                }
            }
        }
        .member-money {
            background: #f7edd7;
            height: 50px;
            padding: 0 10px;
            border-bottom: 2px solid #fff;
            border-radius: 0 0 8px 8px;
            div {
                color: #3c2200;
                span {
                    font-size: 18px;
                    font-family: 'font-Medium';
                    margin-left: 10px;
                }
            }
            > span {
                background: #a35f28;
                color: #fff;
                border-radius: 40px;
                padding: 8px 22px;
                cursor: pointer;
            }
        }
        .left-icon {
            width: 70px;
            position: absolute;
            right: 25px;
            top: 20px;
        }
    }
    .recharge-type {
        margin-top: 50px;
        > div {
            position: relative;
            height: 140px;
            width: 150px;
            border-radius: 6px;
            cursor: pointer;
            margin-bottom: 30px;
            border: 2px solid transparent;
            p {
                font-size: 20px;
                font-weight: bold;
                color: #000;
                span {
                    font-size: 14px;
                }
            }
            .give-text {
                color: #999;
                margin: 10px 20px 0;
                //margin-top: 10px;
                //font-size: 12px;
            }
            .type-select {
                position: absolute;
                right: 0;
                bottom: 0;
                width: 40px;
                height: 40px;
                border-radius: 0 0 4px 0;
                background: linear-gradient(-45deg, #067cf2 50%, transparent 50%);
                .icon-gou {
                    margin-top: 20px;
                    margin-left: 16px;
                    color: #fff;
                }
            }
            .give-tip {
                position: absolute;
                top: -25px;
                left: -4px;
                width: 60px;
                height: 20px;
                border-radius: 4px 10px 2px 0;
                color: #fff;
                img {
                    width: 70px;
                    float: left;
                }
                span {
                    margin-top: -20px;
                    float: left;
                    font-size: 12px;
                    margin-left: 2px;
                }
            }
        }
    }
}
.pay {
    flex: 1;
    margin-left: 15px;
    .pay-tip {
        height: 50px;
        text-align: center;
        img {
            height: 45px;
            margin-top: -10px;
        }
    }

    .pay-list {
        padding: 10px;
        border-radius: 4px;
        .pay-item {
            position: relative;
            width: 120px;
            height: 120px;
            border: 1px solid #e2e9f9;
            background: #ffffff;
            border-radius: 4px;
            cursor: pointer;
            img {
                width: 40px;
                margin-bottom: 10px;
            }
            p {
                font-family: 'font-Regular';
            }
            .pay-select {
                position: absolute;
                right: 0;
                bottom: 0;
                width: 40px;
                height: 40px;
                border-radius: 0 0 2px 0;
                background: linear-gradient(-45deg, #067cf2 50%, transparent 50%);
                .icon-gou {
                    margin-top: 20px;
                    margin-left: 18px;
                    color: #fff;
                }
            }
        }
        .choose-pay {
            border: 1px solid #067cf2;
            background: #f1f5ff;
        }
    }

    .choose-list {
        margin: 10px;
        border-radius: 4px;
        height: 41vh;
        overflow: auto;
        .choose-item {
            background: #fff;
            margin-bottom: 10px;
            padding: 10px 10px 10px 0;
            .choose-title {
                font-family: 'font-Regular';
                color: #333;
                //width:200px;
                font-size: 16px;
            }
            .pay-split {
                border-bottom: 1px solid #067cf2;
                font-size: 16px;
                a {
                    color: #067cf2;
                }
            }
            .choose-body {
                font-family: 'font-Regular';
                font-size: 18px;
                color: #333;
                input {
                    border: 1px solid #ccc;
                    background: #eef2fb;
                    line-height: 36px;
                    border-radius: 4px;
                    text-align: center;
                    font-size: 18px;
                    font-family: 'font-Regular';
                    margin: 0 5px;
                    outline: none;
                }
            }
            .return-tool {
                margin-top: 20px;
                padding-left: 10px;
                .tool-title {
                    background: #f9f9f9;
                    height: 30px;
                    border: 1px solid #e7e8ea;
                    border-bottom: none;
                }
                .tool-money {
                    .tool-row {
                        width: 50%;
                        color: #436481;
                        line-height: 30px;
                        border: 1px solid #e7e8ea;
                        font-size: 14px;
                    }
                    .tool-line {
                        width: 50%;
                        line-height: 50px;
                        border: 1px solid #e7e8ea;
                        font-size: 18px;
                    }
                    .tool-input {
                        font-size: 14px;
                        font-family: 'font-Regular';
                        input {
                            font-family: 'font-Regular';
                            background: #eef2fb;
                            border: 1px solid #ccc;
                            outline: none;
                            width: 100px;
                            text-align: center;
                            line-height: 28px;
                            border-radius: 4px;
                            margin: 0 5px;
                            font-size: 18px;
                        }
                    }
                }
            }
            .split-card {
                margin-top: 20px;
                padding-left: 10px;
                .split-title {
                    background: #f9f9f9;
                    height: 30px;
                    border: 1px solid #e7e8ea;
                    border-bottom: none;
                }
                .title-row {
                    height: 33px;
                    width: 100%;
                    div {
                        width: calc(100%);
                        border: 1px solid #e7e8ea;
                        height: 33px;
                        line-height: 33px;
                        color: #436481;
                    }
                }
                .body-row {
                    height: 46px;
                    width: 100%;
                    div {
                        width: calc(100%);
                        border: 1px solid #e7e8ea;
                        height: 46px;
                        line-height: 46px;
                    }
                    .cell-input {
                        height: 46px;
                        line-height: 46px;
                        p {
                            margin: auto;
                        }
                        input {
                            font-family: 'font-Regular';
                            outline: none;
                            width: 100px;
                            text-align: center;
                            line-height: 28px;
                            border-radius: 4px;
                            margin: 0 5px;
                            font-size: 18px;
                            background: #eef2fb;
                            border: 1px solid #ccc;
                        }
                    }
                    .delete-split {
                        border-left: none;
                        border-bottom: none;
                        span {
                            color: #067cf2;
                            border-bottom: 1px solid #067cf2;
                            cursor: pointer;
                        }
                    }
                }
                .bott-row {
                    height: 46px;
                    width: 100%;
                    div {
                        width: calc(100%);
                        border: 1px solid #e7e8ea;
                        height: 46px;
                        line-height: 46px;
                    }
                    p {
                        background: #067cf2;
                        color: #fff;
                        line-height: 30px;
                        width: 100px;
                        border-radius: 4px;
                        margin: auto;
                        cursor: pointer;
                    }
                }
            }
        }
    }

    .pay-total {
        height: 126px;
    }
}
</style>
