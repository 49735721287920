<template>
  <dialogModule
    width="40%"
    title="创建会员"
    v-if="popupDialog"
    :dialogVisible="popupDialog"
    @close="closeDialog"
  >
    <span
      slot="close"
      class="el-icon-close close-icon"
      @click="closeDialog"
    ></span>
    <div slot="content" class="create-vip">
      <el-form
        ref="vipForm"
        size="small"
        label-position="top"
        label-width="auto"
        :model="form"
        :rules="rules"
        @submit.native.prevent
      >
        <el-row type="flex" :gutter="20" style="flex-wrap: wrap">
          <el-col :span="12">
            <el-form-item label="会员昵称" prop="nickName">
              <el-input
                v-model="form.nickName"
                placeholder="请输入会员昵称"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="真实姓名" prop="realName">
              <el-input
                v-model="form.realName"
                placeholder="请输入真实姓名"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="手机号" prop="phone">
              <el-input
                v-model="form.phone"
                maxlength="11"
                placeholder="请输入手机号"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="性别" prop="sex">
              <el-radio-group v-model="form.sex">
                <el-radio label="1">男</el-radio>
                <el-radio label="0">女</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="绑定店铺" prop="deptId">
              <el-select v-model="form.deptId" disabled placeholder="请选择性别" style="width: 100%;">
                <el-option :value="deptId" :label="deptName"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="发卡导购" prop="assistantUserId">
              <el-select v-model="form.assistantUserId" placeholder="请选择发卡导购" style="width: 100%;">
                <el-option v-for="item in shopList" :key="item.id" :value="item.id" :label="item.realName"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="出生年月" prop="birthday">
              <el-date-picker
                  style="width: 100%;"
                  value-format="yyyy-MM-dd"
                  v-model="form.birthday"
                  type="date"
                  placeholder="请选择出生年月日">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="生日历法类型" prop="birthdayCalendarType">
              <el-radio-group v-model="form.birthdayCalendarType">
                <el-radio label="0">公历</el-radio>
                <el-radio label="1">农历</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="身份证号" prop="idNum">
              <el-input
                v-model="form.idNum"
                placeholder="请输入身份证号"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="上衣尺寸" prop="coatSize">
              <el-input
                  v-model="form.coatSize"
                  placeholder="请输入上衣尺寸"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="腰围尺寸" prop="waistline">
              <el-input
                  v-model="form.waistline"
                  placeholder="请输入腰围尺寸"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="身高(cm)" prop="height">
              <el-input
                  v-model="form.height"
                  placeholder="请输入身高"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="体重(kg)" prop="weight">
              <el-input
                  v-model="form.weight"
                  placeholder="请输入体重"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="鞋码" prop="shoeSize">
              <el-input
                  v-model="form.shoeSize"
                  placeholder="请输入鞋码"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="row align-center space-center">
          <el-button style="width:80px;line-height:30px;margin-right:10px;border:1px solid #ccc;text-align:center;border-radius:4px;cursor:pointer;padding: 0;" @click="closeDialog">取 消</el-button>
          <el-button style="width:80px;line-height:30px;color:#fff;background:#067CF2;text-align:center;border-radius:4px;cursor:pointer;padding: 0;" :disabled="loading" :loading="loading" @click="beforeCreate">确 认</el-button>
      </div>
    </div>
  </dialogModule>
</template>

<script>
import dialogModule from "@/components/dialogModule.vue";
import {queryShoppGuide} from '@/libs/http/modules/posOrder'
import {vipSaveVipUserInfo} from "@/libs/http/modules/vip";
export default {
  components: {
    dialogModule,
  },
  data() {
    return {
      popupDialog: false,
      deptName: this.$cache.local.get('deptName'),
      deptId: this.$cache.local.get('deptId'),
      form: {
        nickName: '',//会员昵称
        realName: '',//真实姓名
        phone: '',//手机号
        sex: '1',//性别
        deptId: this.$cache.local.get('deptId'),//绑定店铺
        assistantUserId: '',//发卡导购
        birthday: '',//出生年月
        idNum: '',//身份证号
        coatSize: '',//上衣尺寸
        waistline: '',//腰围尺寸
        height: '',//身高
        weight: '',//体重
        shoeSize: '',//鞋码
        birthdayCalendarType:'',//生日历法类型
      },
      rules: {
        nickName: [{ required: true, message: '请填写会员名称', trigger: 'blur' }],
        phone: [{ required: true, message: '请填写手机号', trigger: 'blur' }],
        sex: [{ required: true, message: '请选择性别', trigger: 'blur' }],
      },
      loading: false,
      shopList: []
    };
  },
  created() {
    this.popupDialog = true;
    this.queryGuide();
  },
  methods: {
    async queryGuide() {
      this.shopListVisable = true;
      let data = await queryShoppGuide({ page: '1', pageSize: '50' });
      this.shopList = data.items || [];
    },
    closeDialog() {
      this.popupDialog = false;
      this.$emit("close");
    },
    beforeCreate(){
      this.$refs['vipForm'].validate(valid => {
        if (valid) {
          this.create();
        }
      });
    },
    create(){
      if(!this.form.phone || !(/^1[3456789]\d{9}$/.test(this.form.phone))){
        return this.$message({ type: "error", message: '请填写有效手机号', duration: 2000, showClose: true });
      }
      this.loading = true;
      let reqData = {}
      for(let key in this.form){
        if(this.form[key]){
          reqData[key] = this.form[key]
        }
      }
      vipSaveVipUserInfo(reqData).then(data=>{
        this.popupDialog = false;
        this.loading = false;
        this.$message({ type: "success", message: '会员创建成功', duration: 2000, showClose: true });
        this.$emit("close", 'refresh');
      }).catch(error=>{
        this.loading = false;
        this.$message({ type: "error", message: error, duration: 2000, showClose: true });
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.create-vip{
  padding: 20px;
}
.close-icon {
  color: #999;
  padding: 10px;
  margin-right: -10px;
  cursor: pointer;
}
</style>

<style lang="scss">
.create-vip{
  .el-form-item__label{
    line-height: 0;
  }
  .el-form-item{
    margin-bottom: 20px;
  }
  .el-form-item__error{
    padding-top: 0;
  }
}
</style>

